import { LockOutlined } from "@mui/icons-material";
import { Icon } from "@iconify/react";

import {
  Card,
  CardHeader,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Page from "src/components/Page";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { LoadingButton } from "@mui/lab";
import { notification } from "src/utils/messages";
import { updatePassword } from "src/api/commonapi";
import { useNavigate } from "react-router-dom";

export default function ChangePassword() {
  const navigate = useNavigate();
  let loginUser = JSON.parse(localStorage.getItem("userData"));

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  const [formData, setFormData] = useState({
    u_id: loginUser?.u_id || 0,
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const resetFormData = () => {
    setFormData({
      ...formData,
      old_password: "",
      new_password: "",
      confirmPassword: "",
    });
  };
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleFormSubmit = async () => {
    if (
      formData.u_id != undefined &&
      formData.u_id != null &&
      formData.u_id != 0
    ) {
      if (
        formData.old_password != "" &&
        formData.old_password != null &&
        formData.new_password != "" &&
        formData.new_password != null &&
        formData.confirm_password != "" &&
        formData.confirm_password != null
      ) {
        if (formData.old_password == loginUser.password) {
          if (formData.new_password == formData.confirm_password) {
            let resp = await updatePassword({
              ...formData,
            });
            if (resp.status == 1) {
              resetFormData();
            }
            let notify = notification({
              type: resp.status == 0 ? "error" : "success",
              message: resp.message,
            });
            notify();

            if (resp.status == 1) {
              await localStorage.removeItem("userData");
              await navigate("/");
              await window.location.reload();
            }
          } else {
            let notify = notification({
              type: "error",
              message: "New password and confirm password do not match",
            });
            notify();
          }
        } else {
          let notify = notification({
            type: "error",
            message: "Old Password is incorrect Please Try again",
          });
          notify();
        }
      } else {
        let notify = notification({
          type: "error",
          message:
            "Please Fill Password, New Password and Confirm Password fields",
        });
        notify();
      }
    } else {
      let notify = notification({
        type: "error",
        message: "Something went wrong Please try again later",
      });
      notify();
    }
  };
  return (
    <Page title="Change Password | Local Vocal Business Group">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h4" gutterBottom>
            Change Password
          </Typography>
        </Stack>
        <Card
          className="p-20 w-auto"
          sx={{ width: "fit-content", margin: "auto" }}
        >
          <Grid
            spacing={2}
            container
            sx={{
              minWidth: "450px",
              width: "100%",
              maxWidth: "800px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={12}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  value={formData?.old_password}
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Old Password"
                  label="Old Password"
                  name="old_password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>
                          <LockOutlined color="primary" />
                        </Icon>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Icon
                            style={{ color: "#1c4766" }}
                            icon={showPassword ? eyeFill : eyeOffFill}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      old_password: e.target.value,
                    });
                  }}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: "10px" }}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  value={formData?.new_password}
                  type={showNewPassword ? "text" : "password"}
                  placeholder="Enter New Password"
                  label="New Password"
                  name="new_password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>
                          <LockOutlined color="primary" />
                        </Icon>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowNewPassword} edge="end">
                          <Icon
                            style={{ color: "#1c4766" }}
                            icon={showNewPassword ? eyeFill : eyeOffFill}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      new_password: e.target.value,
                    });
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  value={formData?.confirm_password}
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Enter Confirm Password"
                  label="Confirm Password"
                  name="new_password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>
                          <LockOutlined color="primary" />
                        </Icon>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleShowConfirmPassword}
                          edge="end"
                        >
                          <Icon
                            style={{ color: "#1c4766" }}
                            icon={showConfirmPassword ? eyeFill : eyeOffFill}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      confirm_password: e.target.value,
                    });
                  }}
                />
              </Stack>
            </Grid>

            <div style={{ marginTop: "15px" }}>
              <LoadingButton
                sx={{ margin: "auto" }}
                type="button"
                variant="contained"
                className="loadingBtn"
                loading={isFormSubmit}
                onClick={handleFormSubmit}
              >
                Change Password
              </LoadingButton>
            </div>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
}
