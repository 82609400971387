import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import DatePicker from "@mui/lab/DatePicker";
import moment from "moment";
import Select from "react-select";
import Label from "../components/Label";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Button,
  TextField,
  Grid,
  Checkbox,
  MenuItem,
  FormLabel,
  CardFormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Icon,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { notification } from "src/utils/messages";
import {
  deleteStaff,
  insertevent,
  updateStaffDetails,
  updateStaffStatus,
} from "src/api/commonapi";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Page from "../components/Page";
import { Routes } from "../constant/api_url";
import { insertStaff } from "src/api/commonapi";
import { fetchstaffbypid } from "src/utils/common";
import {
  CancelRounded,
  CheckCircleRounded,
  EditSharp,
  DeleteSharp,
  ForkRightRounded,
} from "@mui/icons-material";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
export default function Staff() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });
  const [step, setStep] = useState(0);
  const [formdata, setformdata] = useState({
    sales_p: 0,
    account_p: 0,
    management_p: 0,
    city_id: loginUser.city_id,
    p_user_id: loginUser.u_id,
  });
  const [staffdata, setstaffdata] = useState([]);

  const getstafflist = async () => {
    setstaffdata(await fetchstaffbypid(loginUser.u_id));
  };

  useEffect(async () => {
    await getstafflist();
  }, []);

  const onChangeFunction = (e) => {
    setformdata({ ...formdata, [e.target.name]: e.target.value });
  };

  const changestatus = async (u_id, u_status) => {
    let fdata = new FormData();
    fdata.append("u_id", u_id);
    fdata.append("u_status", u_status);

    const resp = await updateStaffStatus(fdata);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    getstafflist();
  };

  const deletestaff = async (u_id) => {
    let fdata = new FormData();
    fdata.append("u_id", u_id);

    const resp = await deleteStaff(fdata);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      getstafflist();
      setDvisible({ mval: false, id: 0 });
    }
  };

  const expo_columns = [
    { label: "No", key: "u_id" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Sales Permission", key: "sales_permission" },
    { label: "Account Permission", key: "account_permission" },
    { label: "Management", key: "management_permission" },
  ];

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row.u_id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Name",
      wrap: true,
      selector: (row) => row.name,
    },
    {
      name: "Email",
      wrap: true,
      selector: (row) => row.email,
    },
    {
      name: "Sales Permision",
      wrap: true,
      cell: (row) => (
        <>
          <Icon>
            {row.sales_p == 1 ? (
              <CheckCircleRounded color="success" />
            ) : (
              <CancelRounded color="error" />
            )}
          </Icon>
        </>
      ),
    },
    loginUser.u_type == 0 && {
      name: "Account Permission",
      wrap: true,
      cell: (row) => (
        <>
          <Icon>
            {row.account_p == 1 ? (
              <CheckCircleRounded color="success" />
            ) : (
              <CancelRounded color="error" />
            )}
          </Icon>
        </>
      ),
    },
    {
      name: "Management",
      wrap: true,
      cell: (row) => (
        <>
          <Icon>
            {row.management_p == 1 ? (
              <CheckCircleRounded color="success" />
            ) : (
              <CancelRounded color="error" />
            )}
          </Icon>
        </>
      ),
    },
    {
      name: "Status",
      wrap: true,
      selector: (row) => (
        <>
          {row.u_status == 0 ? (
            <>
              <div style={{ margin: "10px 0" }}>
                <Label
                  variant="ghost"
                  color="success"
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    fontSize: "16px",
                  }}
                >
                  Active
                </Label>

                <Button
                  variant="contained"
                  onClick={() => {
                    changestatus(row.u_id, 1);
                  }}
                  style={{ background: "#ff4842" }}
                >
                  Deactive
                </Button>
              </div>
            </>
          ) : (
            <>
              <div style={{ margin: "10px 0" }}>
                <Label
                  variant="ghost"
                  color="error"
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    fontSize: "14px",
                  }}
                >
                  Deactive
                </Label>

                <Button
                  variant="contained"
                  onClick={() => {
                    changestatus(row.u_id, 0);
                  }}
                >
                  Active
                </Button>
              </div>
            </>
          )}
        </>
      ),
    },
    {
      name: "Action",
      wrap: true,
      cell: (row) => (
        <>
          <a
            onClick={() => {
              setStep(2);
              setformdata(row);
            }}
          >
            <Tooltip title="Edit">
              <IconButton>
                <EditSharp color="warning" />
              </IconButton>
            </Tooltip>
          </a>

          <a
            onClick={() => {
              setDvisible({ mval: true, id: row.u_id });
            }}
          >
            <Tooltip title="Delete">
              <IconButton>
                <DeleteSharp color="error" />
              </IconButton>
            </Tooltip>
          </a>
        </>
      ),
    },
  ].filter((f) => f != false);

  const submitformdata = async () => {
    if (
      formdata.name == undefined ||
      formdata.name == "" ||
      formdata.email == undefined ||
      formdata.email == "" ||
      formdata.mobile == undefined ||
      formdata.mobile == "" ||
      formdata.password == undefined ||
      formdata.password == ""
    ) {
      let notify = notification({
        type: "error",
        message: "Please Fill All Fields",
      });
      notify();
    } else if (
      formdata.sales_p == 0 &&
      formdata.account_p == 0 &&
      formdata.management_p == 0
    ) {
      let notify = notification({
        type: "error",
        message: "Please select atleast one permission",
      });
      notify();
    } else {
      let resp;
      if (step == 1) {
        resp = await insertStaff(JSON.stringify(formdata));
      }
      if (step == 2) {
        resp = await updateStaffDetails(JSON.stringify(formdata));
      }
      if (resp.status == 1) {
        setStep(0);
        setformdata({
          sales_p: 0,
          account_p: 0,
          management_p: 0,
          city_id: loginUser.city_id,
          p_user_id: loginUser.u_id,
        });
        getstafflist();
      }
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
    }
  };
  return (
    <>
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={deletestaff}
      />
      <Page title="Staff | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="h4" gutterBottom>
              Staff
            </Typography>
          </Stack>
          {step != 0 ? (
            <>
              <Button
                onClick={() => {
                  setStep(0);
                  setformdata({
                    sales_p: 0,
                    account_p: 0,
                    management_p: 0,
                    city_id: loginUser.city_id,
                    p_user_id: loginUser.u_id,
                  });
                }}
                style={{ marginBottom: "15px" }}
                variant="contained"
              >
                Back
              </Button>
              <Card
                style={{
                  padding: "25px",
                  paddingBottom: "25px",
                  marginBottom: "25px",
                }}
              >
                <Stack spacing={10} style={{ marginBottom: "15px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Name"
                          name="name"
                          value={formdata?.name}
                          onChange={onChangeFunction}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Contact No"
                          name="mobile"
                          value={formdata?.mobile}
                          onChange={onChangeFunction}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="email"
                          label="Email Id"
                          name="email"
                          value={formdata?.email}
                          onChange={onChangeFunction}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Password"
                          name="password"
                          value={formdata?.password}
                          onChange={onChangeFunction}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        label="Sales permission"
                        control={
                          <Checkbox
                            checked={formdata?.sales_p == 1 ? true : false}
                            onChange={(e) => {
                              setformdata({
                                ...formdata,
                                sales_p: e.target.checked ? 1 : 0,
                              });
                            }}
                          />
                        }
                      />
                      {loginUser.u_type == 0 && (
                        <>
                          <FormControlLabel
                            label="Account permission"
                            control={
                              <Checkbox
                                checked={
                                  formdata?.account_p == 1 ? true : false
                                }
                                onChange={(e) => {
                                  setformdata({
                                    ...formdata,
                                    account_p: e.target.checked ? 1 : 0,
                                  });
                                }}
                              />
                            }
                          />
                        </>
                      )}
                      <FormControlLabel
                        label="Management"
                        control={
                          <Checkbox
                            checked={formdata?.management_p == 1 ? true : false}
                            onChange={(e) => {
                              setformdata({
                                ...formdata,
                                management_p: e.target.checked ? 1 : 0,
                              });
                            }}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Stack>

                <Button
                  style={{ marginTop: "15px" }}
                  variant="contained"
                  onClick={submitformdata}
                >
                  Save
                </Button>
              </Card>
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  setStep(1);
                }}
                style={{ marginBottom: "15px" }}
                variant="contained"
              >
                Add Staff
              </Button>
              <Card>
                {staffdata && (
                  <>
                    <Button className="btn btn-export">
                      <CSVLink
                        data={staffdata}
                        headers={expo_columns}
                        filename={"StaffList.csv"}
                        className="btn btn-primary test"
                        target="_blank"
                      >
                        Export CSV
                      </CSVLink>
                    </Button>
                    <DataTable
                      title="Staff Record"
                      filter
                      pagination
                      highlightOnHover
                      columns={columns}
                      data={staffdata}
                    />
                  </>
                )}
              </Card>
            </>
          )}
        </Container>
      </Page>
    </>
  );
}
