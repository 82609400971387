import { filter } from "lodash";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import { Card, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import {
  fetchBusinessList,
  fetchVisitorList,
  fetchChaptList,
  fetchchapterbyuser,
  fetchVisitorByMember,
  fetchallcitylist,
} from "src/utils/common";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
// components
import Page from "../components/Page";
import { deleteVisitorData, GetMemberByChptId } from "src/api/commonapi";
import {
  DeleteForever,
  DeleteOutline,
  DeleteSharp,
  EditSharp,
} from "@mui/icons-material";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import moment from "moment";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";
import Label from "src/components/Label";

export default function VisitorReport() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });

  const [joindate, setjoinDate] = useState(null);
  const [chptdata, setchptdata] = useState([]);
  const [amount, setamount] = useState({});
  const [visitorData, setvisitorData] = useState([]);
  const [nvisitorData, setnvisitorData] = useState([]);
  const [chapoption, setchptoption] = useState([]);
  const [memberdata, setmemberdata] = useState([]);
  const [getdata, setgetdata] = useState({});
  const [step, setstep] = useState(0);
  const [filter, setFilter] = useState({
    chpt_id: 0,
    city_id:
      loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 &&
        loginUser?.p_user_id != 1 &&
        loginUser?.management_p == 1)
        ? loginUser?.city_id
        : 0,
    crossType: -1,
  });

  const [selectedmember, setselectedmember] = useState({
    defaulselect: "",
    m_id: 0,
  });

  const [filtereddata, setFilteredData] = useState({
    startDate: "",
    endDate: "",
  });

  const getchptlist = async () => {
    setchptdata(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  };
  useEffect(
    (async) => {
      chptdata?.map((option) => {
        chapoption.push({ value: option.chpt_id, label: option.chpt_name });
      });
    },
    [chptdata]
  );

  const filterData = (satrt, end) => {
    let nvisitor = [];

    nvisitor =
      visitorData &&
      visitorData.filter((visitor) => {
        if (
          moment(visitor.n_m_date, "DD-MM-YYYY").isBetween(
            satrt,
            end,
            "days",
            "[]"
          ) ||
          (satrt == "" && end == "")
        ) {
          let res = handleCrossFilter(visitor);
          return res;
        }
      });

    setnvisitorData(nvisitor);
  };

  const handleCrossFilter = (visitor) => {
    try {
      if (filter.crossType == 0 || filter.crossType == 1) {
        if (visitor.is_in_chapter == filter.crossType) return visitor;
      } else {
        return visitor;
      }
    } catch (e) {
      return;
    }
  };

  useEffect(async () => {
    if (
      filtereddata.startDate != undefined ||
      filtereddata.endDate != undefined
    ) {
      await filterData("", "");
    }
  }, [visitorData, filter]);

  const getmemberbychp = async (ch_id) => {
    setselectedmember({ defaulselect: "", m_id: 0 });
    setmemberdata(await GetMemberByChptId(ch_id));
  };
  const getvisitorlist = async (ch_id, m_id) => {
    setvisitorData(await fetchVisitorByMember(ch_id, m_id));
  };
  const deleteVisitor = async (v_id) => {
    const resp = await deleteVisitorData(v_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      getvisitorlist(filter.chpt_id, selectedmember.m_id);
      setDvisible({ mval: false, id: 0 });
    }
  };
  useEffect(async () => {
    getchptlist();
  }, []);
  const expo_columns = [
    { label: "No", key: "row_no" },
    { label: "Invite By", key: "m_name" },
    { label: "Date", key: "n_m_date" },
    { label: "Visitor Name", key: "n_m_person_name" },
    { label: "Visitor Business", key: "n_m_business_name" },
    { label: "Contact Number", key: "n_m_mo_no" },
    { label: "Business Category", key: "n_m_business_category" },
    { label: "Visitor Type", key: "visitor_in_chapter_type" },
    { label: "Invited At", key: "other_chapter_name" },
  ];

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row.row_no,
      sortable: true,
      width: "80px",
    },
    {
      name: "Invite By",
      wrap: true,
      selector: (row) => row.v_by_name + " (" + row.member_Business + ")",
      sortable: true,
    },
    {
      name: "Date",
      wrap: true,
      selector: (row) => row.n_m_date,
      sortable: true,
    },
    {
      name: "Visitor Name",
      wrap: true,
      selector: (row) => row.n_m_person_name,
      sortable: true,
    },
    {
      name: "Visitor Business",
      wrap: true,
      selector: (row) => row.n_m_business_name,
      sortable: true,
    },
    {
      name: "Contact Number",
      wrap: true,
      selector: (row) => row.n_m_mo_no,
      sortable: true,
    },
    {
      name: "Business Category",
      selector: (row) => row.n_m_business_category,
      wrap: true,
      sortable: true,
    },
    {
      name: "Visitor Type",
      sortable: true,
      wrap: true,
      selector: (row) => (
        <Label
          variant={"ghost"}
          color={row.is_in_chapter == 1 ? "info" : "success"}
        >
          {row.visitor_in_chapter_type}
        </Label>
      ),
    },
    {
      name: "Invited At",
      sortable: true,
      wrap: true,
      selector: (row) => row.other_chapter_name,
    },
  ];
  const changeChapterHandler = (e) => {
    setFilter({ ...filter, defaulselect: e, chpt_id: e.value });
    getmemberbychp(e.value);
  };

  const [citylist, setcitylist] = useState([]);
  const [chaptlist, setchaplist] = useState([]);

  const getallcitylist = async () => {
    setcitylist(await fetchallcitylist());
    setchaplist(
      await fetchchapterbyuser(loginUser?.city_id, loginUser?.u_type)
    );
  };
  useEffect(async () => {
    getallcitylist();
  }, []);

  const [inOutChapterOptions, setInOutChapterOptions] = useState([
    { value: -1, label: "All" },
    { value: 0, label: "In-Chapter" },
    { value: 1, label: "Cross-Chapter" },
  ]);
  return (
    <>
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={deleteVisitor}
      />
      <Page title="Visitor Report | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="h4" gutterBottom>
              Visitor Report
            </Typography>
          </Stack>
          <Card className="p-20">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>Select City</label>
                  <Select
                    menuPortalTarget={document.body}
                    isDisabled={
                      loginUser?.u_type == 1 ||
                      (loginUser?.u_type == 2 &&
                        loginUser?.p_user_id != 1 &&
                        loginUser?.management_p == 1)
                        ? true
                        : false
                    }
                    options={[
                      {
                        value: 0,
                        label: "All City",
                      },
                      ...citylist.map((city) => {
                        return {
                          label: city?.city_name,
                          value: city?.city_id,
                        };
                      }),
                    ]}
                    onChange={(e) => {
                      setFilter({ ...filter, city_id: e.value, chpt_id: 0 });
                    }}
                    value={
                      filter.city_id == 0
                        ? {
                            value: 0,
                            label: "All City",
                          }
                        : citylist.map((city) => {
                            return (
                              filter.city_id == city.city_id && {
                                value: city.city_id,
                                label: city.city_name,
                              }
                            );
                          })
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>Select Chapter</label>
                  <Select
                    name="chpt_id"
                    menuPortalTarget={document.body}
                    onChange={changeChapterHandler}
                    options={[
                      ...(chaptlist &&
                        chaptlist
                          ?.map((chpt) => {
                            if (filter?.city_id != 0) {
                              if (filter?.city_id == chpt?.city_id) {
                                return {
                                  value: chpt.chpt_id,
                                  label: chpt.chpt_name,
                                };
                              }
                            } else {
                              return {
                                value: chpt.chpt_id,
                                label: chpt.chpt_name,
                              };
                            }
                          })
                          .filter((f) => f != undefined)),
                    ]}
                    value={
                      chaptlist &&
                      chaptlist.map((c) => {
                        if (c.chpt_id == filter.chpt_id) {
                          return {
                            value: c.chpt_id,
                            label: c.chpt_name,
                          };
                        }
                      })
                    }
                  />
                  {/* <ChapterSelectDropDown
                    dropDownLabel={"Select Chapter"}
                    selectedState={filter}
                    chapterKey="chpt_id"
                    handleChange={changeChapterHandler}
                  /> */}
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={3}>
                  <label>Select Member</label>
                  <Select
                    menuPortalTarget={document.body}
                    value={selectedmember.defaulselect}
                    onChange={(e) => {
                      setselectedmember({ defaulselect: e, m_id: e.value });
                      getvisitorlist(filter.chpt_id, e.value);
                    }}
                    options={
                      memberdata &&
                      memberdata.map((option) => {
                        return { value: option.m_id, label: option.m_name };
                      })
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={2}>
                <Stack spacing={3}>
                  <label>In / Cross Chapter</label>
                  <Select
                    menuPortalTarget={document.body}
                    value={inOutChapterOptions.map((c) => {
                      if (c.value == filter.crossType) {
                        return { value: c.value, label: c.label };
                      }
                    })}
                    onChange={async (e) => {
                      setFilter({ ...filter, crossType: e.value });
                    }}
                    options={inOutChapterOptions}
                  />
                </Stack>
              </Grid>

              <Grid item xs={5}>
                <Stack spacing={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      type="date"
                      name="startdate"
                      label="Start Date "
                      value={
                        filtereddata?.startDate != ""
                          ? filtereddata?.startDate
                          : null
                      }
                      inputFormat="dd/MM/yyyy"
                      onChange={(newDate) => {
                        setFilteredData({
                          ...filtereddata,
                          startDate: newDate,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={5}>
                <Stack spacing={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      type="date"
                      name="enddate"
                      label="End Date "
                      value={
                        filtereddata?.endDate != ""
                          ? filtereddata?.endDate
                          : null
                      }
                      inputFormat="dd/MM/yyyy"
                      onChange={(newDate) => {
                        setFilteredData({
                          ...filtereddata,
                          endDate: newDate,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      filterData(
                        filtereddata?.startDate,
                        filtereddata?.endDate
                      );
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setFilteredData({ startDate: null, endDate: null });
                      filterData("", "");
                    }}
                    style={{ marginLeft: "5px" }}
                  >
                    Clear
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>
          <Card className="p-20" sx={{ mt: 2 }}>
            {nvisitorData && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={nvisitorData}
                    headers={expo_columns}
                    filename={
                      "Visitor Data" + moment().format("DD-MM-YYYY") + ".csv"
                    }
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  style={{ overflow: "hidden" }}
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={nvisitorData}
                />
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
