import React, { useState, useEffect } from "react";

import DataTable from "react-data-table-component";
import { styled } from "@mui/material/styles";
import * as XLSX from "xlsx";
import JSZip from "jszip";
import { saveAs } from "file-saver"; // For downloading the zip file
import {
  Box,
  Card,
  CircularProgress,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Page from "src/components/Page";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";
import Select from "react-select";
import { GetMemberByChptId, exportMemberSpecificData } from "src/api/commonapi";
import { DatePicker, LoadingButton, LocalizationProvider } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CSVLink } from "react-csv";
import { notification } from "src/utils/messages";
import {
  fetchallcitylist,
  fetchchapterbyuser,
  getMembersDetailedReportHandler,
  getMembersSpecificDetailedReportHandler,
} from "src/utils/common";
import { BorderBottom, Download, Send } from "@mui/icons-material";
// import { ExcelFile, ExcelSheet } from "react-data-export";

export default function ChapterMemberReportAllInOne() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "800px",
    width: "80%",
    maxWidth: "80%",
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 0.5,
  };
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer", // override the row height
      },
    },
  };
  const TabsWrapperStyle = styled("div")(({ theme }) => ({
    zIndex: 9,
    bottom: 0,
    width: "100%",
    display: "flex",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
      paddingRight: theme.spacing(3),
    },
  }));

  const memberActiveType = [
    { value: -1, label: "All" },
    { value: 1, label: "Active" },
    { value: 0, label: "De-Active" },
  ];
  const [filter, setFilter] = useState({
    city_id:
      loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 &&
        loginUser?.p_user_id != 1 &&
        loginUser?.management_p == 1)
        ? loginUser?.city_id
        : 0,
    chpt_id: 0,
    m_id: 0,
    mActive: -1,
    startDate: "",
    endDate: "",
  });
  const [loader, setLoader] = useState(false);
  const [memberData, setMemberData] = useState([]);
  const [memberFilterResult, setMemberFilterResult] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const toggleOpenDetailModal = () => setOpenModal(!openModal);
  const [memberDetailData, setMemberDetailData] = useState([]);
  const [activeRow, setActiveRow] = useState({});
  const [rowMemberId, setRowMemberId] = useState();
  const getmemberbychp = async (ch_id) => {
    // setselectedmember({ defaulselect: "", m_id: 0 });
    setMemberData(await GetMemberByChptId(ch_id));
  };

  let fileName = `${activeRow.m_name} (${activeRow.business_name}) ${
    activeTab == 1
      ? "V2V"
      : activeTab == 2
      ? "Reference Given"
      : activeTab == 3
      ? "Reference Received"
      : activeTab == 4
      ? "Business Given"
      : activeTab == 5
      ? "Business Received"
      : activeTab == 6
      ? "Invited Visitor"
      : activeTab == 7
      ? "New Member Induction"
      : activeTab == 8
      ? "Cross V2V"
      : activeTab == 9
      ? "Cross Reference Given"
      : activeTab == 10
      ? "Cross Reference Received"
      : activeTab == 11
      ? "Cross Business Given"
      : activeTab == 12
      ? "Cross Reference Received"
      : activeTab == 13
      ? "Testimonial Given"
      : activeTab == 14
      ? "Testimonial Received"
      : activeTab == 15
      ? "Cross Testimonial Given"
      : activeTab == 9
      ? "Cross Testimonial Received"
      : activeTab == 17
      ? "Pin Assign"
      : ""
  }`;

  const tabColumnsMemberDetailDataExportColumns = {
    1: [
      { label: "Date", key: "new_date" },
      { label: "Given", key: "Given" },
      { label: "Receiver", key: "Receiver" },
    ],
    2: [
      { label: "Date", key: "new_date" },
      { label: "Given", key: "Given" },
      { label: "Receiver", key: "Receiver" },
    ],
    3: [
      { label: "Date", key: "new_date" },
      { label: "Given", key: "Given" },
      { label: "Receiver", key: "Receiver" },
    ],
    4: [
      { label: "Date", key: "new_date" },
      { label: "Given", key: "Given" },
      { label: "Receiver", key: "Receiver" },
      { label: "Amount", key: "b_amount" },
    ],
    5: [
      { label: "Date", key: "new_date" },
      { label: "Given", key: "Given" },
      { label: "Receiver", key: "Receiver" },
      { label: "Amount", key: "b_amount" },
    ],
    6: [
      { label: "Date", key: "new_date" },
      { label: "Visitor Name", key: "n_m_person_name" },
      { label: "Visitor Mobile Number", key: "n_m_mo_no" },
      { label: "Visitor Business", key: "n_m_business_name" },
      { label: "Visitor Category", key: "n_m_business_category" },
    ],
    7: [
      { label: "Chapter Name", key: "memberChapter" },
      { label: "Member Name", key: "m_name" },
      { label: "Member Category", key: "business_category" },
      { label: "Member Join Date", key: "joining_date" },
      { label: "Member Expiry Date", key: "due_date" },
    ],
    8: [
      { label: "Date", key: "new_date" },
      { label: "Given", key: "Given" },
      { label: "Receiver", key: "Receiver" },
    ],
    9: [
      { label: "Date", key: "new_date" },
      { label: "Given", key: "Given" },
      { label: "Receiver", key: "Receiver" },
    ],
    10: [
      { label: "Date", key: "new_date" },
      { label: "Given", key: "Given" },
      { label: "Receiver", key: "Receiver" },
    ],
    11: [
      { label: "Date", key: "new_date" },
      { label: "Given", key: "Given" },
      { label: "Receiver", key: "Receiver" },
      { label: "Amount", key: "b_amount" },
    ],
    12: [
      { label: "Date", key: "new_date" },
      { label: "Given", key: "Given" },
      { label: "Receiver", key: "Receiver" },
      { label: "Amount", key: "b_amount" },
    ],
    13: [
      { label: "Date", key: "new_date" },
      { label: "Given", key: "Given" },
      { label: "Receiver", key: "Receiver" },
    ],
    14: [
      { label: "Date", key: "new_date" },
      { label: "Given", key: "Given" },
      { label: "Receiver", key: "Receiver" },
    ],
    15: [
      { label: "Date", key: "new_date" },
      { label: "Given", key: "Given" },
      { label: "Receiver", key: "Receiver" },
    ],
    16: [
      { label: "Date", key: "new_date" },
      { label: "Given", key: "Given" },
      { label: "Receiver", key: "Receiver" },
    ],
    17: [
      { label: "Assign Date", key: "date" },
      { label: "Pin Name", key: "name" },
      { label: "Member Name", key: "member_name" },
    ],
    18: [
      { label: "Date", key: "new_date" },
      { label: "Visitor Name", key: "n_m_person_name" },
      { label: "Visitor Mobile Number", key: "n_m_mo_no" },
      { label: "Visitor Business", key: "n_m_business_name" },
      { label: "Visitor Category", key: "n_m_business_category" },
      { label: "Invited At Chapter", key: "other_chapter" },
    ],
  };

  const memberDetailDataColumns = [
    (activeTab == 1 ||
      activeTab == 2 ||
      activeTab == 3 ||
      activeTab == 4 ||
      activeTab == 5 ||
      activeTab == 6 ||
      activeTab == 8 ||
      activeTab == 9 ||
      activeTab == 10 ||
      activeTab == 11 ||
      activeTab == 12 ||
      activeTab == 13 ||
      activeTab == 14 ||
      activeTab == 15 ||
      activeTab == 16) && {
      name: "Date",
      cell: (row) => row.new_date,
      width: "120px",
    },
    activeTab == 18 && {
      name: "Date",
      cell: (row) => row.new_date,
      width: "120px",
    },

    (activeTab == 1 ||
      activeTab == 2 ||
      activeTab == 3 ||
      activeTab == 4 ||
      activeTab == 5 ||
      activeTab == 8 ||
      activeTab == 9 ||
      activeTab == 10 ||
      activeTab == 11 ||
      activeTab == 12 ||
      activeTab == 13 ||
      activeTab == 14 ||
      activeTab == 15 ||
      activeTab == 16) && {
      name: activeTab == 1 || activeTab == 8 ? "V2V From" : "Given",
      selector: (row) => row.Given,
      // width: "180px",
      sortable: true,
    },
    (activeTab == 1 ||
      activeTab == 2 ||
      activeTab == 3 ||
      activeTab == 4 ||
      activeTab == 5 ||
      activeTab == 8 ||
      activeTab == 9 ||
      activeTab == 10 ||
      activeTab == 11 ||
      activeTab == 12 ||
      activeTab == 13 ||
      activeTab == 14 ||
      activeTab == 15 ||
      activeTab == 16) && {
      name: activeTab == 1 || activeTab == 8 ? "V2V To" : "Receiver",
      selector: (row) => row.Receiver,
      // width: "180px",
      sortable: true,
    },
    // Business Given & Receive
    (activeTab == 4 ||
      activeTab == 5 ||
      activeTab == 11 ||
      activeTab == 12) && {
      name: "Amount",
      selector: (row) => row.b_amount,
      sortable: true,
    },
    // Visitor
    (activeTab == 6 || activeTab == 18) && {
      name: "Visitor Name",
      selector: (row) => row.n_m_person_name,
      sortable: true,
    },
    (activeTab == 6 || activeTab == 18) && {
      name: "Visitor Mobile Number",
      selector: (row) => row.n_m_mo_no,
      sortable: true,
    },
    (activeTab == 6 || activeTab == 18) && {
      name: "Visitor Business",
      selector: (row) => row.n_m_business_name,
      sortable: true,
    },
    (activeTab == 6 || activeTab == 18) && {
      name: "Visitor Category",
      selector: (row) => row.n_m_business_category,
      sortable: true,
    },

    activeTab == 18 && {
      name: "Invited At Chapter",
      selector: (row) => row.other_chapter,
      sortable: true,
    },
    // New Members Induction
    activeTab == 7 && {
      name: "Chapter Name",
      selector: (row) => row.memberChapter,
      sortable: true,
    },
    activeTab == 7 && {
      name: "Member Name",
      width: "160px",
      selector: (row) => row.m_name,
      sortable: true,
    },

    activeTab == 7 && {
      name: "Member Category",
      selector: (row) => row.business_category,
      sortable: true,
    },
    activeTab == 7 && {
      name: "Member Join Date",
      selector: (row) => row.joining_date,
      sortable: true,
    },
    activeTab == 7 && {
      name: "Member Expiry Date",
      selector: (row) => row.due_date,
      sortable: true,
    },
    activeTab == 17 && {
      name: "Assign Date",
      cell: (row) => row.date,
      width: "120px",
    },
    activeTab == 17 && {
      name: "Pin Name",
      cell: (row) => row.name,
    },
    activeTab == 17 && {
      name: "Member Name",
      cell: (row) => row.member_name,
    },
    // New Member Induction
  ].filter((f) => f != false);

  const restFilterSearch = () => {
    setFilter({
      chpt_id: 0,
      m_id: 0,
      startDate: "",
      endDate: "",
    });
    setMemberData([]);
    setMemberFilterResult([]);
  };

  const searchMembersDetailsCounterReport = async () => {
    if (filter.chpt_id != 0) {
      setLoader(true);
      let resp = await getMembersDetailedReportHandler(filter);
      if (resp?.length > 0) {
        setMemberFilterResult(resp);
      } else {
        setMemberFilterResult([]);
      }
      setLoader(false);
    } else {
      setLoader(false);
      let notify = notification({
        type: "error",
        message: "Please select Chapter And Member",
      });
      notify();
    }
  };

  const getMembersDetailReport = async (value, memberId) => {
    let res = await getMembersSpecificDetailedReportHandler({
      ...filter,

      m_id: memberId != undefined ? memberId : filter.m_id,
      getData: value != undefined ? value : activeTab,
    });

    setMemberDetailData(res || []);
  };

  const handleClickRow = (row) => {
    setActiveRow(row);
  };

  function MyCustomDataTable() {
    return (
      <>
        <Button
          className="btn btn-export"
          sx={{
            margin: "0",
            padding: "0",
            marginTop: "10px",
            position: "absolute",
            right: "10px",
            top: "0",
          }}
        >
          <CSVLink
            data={memberDetailData}
            headers={tabColumnsMemberDetailDataExportColumns[activeTab]}
            className="btn btn-primary test"
            filename={`${fileName} Data .csv`}
            target="_blank"
          >
            Export CSV
          </CSVLink>
        </Button>
        <div style={{ marginTop: "20px" }}>
          <DataTable
            filter
            fixedHeaderScrollHeight="450px"
            fixedHeader={true}
            pagination
            highlightOnHover
            columns={memberDetailDataColumns}
            data={memberDetailData}
          />
        </div>
      </>
    );
  }

  const expo_columns = [
    { label: "Member Name", key: "new_member_name" },
    { label: "V2V", key: "TotalV2VCount" },
    { label: "Cross V2V", key: "TotalCrossV2VCount" },
    { label: "Reference Given", key: "TotalReferenceGiven" },
    { label: "Cross Reference Given", key: "TotalCrossReferenceGiven" },
    { label: "Reference Received", key: "TotalReferenceReceive" },
    { label: "Cross Reference Received", key: "TotalCrossReferenceReceive" },
    { label: "Business Given", key: "TotalBusinessGiven" },
    { label: "Cross Business Given", key: "TotalCrossBusinessGiven" },
    { label: "Business Received", key: "TotalBusinessReceive" },
    { label: "Cross Business Received", key: "TotalCrossBusinessReceive" },
    { label: "Testimonial Given", key: "TotalTestimonialGiven" },
    { label: "Cross Testimonial Given", key: "TotalCrossTestimonialGiven" },
    { label: "Testimonial Received", key: "TotalTestimonialReceive" },
    {
      label: "Cross Testimonial Received",
      key: "TotalCrossTestimonialReceive",
    },
    { label: "Visitor", key: "TotalVisitor" },
    { label: "Cross Visitor", key: "TotalCrossVisitor" },
    { label: "New Member Induction", key: "NewMemberInduction" },
    { label: "Pin Assign", key: "TotalPinAssign" },
  ];
  const columns = [
    {
      name: "Member Name",
      wrap: true,
      cell: (row) => row.new_member_name,
      width: "280px",

      sortable: true,
    },
    {
      name: "Member Data Export",
      wrap: true,
      sortable: true,
      width: "170px",
      cell: (row) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <CSVLink
              className="btn btn-primary test"
              data={[row]}
              headers={expo_columns}
              filename={`${row?.new_member_name}.csv`}
              target="_blank"
            >
              Export CSV
            </CSVLink>

            <Button
              color="warning"
              variant="contained"
              className="btn btn-export"
              onClick={() => {
                setRowMemberId(row?.m_id);
                exportData({
                  chpt_id: row.chpt_id,
                  m_id: row.m_id,
                  startDate: filter.startDate,
                  endDate: filter.endDate,
                });
              }}
              disabled={rowMemberId == row.m_id && loading}
            >
              Export Export
              {rowMemberId == row.m_id && loading && (
                <CircularProgress size={14} />
              )}
            </Button>
          </div>
        );
      },
    },
    {
      name: "Total V2V",
      wrap: true,
      selector: (row) => row.TotalV2VCount,
      width: "180px",
      sortable: true,
    },
    {
      name: "Total Cross V2V",
      wrap: true,
      selector: (row) => row.TotalCrossV2VCount,
      width: "180px",
      sortable: true,
    },

    {
      name: "Reference Given",
      wrap: true,
      selector: (row) => row.TotalReferenceGiven,
      width: "180px",
      sortable: true,
    },
    {
      name: "Cross Reference Given",
      wrap: true,
      selector: (row) => row.TotalCrossReferenceGiven,
      width: "180px",
      sortable: true,
    },
    {
      name: "Reference Received",
      wrap: true,
      selector: (row) => row.TotalReferenceReceive,
      width: "180px",
      sortable: true,
    },
    {
      name: "Cross Reference Received",
      wrap: true,
      selector: (row) => row.TotalCrossReferenceReceive,
      width: "180px",
      sortable: true,
    },
    {
      name: "Business Given",
      wrap: true,
      selector: (row) => row.TotalBusinessGiven,
      width: "180px",
      sortable: true,
    },
    {
      name: "Cross Business Given",
      wrap: true,
      selector: (row) => row.TotalCrossBusinessGiven,
      width: "180px",
      sortable: true,
    },
    {
      name: "Business Received",
      wrap: true,
      selector: (row) => row.TotalBusinessReceive,
      width: "180px",
      sortable: true,
    },
    {
      name: "Cross Business Received",
      wrap: true,
      selector: (row) => row.TotalCrossBusinessReceive,
      width: "180px",
      sortable: true,
    },
    // Testimonial
    {
      name: "Testimonial Given",
      wrap: true,
      selector: (row) => row.TotalTestimonialGiven,
      width: "180px",
      sortable: true,
    },
    {
      name: "Cross Testimonial Given",
      wrap: true,
      selector: (row) => row.TotalCrossTestimonialGiven,
      width: "180px",
      sortable: true,
    },
    {
      name: "Testimonial Received",
      wrap: true,
      selector: (row) => row.TotalTestimonialReceive,
      width: "180px",
      sortable: true,
    },
    {
      name: "Cross Testimonial Received",
      wrap: true,
      selector: (row) => row.TotalCrossTestimonialReceive,
      width: "180px",
      sortable: true,
    },
    {
      name: "Visitor",
      wrap: true,
      selector: (row) => row.TotalVisitor,
      width: "100px",
      sortable: true,
    },
    {
      name: "Cross Visitor",
      wrap: true,
      selector: (row) => row.TotalCrossVisitor,
      width: "100px",
      sortable: true,
    },
    {
      name: "New Member Induction",
      width: "100px",
      wrap: true,
      selector: (row) => row.NewMemberInduction,
      sortable: true,
    },
    {
      name: "Pin Assign",
      wrap: true,
      selector: (row) => row.TotalPinAssign,
      sortable: true,
    },
  ];
  const CustomBadges = ({ counter }) => {
    return (
      <div
        style={{
          background: "#034766",
          color: "#fff",
          padding: "2px 10px",
          borderRadius: "10px",
          marginLeft: "5px",
        }}
      >
        <span>{counter != undefined ? counter : 0}</span>
      </div>
    );
  };
  const [loading, setLoading] = useState(false);
  const exportData = async (filter) => {
    if (memberFilterResult.length != 0 && filter.m_id != 0) {
      if (filter.chpt_id != 0 || filter.m_id != 0) {
        setLoading(true);
        let res = await exportMemberSpecificData({
          chpt_id: filter.chpt_id,
          m_id: filter.m_id,
          startDate: filter.startDate,
          endDate: filter.endDate,
        });

        if (res?.status == 1) {
          let data = res?.data;
          const workbook = XLSX.utils.book_new();
          // Custom code for Export In Different Sheets Start
          Object.keys(data).map((key) => {
            //Define the columns you want to export On Sheet Wise
            const sheetColumn = data[key]?.column;
            const customColumn = data[key]?.mappingColumn;

            //  Filter and transform the data for the sheet
            const sheetFilteredData = data[key]?.data?.map((item) => {
              const filteredItem = {};
              sheetColumn?.forEach((column) => {
                filteredItem[column] = item[column];
              });
              return filteredItem;
            });
            // convert each sheet data in to row with Header and data Row
            const sheetArrayData = [customColumn].concat(
              sheetFilteredData?.map((item) =>
                sheetColumn?.map((column) => item[column])
              )
            );
            // Create Sheet and pass the data Array
            const sheet1WS = XLSX.utils.aoa_to_sheet(sheetArrayData);
            XLSX.utils.book_append_sheet(
              workbook,
              sheet1WS,
              key.replace(/_/g, " ")
            );
          });
          // Export the workbook as a blob For Download
          XLSX.writeFile(
            workbook,
            `${memberFilterResult[0].new_member_name}.xlsx`
          );

          setLoading(false);
          setRowMemberId();
        } else {
          let notify = notification({
            type: res.status == 0 ? "error" : "success",
            message: res.message,
          });
          notify();
          setRowMemberId();
          setLoading(false);
        }
      } else {
        let notify = notification({
          type: "error",
          message: "Please select Chapter & Member",
        });
        notify();
        setLoading(false);
        setRowMemberId();
      }
    } else {
      let notify = notification({
        type: "error",
        message: "First Need to Get Member's Data",
      });
      notify();
    }
  };

  const [loadExportExcelZipBtn, setLoadExportExcelZipBtn] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState("");

  const exportExcelZip = async () => {
    const zip = new JSZip();
    try {
      setLoadExportExcelZipBtn(true);

      if (memberData?.length != 0) {
        for (const e of memberData) {
          if (filter.mActive == -1 || filter.mActive == e.status) {
            let fileName = `${e?.m_name} ${
              e.status == 1 ? "[Active]" : "[De-Active]"
            }`;
            let res = await exportMemberSpecificData({
              chpt_id: filter.chpt_id,
              m_id: e.m_id,
              startDate: filter.startDate,
              endDate: filter.endDate,
            });

            if (res?.status == 1) {
              let data = res?.data;
              const workbook = XLSX.utils.book_new();

              for (const [key, sheetData] of Object.entries(data)) {
                //Define the columns you want to export On Sheet Wise
                const {
                  column: sheetColumn,
                  mappingColumn: customColumn,
                } = sheetData;

                //  Filter and transform the data for the sheet
                const sheetFilteredData = sheetData.data.map((item) => {
                  const filteredItem = {};
                  sheetColumn.forEach((column) => {
                    filteredItem[column] = item[column];
                  });
                  return filteredItem;
                });
                // convert each sheet data in to row with Header and data Row
                const sheetArrayData = [customColumn].concat(
                  sheetFilteredData.map((item) =>
                    sheetColumn.map((column) => item[column])
                  )
                );
                // Create Sheet and pass the data Array
                const worksheet = XLSX.utils.aoa_to_sheet(sheetArrayData);
                XLSX.utils.book_append_sheet(
                  workbook,
                  worksheet,
                  key.replace(/_/g, " ")
                );

                // Convert the workbook to a binary blob (array format)
                const binaryBlob = XLSX.write(workbook, {
                  bookType: "xlsx",
                  type: "array",
                });

                zip.file(`${fileName}.xlsx`, binaryBlob);
              }
            }
          }
        }
        // Generate the zip file as a binary blob
        const content = await zip.generateAsync({ type: "blob" });
        saveAs(content, `${selectedChapter} All Member Excel.zip`);
        setLoadExportExcelZipBtn(false);
      } else {
        setLoadExportExcelZipBtn(false);
        // No member in chapters
      }
    } catch (error) {
      setLoadExportExcelZipBtn(false);
      let notify = notification({
        type: "error",
        message: "Somethings wants wrong While Create Zip",
      });
      notify();
      setLoading(false);
    }
  };
  const tabTitles = {
    1: "V2V List",
    2: "Reference Given List",
    3: "Reference Received List",
    4: "Business Given List",
    5: "Business Received List",
    6: "Visitor List",
    7: "New Member Induction List",
    8: "Cross V2V List",
    9: "Cross Reference Given List",
    10: "Cross Reference Received List",
    11: "Cross Business Given List",
    12: "Cross Business Received List",
    13: "Testimonial Given List",
    14: "Testimonial Received List",
    15: "Cross Testimonial Given List",
    16: "Cross Testimonial Received List",
    17: "Pin Assign List List",
    18: "Cross Visitor List",
  };

  const [citylist, setcitylist] = useState([]);

  const getallcitylist = async () => {
    setcitylist(await fetchallcitylist());
  };
  useEffect(async () => {
    getallcitylist();
    setchaplist(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  }, []);

  const [chaptlist, setchaplist] = useState([]);

  return (
    <Page title="Chapter's Member All Report | Local Vocal Business Group">
      <Modal
        open={openModal}
        onClose={() => (setMemberDetailData([]), toggleOpenDetailModal())}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            style={{ marginBottom: "5px", textAlign: "center" }}
          >
            Member Detail : {activeRow?.new_member_name}
          </Typography>
          <hr />
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={activeTab}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="wrapped visible scrollable auto  arrows tabs example"
              allowScrollButtonsMobile
              onChange={(e, value) => (
                setMemberDetailData([]),
                setActiveTab(value),
                getMembersDetailReport(value, activeRow.m_id)
              )}
            >
              <Tab
                disableRipple
                key={1}
                value={1}
                label="V2V"
                wrapped
                icon={<CustomBadges counter={activeRow?.TotalV2VCount} />}
                iconPosition="end"
              />
              <Tab
                disableRipple
                key={8}
                value={8}
                wrapped
                label="Cross V2V"
                icon={<CustomBadges counter={activeRow?.TotalCrossV2VCount} />}
                iconPosition="end"
              />
              <Tab
                disableRipple
                key={2}
                value={2}
                wrapped
                icon={<CustomBadges counter={activeRow?.TotalReferenceGiven} />}
                iconPosition="end"
                label="Reference Given"
              />
              <Tab
                disableRipple
                key={9}
                value={9}
                wrapped
                icon={
                  <CustomBadges counter={activeRow?.TotalCrossReferenceGiven} />
                }
                iconPosition="end"
                label="Cross Reference Given"
              />
              <Tab
                disableRipple
                key={3}
                value={3}
                wrapped
                icon={
                  <CustomBadges counter={activeRow?.TotalReferenceReceive} />
                }
                iconPosition="end"
                label="Reference Received"
              />
              <Tab
                disableRipple
                key={10}
                value={10}
                wrapped
                icon={
                  <CustomBadges
                    counter={activeRow?.TotalCrossReferenceReceive}
                  />
                }
                iconPosition="end"
                label="Cross Reference Received"
              />
              <Tab
                disableRipple
                key={4}
                value={4}
                wrapped
                icon={
                  <CustomBadges
                    counter={
                      activeRow?.TotalBusinessGiven < 1000
                        ? activeRow?.TotalBusinessGiven?.split("(")[0].trim()
                        : activeRow?.TotalBusinessGiven
                    }
                  />
                }
                iconPosition="end"
                label="Business Given"
              />
              <Tab
                disableRipple
                key={11}
                value={11}
                wrapped
                icon={
                  <CustomBadges
                    counter={
                      activeRow?.TotalCrossBusinessGiven > 1000
                        ? activeRow?.TotalCrossBusinessGiven?.split(
                            "("
                          )[0].trim()
                        : activeRow?.TotalCrossBusinessGiven
                    }
                  />
                }
                iconPosition="end"
                label="Cross Business Given"
              />

              <Tab
                disableRipple
                key={5}
                value={5}
                wrapped
                icon={
                  <CustomBadges
                    counter={
                      activeRow?.TotalBusinessReceive < 1000
                        ? activeRow?.TotalBusinessReceive?.split("(")[0].trim()
                        : activeRow?.TotalBusinessReceive
                    }
                  />
                }
                iconPosition="end"
                label="Business Received"
              />
              <Tab
                disableRipple
                key={12}
                value={12}
                wrapped
                icon={
                  <CustomBadges
                    counter={
                      activeRow?.TotalCrossBusinessReceive > 1000
                        ? activeRow?.TotalCrossBusinessReceive?.split(
                            "("
                          )[0].trim()
                        : activeRow?.TotalCrossBusinessReceive
                    }
                  />
                }
                iconPosition="end"
                label="Cross Business Received"
              />

              <Tab
                disableRipple
                key={13}
                value={13}
                wrapped
                icon={
                  <CustomBadges counter={activeRow?.TotalTestimonialGiven} />
                }
                iconPosition="end"
                label="Testimonial Given"
              />
              <Tab
                disableRipple
                key={15}
                value={15}
                wrapped
                icon={
                  <CustomBadges
                    counter={activeRow?.TotalCrossTestimonialGiven}
                  />
                }
                iconPosition="end"
                label="Cross Testimonial Given"
              />
              <Tab
                disableRipple
                key={14}
                value={14}
                wrapped
                icon={
                  <CustomBadges counter={activeRow?.TotalTestimonialReceive} />
                }
                iconPosition="end"
                label="Testimonial Received"
              />

              <Tab
                disableRipple
                key={16}
                value={16}
                wrapped
                icon={
                  <CustomBadges
                    counter={activeRow?.TotalCrossTestimonialReceive}
                  />
                }
                iconPosition="end"
                label="Cross Testimonial Received"
              />

              <Tab
                disableRipple
                key={6}
                value={6}
                wrapped
                icon={<CustomBadges counter={activeRow?.TotalVisitor} />}
                iconPosition="end"
                label="Visitor"
              />
              <Tab
                disableRipple
                key={18}
                value={18}
                wrapped
                icon={<CustomBadges counter={activeRow?.TotalCrossVisitor} />}
                iconPosition="end"
                label="Cross Visitor"
              />
              <Tab
                disableRipple
                key={7}
                value={7}
                wrapped
                icon={<CustomBadges counter={activeRow?.NewMemberInduction} />}
                iconPosition="end"
                label="New Member Induction"
              />

              <Tab
                disableRipple
                key={17}
                value={17}
                wrapped
                icon={<CustomBadges counter={activeRow?.TotalPinAssign} />}
                iconPosition="end"
                label="Total Pin Assign"
              />
              {/* <Tab style={{ background: "red", color: "#fff" }}>AA</Tab> */}
            </Tabs>
          </Box>
          {/* Tab Container */}
          <div style={{ padding: "5px 10px", position: "relative" }}>
            <div key={1}>
              <h3>{tabTitles[activeTab] || ""}</h3>
            </div>
            <div>
              <MyCustomDataTable />
            </div>
          </div>
        </Box>
      </Modal>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h4" gutterBottom>
            Chapter's Member All Report
          </Typography>
        </Stack>
        <Card className="p-20">
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Stack spacing={0}>
                <label>Select City</label>
                <Select
                  menuPortalTarget={document.body}
                  isDisabled={
                    loginUser?.u_type == 1 ||
                    (loginUser?.u_type == 2 &&
                      loginUser?.p_user_id != 1 &&
                      loginUser?.management_p == 1)
                      ? true
                      : false
                  }
                  options={[
                    {
                      value: 0,
                      label: "All City",
                    },
                    ...citylist.map((city) => {
                      return {
                        label: city?.city_name,
                        value: city?.city_id,
                      };
                    }),
                  ]}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      city_id: e.value,
                      chpt_id: 0,
                    });
                  }}
                  value={
                    filter.city_id == 0
                      ? {
                          value: 0,
                          label: "All City",
                        }
                      : citylist.map((city) => {
                          return (
                            filter.city_id == city.city_id && {
                              value: city.city_id,
                              label: city.city_name,
                            }
                          );
                        })
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={0}>
                <label>Select Chapter</label>
                <Select
                  name="chpt_id"
                  menuPortalTarget={document.body}
                  onChange={(e) => {
                    setSelectedChapter(e?.label);
                    setFilter({ ...filter, chpt_id: e.value, m_id: 0 });
                    getmemberbychp(e.value);
                  }}
                  options={[
                    ...(chaptlist &&
                      chaptlist
                        ?.map((chpt) => {
                          if (filter?.city_id != 0) {
                            if (filter?.city_id == chpt?.city_id) {
                              return {
                                value: chpt.chpt_id,
                                label: chpt.chpt_name,
                              };
                            }
                          } else {
                            return {
                              value: chpt.chpt_id,
                              label: chpt.chpt_name,
                            };
                          }
                        })
                        .filter((f) => f != undefined)),
                  ]}
                  value={
                    chaptlist &&
                    chaptlist.map((c) => {
                      if (c.chpt_id == filter.chpt_id) {
                        return {
                          value: c.chpt_id,
                          label: c.chpt_name,
                        };
                      }
                    })
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={3}>
                <label>Member Active Type</label>
                <Select
                  menuPortalTarget={document.body}
                  value={
                    memberActiveType &&
                    memberActiveType.map((option) => {
                      if (option.value == filter.mActive) {
                        return { value: option.value, label: option.label };
                      }
                    })
                  }
                  onChange={(e) => {
                    setFilter({ ...filter, mActive: e.value });
                  }}
                  options={
                    memberActiveType &&
                    memberActiveType.map((option) => {
                      return { value: option.value, label: option.label };
                    })
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={3}>
                <label>Select Member</label>
                <Select
                  menuPortalTarget={document.body}
                  value={
                    filter.m_id == 0
                      ? {
                          value: 0,
                          label: "All Members",
                        }
                      : memberData.map((option) => {
                          return (
                            filter.m_id == option.m_id && {
                              value: option.value,
                              label: option.m_name,
                            }
                          );
                        })
                  }
                  onChange={(e) => {
                    setFilter({ ...filter, m_id: e.value });
                  }}
                  options={[
                    {
                      value: 0,
                      label: "All Members",
                    },
                    ...memberData
                      .map((option) => {
                        if (
                          filter.mActive == -1 ||
                          option.status == filter.mActive
                        ) {
                          return {
                            value: option.m_id,
                            label: option.m_name,
                          };
                        }
                        return null;
                      })
                      .filter(Boolean),
                  ]}
                />
              </Stack>
            </Grid>
            <Grid item xs={5}>
              <Stack spacing={0} style={{ marginTop: "15px" }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  style={{ marginTop: "15px", background: "yellow" }}
                >
                  <DatePicker
                    type="date"
                    name="startdate"
                    label="Start Date "
                    value={filter?.startDate != "" ? filter?.startDate : null}
                    style={{ background: "red" }}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newDate) => {
                      setFilter({
                        ...filter,
                        startDate: newDate,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={5}>
              <Stack spacing={0} style={{ marginTop: "15px" }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  style={{ marginTop: "15px", background: "yellow" }}
                >
                  <DatePicker
                    type="date"
                    name="endDate"
                    label="End Date "
                    value={filter?.endDate != "" ? filter?.endDate : null}
                    style={{ background: "red" }}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newDate) => {
                      setFilter({
                        ...filter,
                        endDate: newDate,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid
              xs={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={searchMembersDetailsCounterReport}
              >
                Search
              </Button>
              <Button
                variant="outlined"
                onClick={restFilterSearch}
                style={{ marginLeft: "5px" }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Card>

        <Card
          style={{
            paddingTop: "25px",
            paddingBottom: "25px",
            marginTop: "25px",
          }}
        >
          <>
            <Button className="btn btn-export">
              <CSVLink
                data={memberFilterResult}
                headers={expo_columns}
                filename={"MembersDetail.csv"}
                className="btn btn-primary test"
                target="_blank"
              >
                Export CSV
              </CSVLink>
            </Button>
            {filter.m_id != 0 && (
              <Button
                color="warning"
                variant="contained"
                className="btn btn-export"
                onClick={() => exportData(filter)}
                disabled={loading}
              >
                Member All Data Export
                {loading && <CircularProgress size={14} />}
              </Button>
            )}

            {filter.m_id == 0 && (
              <Button
                color="secondary"
                variant="contained"
                className="btn btn-export"
                onClick={exportExcelZip}
                style={{ marginRight: "10px" }}
                disabled={loadExportExcelZipBtn}
              >
                Member Data Zip Download{" "}
                {loadExportExcelZipBtn && <CircularProgress size={14} />}
              </Button>
            )}

            <DataTable
              // style={{ overflow: "hidden" }}
              filter
              pagination
              highlightOnHover
              progressPending={loader}
              columns={columns}
              onRowClicked={(row) => (
                setActiveTab(1),
                handleClickRow(row),
                getMembersDetailReport(1, row.m_id),
                toggleOpenDetailModal()
                // ,
                // toggleOpenDetailModal()
              )}
              data={memberFilterResult}
              customStyles={customStyles}
            />
          </>
        </Card>

        {/* <Grid container spacing={1}>
          <Grid item xs={6}>
            <Card
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                marginTop: "25px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0 15px",
                }}
              >
                <Typography variant="h6" align="center">
                  V2V
                </Typography>
                <Button
                  className="btn btn-export"
                  style={{ margin: "0", padding: "0" }}
                >
                  <CSVLink
                    data={""}
                    headers={""}
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
              </div>

              <div style={{ marginTop: "10px" }}>
                <DataTable
                  title={""}
                  style={{ overflow: "hidden" }}
                  filter
                  // progressPending={pending}
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={[]}
                />
              </div>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card
              style={{
                paddingTop: "25px",
                paddingBottom: "25px",
                marginTop: "25px",
              }}
            >
              xyz
            </Card>
          </Grid>
        </Grid> */}
      </Container>
    </Page>
  );
}
