import { filter } from "lodash";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Label from "../components/Label";
import {
  DeleteSharp,
  CalendarMonth,
  EditSharp,
  PersonAddAlt1,
  AssignmentInd,
} from "@mui/icons-material";

// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Button,
  Grid,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import Select from "react-select";
// components
import Page from "../components/Page";
import { LandmarkForm } from "../components/Form/Landmark";
import { notification } from "src/utils/messages";

import { fetchLandmarkList } from "src/utils/common";
import { updatelandmark, UpdateLandmarkStatus } from "src/api/commonapi";

import { fetchCityList } from "src/utils/common";
import { fetchallstatelist } from "src/utils/common";
import { insertlandmark } from "src/api/commonapi";
import { CSVLink } from "react-csv";

export default function Landmark() {
  const [landmarklist, setlandmarklist] = useState([]);
  const [newlandmarklist, setnewlandmarklist] = useState([]);
  const [filterlandmark, setfilterlandmark] = useState("");
  const [statelist, setstatelist] = useState(null);
  const [citylist, setcitylist] = useState(null);
  const [statename, setstatename] = useState(null);
  const [selectedItems, setselectedItems] = useState(null);
  const [formdata, setformdata] = useState([]);
  const [step, setStep] = useState(0);

  const filterData = () => {
    let nlandmark = [];
    nlandmark =
      landmarklist &&
      landmarklist.filter((landmark) => {
        if (
          landmark.l_name.toLowerCase().includes(filterlandmark?.toLowerCase())
        ) {
          return landmark;
        }
      });

    setnewlandmarklist(nlandmark);
  };

  useEffect(async () => {
    if (filterlandmark != undefined) {
      await filterData();
    }
  }, [filterlandmark, landmarklist]);

  const getlandmarklist = async () => {
    setlandmarklist(await fetchLandmarkList());
  };

  const changestatus = async (l_id, l_status) => {
    let fdata = new FormData();
    fdata.append("l_id", l_id);
    fdata.append("l_status", l_status);

    const resp = await UpdateLandmarkStatus(fdata);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    getlandmarklist();
  };
  useEffect(async () => {
    getlandmarklist();
  }, []);

  // Form
  const getcitylist = async (state_id) => {
    setcitylist(await fetchCityList(state_id));
  };

  const getstatelist = async () => {
    setstatelist(await fetchallstatelist());
  };
  useEffect(async () => {
    getstatelist();
  }, []);
  const handlesubmit = async (e) => {
    e.preventDefault();
    if (step != 2) {
      if (
        formdata.city_id == "" ||
        formdata.city_id == undefined ||
        formdata.l_name == "" ||
        formdata.l_name == undefined
      ) {
        let notify = notification({
          type: "error",
          message: "Please Fill Required Fields",
        });
        notify();
      } else {
        let resp = null;
        const jslandmarkdata = JSON.stringify(formdata);
        resp = await insertlandmark(jslandmarkdata);
        let notify = notification({
          type: resp.status == 0 ? "error" : "success",
          message: resp.message,
        });
        notify();

        if (resp.status == 1) {
          setstatelist(null);
          setcitylist(null);
          setformdata([]);
          getlandmarklist();
        }
      }
    } else {
      if (
        formdata.city_id == "" ||
        formdata.city_id == undefined ||
        formdata.l_name == "" ||
        formdata.l_name == undefined
      ) {
        let notify = notification({
          type: "error",
          message: "Please Fill Required Fields",
        });
        notify();
      } else {
        let resp = null;
        const jslandmarkdata = JSON.stringify(formdata);
        resp = await updatelandmark(jslandmarkdata);
        let notify = notification({
          type: resp.status == 0 ? "error" : "success",
          message: resp.message,
        });
        notify();
        if (resp.status == 1) {
          setstatelist(null);
          setcitylist(null);
          setformdata([]);
          getlandmarklist();
        }
      }
    }
  };

  const expo_columns = [
    { label: "No", key: "l_id" },
    { label: "City Name", key: "city_name" },
    { label: "State Name", key: "state_name" },
    { label: "Landmark Name", key: "l_name" },
  ];

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row.l_id,
      sortable: true,
    },
    {
      name: "City Name",
      wrap: true,
      selector: (row) => row.city_name,
      sortable: true,
    },
    {
      name: "State Name",
      wrap: true,
      selector: (row) => row.state_name,
      sortable: true,
    },
    {
      name: "Landmark Name",
      wrap: true,
      selector: (row) => row.l_name,
      sortable: true,
    },
    {
      name: "Status",
      wrap: true,
      selector: (row) => (
        <>
          {row.l_status == 0 ? (
            <>
              <div style={{ margin: "10px 0" }}>
                <Label
                  variant="ghost"
                  color="success"
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    fontSize: "16px",
                  }}
                >
                  Active
                </Label>

                <Button
                  variant="contained"
                  onClick={() => {
                    changestatus(row.l_id, 1);
                  }}
                  style={{ background: "#ff4842" }}
                >
                  Deactive
                </Button>
              </div>
            </>
          ) : (
            <>
              <div style={{ margin: "10px 0" }}>
                <Label
                  variant="ghost"
                  color="error"
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    fontSize: "14px",
                  }}
                >
                  Deactive
                </Label>

                <Button
                  variant="contained"
                  onClick={() => {
                    changestatus(row.l_id, 0);
                  }}
                >
                  Active
                </Button>
              </div>
            </>
          )}
        </>
      ),
    },
    {
      name: "Action",
      wrap: true,
      cell: (row) => (
        <>
          <a
            onClick={() => {
              setformdata(row);
              getstatelist();
              getcitylist(row.state_id);
              setStep(2);
            }}
          >
            <Tooltip title="Edit Chapter">
              <IconButton>
                <EditSharp color="warning" />
              </IconButton>
            </Tooltip>
          </a>
        </>
      ),
    },
  ];
  return (
    <Page title="Landmark | Local Vocal Business Group">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h4" gutterBottom>
            Landmark
          </Typography>
        </Stack>
        {/* Start Category Form */}
        <Card
          style={{
            padding: "25px",
            paddingBottom: "25px",
            marginBottom: "25px",
          }}
        >
          <Stack spacing={10} style={{ marginBottom: "15px" }}>
            <label style={{ marginBottom: "10px" }}>Select State</label>
            <Select
              value={
                statelist != null &&
                statelist
                  .map((c) => {
                    if (c.state_id == formdata?.state_id) {
                      return { value: c.state_id, label: c.state_name };
                    }
                  })
                  .filter((n) => n)[0]
              }
              style={{ marginTop: "0px" }}
              name="state"
              onChange={(e) => {
                setformdata({ ...formdata, state_id: e.value });
                getcitylist(e.value);
              }}
              options={
                statelist != null &&
                statelist.map((chpt) => {
                  return { value: chpt.state_id, label: chpt.state_name };
                })
              }
            ></Select>
          </Stack>
          <Stack spacing={10} style={{ marginBottom: "15px" }}>
            <label style={{ marginBottom: "10px" }}>Select City</label>
            <Select
              value={
                citylist != null &&
                citylist
                  .map((c) => {
                    if (c.city_id == formdata?.city_id) {
                      return { value: c.city_id, label: c.city_name };
                    }
                  })
                  .filter((n) => n)[0]
              }
              style={{ marginTop: "0px" }}
              name="city_id"
              onChange={(e) => setformdata({ ...formdata, city_id: e.value })}
              options={
                citylist != null &&
                citylist.map((chpt) => {
                  return { value: chpt.city_id, label: chpt.city_name };
                })
              }
            ></Select>
          </Stack>

          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="off"
              type="text"
              label="Landmark Name"
              name="l_name"
              value={formdata.l_name ? formdata.l_name : ""}
              onChange={(e) =>
                setformdata({ ...formdata, l_name: e.target.value })
              }
            />
          </Stack>

          <Button
            style={{ marginTop: "15px" }}
            type="submit"
            onClick={handlesubmit}
            variant="contained"
          >
            save
          </Button>
        </Card>
        {/* End Category Form */}
        <Card style={{ padding: "25px" }}>
          <Grid item xs={6}>
            <Stack spacing={3} style={{ marginBottom: "15px" }}>
              <label> Search Landmark </label>
              <TextField
                style={{ marginTop: "5px" }}
                fullWidth
                autoComplete="off"
                type="text"
                label="Search Landmark"
                name="search"
                value={filterlandmark}
                onChange={(e) => {
                  setfilterlandmark(e.target.value);
                }}
              />
            </Stack>
          </Grid>
          {landmarklist && (
            <>
              <Button className="btn btn-export">
                <CSVLink
                  data={newlandmarklist}
                  headers={expo_columns}
                  filename={"LandMark Data.csv"}
                  className="btn btn-primary test"
                  target="_blank"
                >
                  Export CSV
                </CSVLink>
              </Button>
              <DataTable
                title="Landmark List"
                filter
                pagination
                highlightOnHover
                columns={columns}
                data={newlandmarklist}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
