import { filter } from "lodash";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import {
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputAdornment,
  Switch,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";

import {
  Stack,
  Container,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
// components
import Page from "../components/Page";
import { MemberForm } from "../components/Form/Member";
import { borderRadius } from "@mui/system";

import { UpdateSetting, getSetting } from "src/api/updateSetting";
import { notification } from "src/utils/messages";

export default function Setting() {
  const navigate = useNavigate();
  const [joindate, setjoinDate] = useState(null);
  const [getdata, setgetdata] = useState({});

  async function fetchchapterlist() {
    try {
      const resp = await getSetting();
      if (resp.status == 1) {
        setgetdata(resp.data);
        const test = new Date(resp.data.lvb_date);
        setjoinDate(test);
      }
    } catch {}
  }

  useEffect(() => {
    fetchchapterlist();
  }, []);

  const onChangeFunction = (e) => {
    setgetdata({
      ...getdata,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    const injoindate = joindate;
    const dd = injoindate.getDate();
    const mm = injoindate.getMonth() + 1;
    const yy = injoindate.getFullYear();
    const date = dd + "-" + mm + "-" + yy;
    const settingData = {
      s_id: 1,
      joindate: date,
      v2v: getdata.v2v,
      reference: getdata.reference,
      visitor: getdata.visitor,
      business: getdata.business,
      member: getdata.member,
      m_r_fee: getdata.m_r_fee,
      n_m_fee: getdata.n_m_fee,
      gst: getdata.gst,
      b_message: getdata.b_message,
      absent_sms_permission: getdata.absent_sms_permission,
      enable_advance_search: getdata.enable_advance_search,
      blue_tick_point: getdata.blue_tick_point,
      green_tick_point: getdata.green_tick_point,
    };

    const jssettingData = JSON.stringify(settingData);
    const resp = await UpdateSetting(jssettingData);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();

    if (resp.status == 1) {
      fetchchapterlist();
    }
  };

  return (
    <Page title="Setting | Local Vocal Business Group">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h4" gutterBottom>
            Setting
          </Typography>
        </Stack>
        <Card style={{ marginBottom: "25px", padding: "25px" }}>
          <Stack spacing={10} style={{ marginBottom: "15px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Stack spacing={0} style={{ marginBottom: "15px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    style={{ marginTop: "15px" }}
                  >
                    <DatePicker
                      type="date"
                      label="Joining Date "
                      value={joindate}
                      disableFuture
                      onChange={(newDate) => setjoinDate(newDate)}
                      renderInput={(params) => <TextField {...params} />}
                      inputFormat="dd/MM/yyyy"
                      defaultValue="0"
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Send SMS to Member</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            name="absent_sms_permission"
                            checked={
                              getdata.absent_sms_permission == 0 ? false : true
                            }
                            onChange={(e) => {
                              let x = 0;
                              if (e.target.checked == true) x = 1;

                              setgetdata({
                                ...getdata,
                                [e.target.name]: x,
                              });
                            }}
                            value="0"
                          />
                        }
                        label="Send Absent SMS to Member"
                      />
                    </FormGroup>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="V2V"
                    name="v2v"
                    id="outlined-required"
                    onChange={onChangeFunction}
                    value={getdata.v2v}
                    defaultValue="0"
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="Reference"
                    name="reference"
                    onChange={onChangeFunction}
                    value={getdata.reference}
                    defaultValue="0"
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="Business"
                    name="business"
                    onChange={onChangeFunction}
                    value={getdata.business}
                    defaultValue="0"
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="Visitor"
                    name="visitor"
                    onChange={onChangeFunction}
                    value={getdata.visitor}
                    defaultValue="0"
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="Member Strength"
                    name="member"
                    onChange={onChangeFunction}
                    value={getdata.member}
                    defaultValue="0"
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="New Member Fees"
                    name="n_m_fee"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                    onChange={onChangeFunction}
                    value={getdata?.n_m_fee}
                    defaultValue="0"
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="Member Renewal Fees"
                    name="m_r_fee"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                    onChange={onChangeFunction}
                    value={getdata?.m_r_fee}
                    defaultValue="0"
                  />
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="GST"
                    name="gst"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    onChange={onChangeFunction}
                    value={getdata?.gst}
                    defaultValue="0"
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="Birthday Message"
                    name="b_message"
                    value={getdata?.b_message}
                    onChange={onChangeFunction}
                    defaultValue=" "
                  />
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Stack>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Enable Advance Member Search
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            name="enable_advance_search"
                            checked={
                              getdata.enable_advance_search == 0 ? false : true
                            }
                            onChange={(e) => {
                              let x = 0;
                              if (e.target.checked == true) x = 1;

                              setgetdata({
                                ...getdata,
                                [e.target.name]: x,
                              });
                            }}
                            value="0"
                          />
                        }
                        label="Enable Advance Member Search"
                      />
                    </FormGroup>
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="OBT Complete Point"
                    name="green_tick_point"
                    id="outlined-required"
                    onChange={onChangeFunction}
                    value={getdata.green_tick_point}
                    defaultValue="0"
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="MGT Complete Point"
                    name="blue_tick_point"
                    id="outlined-required"
                    onChange={onChangeFunction}
                    value={getdata.blue_tick_point}
                    defaultValue="0"
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <Button
            onClick={handleSubmit}
            style={{ marginTop: "15px" }}
            variant="contained"
          >
            Update
          </Button>
        </Card>
      </Container>
    </Page>
  );
}
