import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import Page from "../components/Page";
import Select from "react-select";
import {
  fetchallcitylist,
  fetchBusinessList,
  fetchBusinessListByMember,
  fetchchapterbyuser,
  fetchChaptList,
} from "src/utils/common";
import {
  GetMemberByChptId,
  UpdateBamount,
  deleteBusiness,
} from "src/api/commonapi";
import { EditSharp, DeleteSharp, Filter } from "@mui/icons-material";
import { async } from "q";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import moment from "moment";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";
import Label from "src/components/Label";

const BusinessReport = () => {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const [businessdata, setbusinessdata] = useState([]);
  const [nbusinessdata, setnbusinessdata] = useState([]);
  const [chptdata, setchptdata] = useState([]);
  const [chapoption, setchptoption] = useState([]);
  const [memberdata, setmemberdata] = useState([]);
  const [filter, setFilter] = useState({
    city_id:
      loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 &&
        loginUser?.p_user_id != 1 &&
        loginUser?.management_p == 1)
        ? loginUser?.city_id
        : 0,
    chpt_id: 0,
    selectType: 0,
    m_id: 0,
    crossType: 0,
    startDate: "",
    endDate: "",
  });
  const [filterTypeOptions, setFilterTypeOptions] = useState([
    { value: 0, label: "All" },
    { value: 1, label: "Business Receiver" },
    { value: 2, label: "Business Giver" },
  ]);
  const [inOutChapterOptions, setInOutChapterOptions] = useState([
    { value: 0, label: "All" },
    { value: 1, label: "In-Chapter" },
    { value: 2, label: "Cross-Chapter" },
  ]);
  const [filtereddata, setFilteredData] = useState({
    startDate: "",
    endDate: "",
  });

  const getchptlist = async () => {
    setchptdata(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  };

  const getbusinesslist = async (ch_id, m_id) => {
    let res = await fetchBusinessListByMember(ch_id, m_id);
    let newData = res.map((business) => {
      if (business.b_to_id == m_id) {
        return memberdata
          .filter((member) => member.chpt_id == filter.chpt_id)
          .map((member) => member.m_id)
          .includes(business.b_by_id)
          ? { ...business, inOutChapter: 1, outChapterLabel: "In-Chapter" }
          : { ...business, inOutChapter: 2, outChapterLabel: "Cross-Chapter" };
      } else if (business.b_by_id == m_id) {
        return memberdata
          .filter((member) => member.chpt_id == filter.chpt_id)
          .map((member) => member.m_id)
          .includes(business.b_to_id)
          ? { ...business, inOutChapter: 1, outChapterLabel: "In-Chapter" }
          : { ...business, inOutChapter: 2, outChapterLabel: "Cross-Chapter" };
      }
    });
    setbusinessdata(newData);
  };

  const filterData = () => {
    let nbusiness = [];
    nbusiness =
      businessdata &&
      businessdata.filter((business) => {
        if (
          (filter.selectType == 1 && business.b_by_id == filter.m_id) ||
          (filter.selectType == 2 && business.b_to_id == filter.m_id) ||
          filter.selectType == 0
        ) {
          if (filter.startDate != "" && filter.endDate != "") {
            if (
              moment(business.b_date, "DD-MM-YYYY").isBetween(
                filter.startDate,
                filter.endDate,
                "days",
                "[]"
              )
            ) {
              let res = handleCrossFilter(business);
              return res;
            }
          } else {
            let res = handleCrossFilter(business);
            return res;
          }
        }
      });
    setnbusinessdata(nbusiness);
  };

  const handleCrossFilter = (business) => {
    if (filter.crossType == 1 || filter.crossType == 2) {
      if (business.inOutChapter == filter.crossType) {
        return business;
      }
    } else {
      return business;
    }
  };
  useEffect(async () => {
    await filterData();
  }, [businessdata, filter]);

  useEffect(
    (async) => {
      chptdata?.map((option) => {
        chapoption.push({ value: option.chpt_id, label: option.chpt_name });
      });
    },
    [chptdata]
  );

  const getmemberbychp = async (ch_id) => {
    setmemberdata(await GetMemberByChptId(ch_id));
  };

  useEffect(async () => {
    getchptlist();
  }, []);

  // b_to_id >> Business Giver Id
  // b_to_name >> Business Giver Name
  // b_by_id >> Business Receiver Id
  // b_by_name >> Business Receiver Name
  const expo_columns = [
    { label: "No", key: "b_id" },
    { label: "Business Receiver", key: "b_by_name" },
    { label: "Business Giver", key: "b_to_name" },
    { label: "Date", key: "b_date" },
    { label: "Amount", key: "b_amount" },
    { label: "In/Out Chapter", key: "outChapterLabel" },
  ];

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row, key) => row.row_no,
      minWidth: "70px",
      maxWidth: "100px",
      sortable: true,
    },
    {
      name: "Business Receiver",
      wrap: true,
      selector: (row) => row.b_by_name,
      sortable: true,
    },
    {
      name: "Business Giver",
      wrap: true,
      selector: (row) => row.b_to_name,
      sortable: true,
    },
    {
      name: "Date",
      wrap: true,
      selector: (row) => row.b_date,
      sortable: true,
    },
    {
      name: "Amount ",
      wrap: true,
      selector: (row) => row.b_amount,
      sortable: true,
    },
    {
      name: "In/Out Chapter",
      selector: (row) => (
        <div>
          <Label
            variant={"ghost"}
            color={row.inOutChapter == 1 ? "success" : "info"}
          >
            {row.outChapterLabel}
          </Label>
        </div>
      ),
    },
  ];

  const [citylist, setcitylist] = useState([]);

  const [chaptlist, setchaplist] = useState([]);

  const getallcitylist = async () => {
    setcitylist(await fetchallcitylist());
    setchaplist(
      await fetchchapterbyuser(loginUser?.city_id, loginUser?.u_type)
    );
  };
  useEffect(async () => {
    getallcitylist();
  }, []);

  return (
    <>
      <Page title="Business Report | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="h4" gutterBottom>
              Business Report
            </Typography>
          </Stack>
          <Card className="p-20">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>Select City</label>
                  <Select
                    menuPortalTarget={document.body}
                    isDisabled={
                      loginUser?.u_type == 1 ||
                      (loginUser?.u_type == 2 &&
                        loginUser?.p_user_id != 1 &&
                        loginUser?.management_p == 1)
                        ? true
                        : false
                    }
                    options={[
                      {
                        value: 0,
                        label: "All City",
                      },
                      ...citylist.map((city) => {
                        return {
                          label: city?.city_name,
                          value: city?.city_id,
                        };
                      }),
                    ]}
                    onChange={(e) => {
                      setFilter({ ...filter, city_id: e.value, chpt_id: 0 });
                    }}
                    value={
                      filter.city_id == 0
                        ? {
                            value: 0,
                            label: "All City",
                          }
                        : citylist.map((city) => {
                            return (
                              filter.city_id == city.city_id && {
                                value: city.city_id,
                                label: city.city_name,
                              }
                            );
                          })
                    }
                  />
                </Stack>
              </Grid>

              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>Select Chapter</label>
                  <Select
                    name="chpt_id"
                    menuPortalTarget={document.body}
                    onChange={(e) => {
                      setFilter({ ...filter, chpt_id: e.value });
                      getmemberbychp(e.value);
                    }}
                    options={[
                      ...(chaptlist &&
                        chaptlist
                          ?.map((chpt) => {
                            if (filter?.city_id != 0) {
                              if (filter?.city_id == chpt?.city_id) {
                                return {
                                  value: chpt.chpt_id,
                                  label: chpt.chpt_name,
                                };
                              }
                            } else {
                              return {
                                value: chpt.chpt_id,
                                label: chpt.chpt_name,
                              };
                            }
                          })
                          .filter((f) => f != undefined)),
                    ]}
                    value={
                      chaptlist &&
                      chaptlist.map((c) => {
                        if (c.chpt_id == filter.chpt_id) {
                          return {
                            value: c.chpt_id,
                            label: c.chpt_name,
                          };
                        }
                      })
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>Select Member</label>
                  <Select
                    menuPortalTarget={document.body}
                    value={memberdata.map((member) => {
                      if (member.m_id == filter.m_id) {
                        return { value: member.m_id, label: member.m_name };
                      }
                    })}
                    onChange={async (e) => {
                      setbusinessdata([]);
                      setFilter({ ...filter, m_id: e.value });
                      getbusinesslist(filter.chpt_id, e.value);
                      await filterData();
                    }}
                    options={
                      memberdata &&
                      memberdata.map((option) => {
                        return { value: option.m_id, label: option.m_name };
                      })
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>Select Type</label>
                  <Select
                    menuPortalTarget={document.body}
                    value={filterTypeOptions.map((c) => {
                      if (c.value == filter.selectType) {
                        return { value: c.value, label: c.label };
                      }
                    })}
                    onChange={async (e) => {
                      await setFilter({ ...filter, selectType: e.value });
                      // filterByType(e.value);
                    }}
                    options={filterTypeOptions}
                  />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>In / Cross Chapter</label>
                  <Select
                    menuPortalTarget={document.body}
                    value={inOutChapterOptions.map((c) => {
                      if (c.value == filter.crossType) {
                        return { value: c.value, label: c.label };
                      }
                    })}
                    onChange={async (e) => {
                      setFilter({ ...filter, crossType: e.value });
                    }}
                    options={inOutChapterOptions}
                  />
                </Stack>
              </Grid>

              <Grid item xs={3}>
                <Stack spacing={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      type="date"
                      name="startdate"
                      label="Start Date "
                      value={filter?.startDate != "" ? filter?.startDate : null}
                      inputFormat="dd/MM/yyyy"
                      onChange={(newDate) => {
                        setFilter({
                          ...filter,
                          startDate: newDate,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      type="date"
                      name="enddate"
                      label="End Date"
                      value={filter?.endDate != "" ? filter?.endDate : null}
                      inputFormat="dd/MM/yyyy"
                      onChange={(newDate) => {
                        setFilter({
                          ...filter,
                          endDate: newDate,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>

              <Grid
                item
                xs={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <Button
                    variant="contained"
                    onClick={async () => {
                      await filterData();
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    onClick={async () => {
                      setFilter({
                        chpt_id: 0,
                        m_id: 0,
                        startDate: "",
                        endDate: "",
                        selectType: 0,
                        crossType: 0,
                      });

                      setbusinessdata([]);
                      setnbusinessdata([]);
                    }}
                    style={{ marginLeft: "5px" }}
                  >
                    Clear
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>
          <Card className="p-20" sx={{ mt: 2 }}>
            {nbusinessdata && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={nbusinessdata}
                    headers={expo_columns}
                    filename={"BusinessData.csv"}
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  style={{ overflow: "hidden" }}
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={nbusinessdata}
                />
              </>
            )}
          </Card>
          {/* End Category Form */}
        </Container>
      </Page>
    </>
  );
};

export default BusinessReport;
