import React, { useState, useEffect } from "react";
// material
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { insertpinBadge, updatePinFunction } from "src/api/commonapi";
import Page from "src/components/Page";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import {
  fetchallcitylist,
  fetchchapterbyuser,
  getSuggestionListByCity,
} from "src/utils/common";
import { CSVLink } from "react-csv";
import Select from "react-select";

export function Suggestion() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));

  const [suggestion, setSuggestion] = useState([]);
  const [newSuggestionList, setNewSuggestionList] = useState([]);

  const [searchData, setSearchData] = useState({
    // if admin is login the search Type is 0 and if city Amb is login then their city id is search Type
    city_id:
      loginUser?.u_type == 0 ||
      (loginUser?.u_type == 2 &&
        loginUser?.p_user_id == 1 &&
        loginUser?.management_p != 0)
        ? 0
        : loginUser?.city_id, // In City Data Get,
    chpt_id: 0,
    member: "",
    // startDate: "",
    // endDate: "",
  });

  const [chaptlist, setchaplist] = useState([]);
  useEffect(async () => {
    setchaplist(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  }, []);

  const expo_columns = [
    // { label: "No", key: "b_id" },
    { label: "Chapter Name", key: "chpt_name" },
    { label: "Suggestion Given By", key: "m_name" },
    { label: "Suggestion", key: "suggestion" },
    { label: "Date", key: "date" },
  ];
  const columns = [
    {
      name: "Chapter Name",
      wrap: true,
      selector: (row) => row.chpt_name,
      width: "200px",
      sortable: true,
    },
    {
      name: "Suggestion Given By",
      wrap: true,
      selector: (row) => (
        <div
          style={{
            cursor: "pointer",
            overflow: "visible",
            whiteSpace: "pre-line",
          }}
        >
          {row.m_name}
        </div>
      ),
      width: "auto",
      sortable: true,
    },
    {
      name: "Suggestion",
      wrap: true,
      width: "auto",
      selector: (row) => (
        <div
          style={{
            cursor: "pointer",
            overflow: "visible",
            lineHeight: "1rem",
            whiteSpace: "pre-line",
          }}
        >
          {row.suggestion}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Date",
      wrap: true,
      width: "150px",
      selector: (row) => row.date,
      sortable: true,
    },
  ];

  useEffect(() => {
    suggestionList();
  }, []);

  useEffect(() => {
    filterSuggestions();
  }, [suggestion, searchData]);

  const suggestionList = async () => {
    let resp = await getSuggestionListByCity(searchData);
    setSuggestion(resp);
  };

  const handleChapterSelect = async (selectedChapter) => {
    setSearchData({ ...searchData, chpt_id: selectedChapter.value });
  };

  const setNewSearchData = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
  };

  const filterSuggestions = () => {
    let nSuggestion = [];

    nSuggestion =
      suggestion &&
      suggestion.filter((sug) => {
        if (
          (sug.city_id == searchData.city_id || searchData.city_id == 0) &&
          (sug.c_id == searchData.chpt_id || searchData.chpt_id == 0)
        ) {
          return sug;
        }
      });

    setNewSuggestionList(nSuggestion);
  };

  const [citylist, setcitylist] = useState([]);

  const getallcitylist = async () => {
    setcitylist(await fetchallcitylist());
  };
  useEffect(async () => {
    getallcitylist();
  }, []);
  return (
    <>
      <Page title="LVB Suggestion | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="h4" gutterBottom>
              LVB Suggestion
            </Typography>
          </Stack>

          <Card
            style={{
              padding: "25px",
            }}
          >
            <Grid container spacing={2} style={{ marginBottom: "20px" }}>
              <Grid item xs={6}>
                <Stack sx={{ marginBottom: "15px" }}>
                  <label>Select City</label>
                  <Select
                    menuPortalTarget={document.body}
                    isDisabled={
                      loginUser?.u_type == 1 ||
                      (loginUser?.u_type == 2 &&
                        loginUser?.p_user_id != 1 &&
                        loginUser?.management_p == 1)
                        ? true
                        : false
                    }
                    options={[
                      {
                        value: 0,
                        label: "All City",
                      },
                      ...citylist.map((city) => {
                        return {
                          label: city?.city_name,
                          value: city?.city_id,
                        };
                      }),
                    ]}
                    onChange={(e) => {
                      setSearchData({
                        ...searchData,
                        city_id: e.value,
                        chpt_id: 0,
                      });
                    }}
                    value={
                      searchData.city_id == 0
                        ? {
                            value: 0,
                            label: "All City",
                          }
                        : citylist.map((city) => {
                            return (
                              searchData.city_id == city.city_id && {
                                value: city.city_id,
                                label: city.city_name,
                              }
                            );
                          })
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <label>Select Chapter</label>
                  <Select
                    name="chpt_id"
                    menuPortalTarget={document.body}
                    onChange={(e) => {
                      setSearchData({
                        ...searchData,
                        chpt_id: e.value,
                      });
                    }}
                    options={[
                      {
                        value: 0,
                        label: "All Chapter",
                      },
                      ...(chaptlist &&
                        chaptlist
                          ?.map((chpt) => {
                            if (searchData?.city_id != 0) {
                              if (chpt?.city_id == searchData?.city_id) {
                                return {
                                  value: chpt.chpt_id,
                                  label: chpt.chpt_name,
                                };
                              }
                            } else {
                              return {
                                value: chpt.chpt_id,
                                label: chpt.chpt_name,
                              };
                            }
                          })
                          .filter((f) => f != undefined)),
                    ]}
                    value={
                      searchData.chpt_id == 0
                        ? { value: 0, label: "All Chapter" }
                        : chaptlist &&
                          chaptlist.map((c) => {
                            if (c.chpt_id == searchData.chpt_id) {
                              return {
                                value: c.chpt_id,
                                label: c.chpt_name,
                              };
                            }
                          })
                    }
                  />
                </Stack>
              </Grid>
            </Grid>

            <>
              <Button className="btn btn-export">
                <CSVLink
                  data={newSuggestionList}
                  headers={expo_columns}
                  filename={"Suggestion.csv"}
                  className="btn btn-primary test"
                  target="_blank"
                >
                  Export CSV
                </CSVLink>
              </Button>
              <DataTable
                style={{ overflow: "hidden" }}
                filter
                pagination
                highlightOnHover
                columns={columns}
                data={newSuggestionList}
              />
            </>
          </Card>
        </Container>
      </Page>
    </>
  );
}
