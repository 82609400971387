export const Routes = {
  // API_HOSTNAME: "http://192.168.1.251:3000/",
  // http://128.199.16.59/
  API_HOSTNAME: "https://www.api.localvocalbusiness.com/",

  LEADFORMPUBLICURL: "http://panel.localvocalbusiness.com/",

  LOGIN: "admin-login",
  UPDATESETTING: "setsettings",
  GETESETTING: "getsettings",
  GETCHAPTER: "getchepterbyid",
  GETALLCHAPTER: "getchapter",
  UPDATECHAPTER: "update-chepter",
  INSERTMEMBER: "insert-member",
  INSERTSTATE: "insert-state",
  GETSTATE: "getall-state",
  GETCITY: "getcity",
  GETALLUSER: "getuser",
  INSERTCHAPTER: "insert-chapter",
  GETALLMEMBER: "getallmemberweb",
  // GETMEMBERBYID: "getmember",
  GETMEMBERBYID: "getmemberbyid",
  UPDATEMEMBER: "update-member",
  MEMBERIMAGEURL: "/images/member",
  GETPOLLSELECT: "getpolloption",
  INSERTPOLL: "insert-poll",
  INSERTEVENT: "insert-event",
  INSERTGALLERY: "insert-gallery",
  GETALLGALLERY: "getallgallery",
  DELETEGALLERY: "deletegallery",
  GETALLPOLL: "getallpoll",
  DELETEPOLL: "deletepoll",
  GETALLEVENTANNO: "getalleventannoucement",
  EVENTANNOUDELETE: "deleteeventannoucement",
  UPDATEMEMBERSTATUS: "update-member-status",
  USERLOGOUT: "logout",
  GETMEMBERBYCHPT: "getmemberbychptweb",
  UPDATEMEMBERPOST: "update-position",
  GETPOLLRESULT: "getpollresult",
  GETALLCITY: "getallcityweb",
  GETALLSTATE: "getall-state",
  GETALLCOUNTRY: "getcountry",
  INSERTCITY: "insert-city",
  INSERTCOUNTRY: "insert-country",
  GETMEETING: "getmeeting",
  INSERTLANDMARK: "insert-landmark",
  INSERTMEETING: "insert-meeting",
  GETALLLANDMARK: "getalllandmark",
  UPDATELANDMARKSTATUS: "update-landmarkstatus",
  UPDATESTATESTATUS: "update-statestatus",
  UPDATECITYSTATUS: "update-citystatus",
  GETMEETINGBYID: "getmeetingbyid",
  UPDATEMEETING: "update-meeting-web",
  // Get Business And Edit , Delete
  GETALLBUSINESS: "getbusinessbymember",
  UPDATEBAMOUNT: "update-bamount-web",
  DELETEBUSINESS: "deletebusiness",
  GETV2VLIST: "getv2vbymember",
  GETVISITORBYID: "getvisitorbymember",
  DELETEVISITOR: "deletevisitor",
  DELETEV2V: "deletev2v",
  GETREFERENCELIST: "getreferencebymember",
  DELETEREFERENCE: "deletereference",
  DELETECHAPTER: "deletechapter",
  RESETMEETINGMEMBER: "resetmeetingmember",
  GETTESTIMONIALLIST: "gettestimonialweb",
  DELETETESTIMONIAL: "deletetestimonial",
  // Get State
  GETSTATEBYID: "getstatebyid",
  UPDATESTATE: "updatestate",
  // UPdate City
  UPDATECITY: "updatecity",
  UPDATELANDMARK: "updatelandmarkweb",
  DELETEMEETING: "deletemeeting",
  GETATTENDANCE: "getattendance",
  UPDATEEVENTANNOUNCEMENT: "update-event",
  GETMEMBERATTENDANCEBYCHEPER: "memberattendancebychepter",
  GETMEMBERATTENDANCEBYID: "memberattendancebyid",
  INSERTGREETING: "insert-greeting",
  GETALLGREETING: "getallgreeting",
  GREETINGDELETE: "deletegreeting",
  RESENDGREETING: "resendgreeting",
  GETCHAPTERBYCITY: "getchapterbycityid",
  INSERTCITYAMBASSODAR: "insertcityambassador",
  GETCITYAMBASSADORBYID: "getcityambassadorbyid",
  GETACTIVEMEMBERBYCHPT: "getactivememberbychptweb",
  UPDATECITYAMBSSADORDEATILS: "updatecityambassodardeatils",
  INSERTSTAFF: "insertstaff",
  GETSTAFFBYPID: "getstaffbypid",
  UPDATESTAFFSTATUS: "updatestaffstatus",
  DELETESTAFF: "deletestaff",
  UPDATESTAFFDETAILS: "updatestaffdetails",
  GETALLOCATELISTBYID: "getallocatelistbyid",
  INSERTLEAD: "insertlead",
  INSERTTASK: "inserttask",
  GETTASKLIST: "gettaskbyid",
  GETLEADLIST: "getleadbyid",
  UPDATETASKSTATUS: "updatetaskstatus",
  UPDATELEADSTATUS: "updateleadstatus",
  GETTASKBYTID: "gettaskbytid",
  UPDATETASKDETAILS: "updatetaskdetails",
  DELETETASK: "deletetask",
  UPDATETASKPRIORITY: "updatetaskpriority",
  GETLEADBYLID: "getleadbylid",
  UPDATELEADDETAILS: "updateleaddetails",
  INSERTFOLLOWUP: "insertfollowup",
  GETLEADHISTORY: "getleadhistory",
  LEADTRANSFERTO: "transferlead",
  UPLOADPAYMENTIMAGE: "uploadpaymentimage",
  GETCHAPTERBYUSER: "getchapterbyuser",
  GETMEETINGBYUSER: "getmeetingbyuser",
  INSERTNEWCITYAMBASSADOR: "insertnewcityambassador",
  GETALLCITYAMABASSADOR: "getallcityambassador",
  DELETECITYAMBASSADOR: "deletecityambassador",
  PAYMENTWITHDRAWAL: "paymentwithdrawal",
  GETRENEWALMEMBERDATA: "getrenewalmemberdata",
  MEMBERRENEWAL: "memberrenewal",
  CHANGECITYAMBASSADOR: "changecityambassador",
  GETDASHBOARDCOUNTER: "getdashboardcounter",
  GETWALLETHISTORYBYID: "getwallethistorybyid",
  GETMEMBERFEEHISTORY: "getmemberfeehistory",
  UPLOADLVBDOCUMENT: "uploadlvbdocument",
  GETLVBDOCUMENT: "getlvbdocument",
  DELETEDOCUEMENT: "deletedocuement",
  UPDATECITYAMBASSADORPROFILE: "updatecityambassadorprofile",
  INSERTFOLDER: "insertfolder",
  GETFOLDERBYCITYID: "getfolderbycityid",
  GETDOCUMENTBYFID: "getdocumentbyfid",
  DELETESUBFOLDER: "deletesubfolder",
  V2VREPORTBYMEMBER: "getv2vmemberreport",
  BUSINESSREPORTBYMEMBER: "getbusinessbymemberreport",
  GETREFERENCEREPORTBYMEMBER: "getreferencebymemberreport",
  GETVISITORREPORTBYMEMBER: "getvisitorbymemberreport",
  GETATTENDANCEREPORT: "getattendancebymemberreport",
  GETLVBCOUNTERWEB: "getlvbcounterweb",
  GETLVBSEARCHCOUNTER: "getlvbsearchcounter",
  GETCITYSEARCHCOUNTER: "getcitysearchcounter",
  GETCITYCHAPTERSEARCHCOUNTER: "getcitychaptersearchcounter",
  GETCITYAMBDETAILBYID: "getcityambdetailbyid",
  FINDTOPPER: "find-topper",
  CHAPTERSMEMBERDATA: "chapters-member-data",
  INSERTPINBADGE: "insert-pin-badge",
  GETPINLIST: "get-pin-list",
  UPDATEPIN: "update-pin",
  ASSIGNPIN: "assign-pin",
  GETPINBYMEMBERID: "get-pin-by-member-id",
  UPDATEMEMBERVERIFYSTATUS: "update-member-verify-status", //
  GETMEMBERSDETAILREPORT: "get-members-detail-report",
  GETMEMBERSPECIFICDETAILREPORT: "get-member-specific-detail-report",
  INSERTBLACKLISTVISITOR: "add-visitor-blocklist",
  GETBLOCKVISITORLIST: "get-block-visitor-list",
  GETLEADDETAILBYID: "get-leaddetails-by-id",
  UPDATELVBLEAD: "update-lvb-lead",
  GETSUGGESTIONLIST: "get-suggestion-list",
  UPDATEPUBLICLEADFORMEDITMODE: "update-public-lead-form-edit-mode",
  GETMEMBERINDUCTIONLIST: "get-member-induction-list",
  INSERTPUBLICLEADFORM: "insert-public-lead",
  EXPORTMEMBERALLSPECIFICDATA: "export-member-all-specific-data",
  THANKYOUNOTEWAITINGLIST: "thankyounote-waiting-list",
  APPROVETHANKYOUNOTE: "approve-thankyounote",
  DELETEUNAPPROVEDTHANKYOUNOTE: "delete-waiting-thankyounote",
  GETNEWLEADLIST: "getleadbyid-v2",
  GETNEWLEADFOREXPORT: "get-new-lead-for-export",
  PINASSIGNREPORT: "pin-assign-report",
  EXPORTCITYCHAPTERREPORT: "export-city-chapter-report",
  DELETEVISITORBLOCKLIST: "delete-visitor-blocklist",
  GETVISITORPREVIOUSELIST: "get-visitor-previous-list",
  CHECKMOBILEVALIDATION: "check-mobile-validation",
  CHAPTERREACTIVE: "chapter-reactive",
  CHAPTERLOGHISTORY: "chapter-log-history",
  UPDATEPASSWORD: "update-password",
};
