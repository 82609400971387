import { filter } from "lodash";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import { Card, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import {
  fetchBusinessList,
  fetchV2vList,
  fetchChaptList,
  fetchchapterbyuser,
  fetchV2vListByMember,
} from "src/utils/common";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
// components
import Page from "../components/Page";
import { deleteV2v, GetMemberByChptId } from "src/api/commonapi";
import {
  DeleteForever,
  DeleteOutline,
  DeleteSharp,
  EditSharp,
} from "@mui/icons-material";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import moment from "moment";
import { setDefaultOptions } from "date-fns";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";

export default function ChapterV2vReport() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });

  const [joindate, setjoinDate] = useState(null);
  const [chptdata, setchptdata] = useState([]);
  const [amount, setamount] = useState({});
  const [v2vdata, setv2vdata] = useState([]);
  const [nv2vdata, setnv2vdata] = useState([]);
  const [chapoption, setchptoption] = useState([]);
  const [memberdata, setmemberdata] = useState([]);
  const [nmemberdata, setnmemberdata] = useState([]);
  const [getdata, setgetdata] = useState({});
  const [step, setstep] = useState(0);
  const [filter, setFilter] = useState({
    chpt_id: 0,
  });

  const [filtereddata, setFilteredData] = useState({
    startDate: "",
    endDate: "",
  });
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");

  const [selectedmember, setselectedmember] = useState({
    defaulselect: "",
    m_id: 0,
  });

  const filterData = (satrt, end) => {
    let nv2v = [];

    nv2v =
      v2vdata &&
      v2vdata.filter((v2v) => {
        if (
          moment(v2v.v2v_date, "DD-MM-YYYY").isBetween(
            satrt,
            end,
            "days",
            "[]"
          ) ||
          (satrt == "" && end == "")
        ) {
          return v2v;
        }
      });

    setnv2vdata(nv2v);
  };

  useEffect(async () => {
    if (
      filtereddata.startDate != undefined ||
      filtereddata.endDate != undefined
    ) {
      await filterData("", "");
    }
  }, [v2vdata]);

  const getchptlist = async () => {
    setchptdata(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  };

  useEffect(
    (async) => {
      chptdata?.map((option) => {
        chapoption.push({ value: option.chpt_id, label: option.chpt_name });
      });
    },
    [chptdata]
  );

  const getmemberbychp = async (ch_id) => {
    setselectedmember({ defaulselect: "", m_id: 0 });
    setmemberdata(await GetMemberByChptId(ch_id));
  };
  const getv2vlist = async (ch_id, m_id) => {
    setv2vdata(await fetchV2vListByMember(ch_id, m_id));
  };
  const deletev2v = async (v_id) => {
    const resp = await deleteV2v(v_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      getv2vlist(filter.chpt_id, selectedmember.m_id);
      setDvisible({ mval: false, id: 0 });
    }
  };
  useEffect(async () => {
    getchptlist();
  }, []);
  const expo_columns = [
    { label: "No", key: "row_no" },
    { label: "V2V By", key: "v_by_name" },
    { label: "V2V To", key: "v_to_name" },
    { label: "Date", key: "v2v_date" },
    { label: "Comment", key: "comment" },
  ];

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row, key) => row.row_no,
      width: "100px",
      sortable: true,
    },
    {
      name: "V2V By",
      wrap: true,
      selector: (row) => row.v_by_name,
      sortable: true,
    },
    {
      name: "V2V To",
      wrap: true,
      selector: (row) => row.v_to_name,
      sortable: true,
    },
    {
      name: "Date",
      wrap: true,
      selector: (row) => row.v2v_date,
      sortable: true,
    },
    {
      name: "Comment ",
      wrap: true,
      selector: (row) => row.comment,
      sortable: true,
    },
  ];

  return (
    <>
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={deletev2v}
      />
      <Page title="Chapter V2V Report | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="h4" gutterBottom>
              Chapter V2V Report
            </Typography>
          </Stack>

          <Stack spacing={3} style={{ marginBottom: "15px" }}>
            <ChapterSelectDropDown
              dropDownLabel={"Select Chapter"}
              selectedState={filter}
              chapterKey="chpt_id"
              handleChange={(e) => {
                setFilter({ chpt_id: e.value });
                getmemberbychp(e.value);
              }}
            />
          </Stack>
          <Stack spacing={3} style={{ marginBottom: "15px" }}>
            <label style={{ marginBottom: "10px" }}>Select Member</label>
            <Select
              value={selectedmember.defaulselect}
              onChange={(e) => {
                setselectedmember({ defaulselect: e, m_id: e.value });
                getv2vlist(filter.chpt_id, e.value);
              }}
              options={
                memberdata &&
                memberdata.map((option) => {
                  return { value: option.m_id, label: option.m_name };
                })
              }
            />
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Stack
                spacing={3}
                style={{ marginBottom: "15px", marginTop: "5px" }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  style={{ marginTop: "15px" }}
                >
                  <DatePicker
                    type="date"
                    name="startdate"
                    label="Start Date "
                    value={filtereddata?.startDate}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newDate) => {
                      setFilteredData({
                        ...filtereddata,
                        startDate: newDate,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack
                spacing={3}
                style={{ marginBottom: "15px", marginTop: "5px" }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  style={{ marginTop: "15px" }}
                >
                  <DatePicker
                    type="date"
                    name="enddate"
                    label="End Date "
                    value={filtereddata?.endDate}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newDate) => {
                      setFilteredData({
                        ...filtereddata,
                        endDate: newDate,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div>
                <Button
                  variant="contained"
                  onClick={() => {
                    filterData(filtereddata?.startDate, filtereddata?.endDate);
                  }}
                >
                  Search
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setFilteredData({ startDate: null, endDate: null });
                    filterData("", "");
                  }}
                  style={{ marginLeft: "5px" }}
                >
                  Clear
                </Button>
              </div>
            </Grid>
          </Grid>

          <Card
            style={{
              paddingTop: "25px",
              paddingBottom: "25px",
              marginBottom: "25px",
            }}
          >
            {nv2vdata && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={nv2vdata}
                    headers={expo_columns}
                    filename={"V2Vdata.csv"}
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  style={{ overflow: "hidden" }}
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={nv2vdata}
                />
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
