import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import DatePicker from "@mui/lab/DatePicker";
import moment from "moment";
import Select from "react-select";

// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Button,
  TextField,
  Grid,
  MenuItem,
  FormLabel,
  CardFormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
// components
import { EventForm } from "../components/Form/Event";
import { PollSelect } from "src/utils/common";
import "react-toastify/dist/ReactToastify.css";
import { notification } from "src/utils/messages";
import {
  GreetingDelete,
  insertgreeting,
  ResendGreeting,
} from "src/api/commonapi";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Page from "../components/Page";
import { Routes } from "../constant/api_url";
import { fetchGreetingList } from "src/utils/common";
import { DeleteSharp, NotificationsActive, Search } from "@mui/icons-material";
import { EventAnnouDelete, updateEventAnnouncement } from "src/api/commonapi";

import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
export default function Greeting() {
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });
  let loginUser = JSON.parse(localStorage.getItem("userData"));

  const [citylist, setcitylist] = useState(null);
  const [eventlist, seteventlist] = useState(null);
  const [gettabledata, settabledata] = useState([]);
  const [newGetTableData, setNewTableData] = useState([]);
  const [response, setresponse] = useState(0);
  const [step, setStep] = useState(0);
  //strat
  const notify = () => toast("Wow so easy!");
  const navigate = useNavigate();
  const [statelist, setstatelist] = useState(null);
  const [getdata, setgetdata] = useState({ g_type: 0, e_status: 0 });
  const [option, setOption] = useState(null);
  const [setfile, setFile] = useState({});
  const EventSchema = Yup.object().shape({
    eventname: Yup.string().required("Event Name is required"),
  });
  const getlandmarklist = async () => {
    settabledata(await fetchGreetingList());
  };
  const onChangeFunction = (e) => {
    if (e.target.name == "g_type") {
      setOption([]);
      setSelectData(e.target.value);
    }
    setgetdata({
      ...getdata,
      [e.target.name]: e.target.value,
    });
  };
  // const setSelectData = async (type = 0) => {
  //   let optiondata = [];
  //   if (type !== 0) {
  //     const resp = await PollSelect(type, loginUser?.u_type, loginUser?.city_id);
  //     console.log("AAAAAAAAAAAAAAAAA", resp);
  //     // setOption([]);
  //     const defaultOption = { value: 0, label: "Select" };
  //     // resp?.map((e) => optiondata.push({ value: e.value, label: e.name }));
  //     // resp?.map((e) => {
  //     //   setOption(...option, { value: e.value, label: e.name });
  //     // });
  //     resp?.map((e) => optiondata.push({ value: e.value, label: e.name }));

  //     console.log("optiondata", optiondata);
  //     // setOption([...optiondata]);
  //     // setOption([{ value: 0, label: "Select" }, ...optiondata]);
  //   }
  // };
  const setSelectData = async (type = 0) => {
    let optiondata = [];
    if (type != 0) {
      const resp = await PollSelect(
        type,
        loginUser?.u_type,
        loginUser?.city_id
      );
      resp?.map((e) => optiondata.push({ value: e.value, label: e.name }));
      setOption([{ value: 0, label: "Select" }, ...optiondata]);
    }
  };

  const handleSubmit = async () => {
    let fdata = new FormData();
    fdata.append("g_type", getdata?.g_type);
    fdata.append("g_type_id", getdata?.g_type == 0 ? 0 : getdata?.g_type_id);
    fdata.append("g_title", getdata?.g_title);
    fdata.append("g_description", getdata?.g_description);

    if (step == 1) {
      if (
        (getdata.g_type != 0 &&
          (getdata.g_type_id == 0 || getdata.g_type_id == undefined)) ||
        getdata.g_title == "" ||
        getdata.g_title == undefined ||
        getdata.g_description == "" ||
        getdata.g_description == undefined
      ) {
        let notify = notification({
          type: "error",
          message: "Please Fill Required Fields",
        });
        notify();
      } else {
        const resp = await insertgreeting(fdata);
        let notify = notification({
          type: resp.status == 0 ? "error" : "success",
          message: resp.message,
        });
        notify();
        setgetdata({ g_type: 0, e_status: 0 });
        setStep(0);
        getlandmarklist();
      }
    } else {
      fdata.append("e_id", getdata?.e_id);
      const resp = await updateEventAnnouncement(fdata);

      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      setgetdata({ g_type: 0, e_status: 0 });
      setStep(0);
      getlandmarklist();
    }
  };
  //end

  useEffect(async () => {
    settabledata(await fetchGreetingList());
  }, [response]);

  const landmarkresponse = () => {
    geteventlist();
  };

  const geteventlist = async () => {
    settabledata(await fetchGreetingList());
  };

  const eventannoudelete = async (g_id) => {
    const resp = await GreetingDelete(g_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();

    if (resp.status == 1) {
      setresponse(response + 1);
      setDvisible({ mval: false, id: 0 });
    }
  };

  const resendgreeting = async (g_id) => {
    const resp = await ResendGreeting(g_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();

    if (resp.status == 1) {
      setresponse(response + 1);
    }
  };

  const expo_columns = [
    { label: "No", key: "g_id" },
    { label: "Title", key: "g_title" },
    { label: "Description", key: "g_description" },
  ];

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row.g_id,
      sortable: true,
    },

    {
      name: "Title",
      wrap: true,
      selector: (row) => row.g_title,
      sortable: true,
    },
    {
      name: "Description",
      wrap: true,
      selector: (row) => row.g_description,
      sortable: true,
    },
    {
      name: "Greeting Type",
      wrap: true,
      selector: (row) =>
        row.g_type == 1
          ? "State"
          : row.g_type == 2
          ? "City"
          : row.g_type == 3
          ? "Chapter"
          : "LVB",
    },
    {
      name: "Location",
      wrap: true,
      selector: (row) =>
        row.g_type == 1
          ? row.s_name
          : row.g_type == 2
          ? row.city_name
          : row.g_type == 3
          ? row.ch_name
          : "",
    },
    {
      name: "Action",
      wrap: true,
      cell: (row) => (
        <>
          <a
            onClick={() => {
              setDvisible({ mval: true, id: row.g_id });
            }}
          >
            <Tooltip title="Delete">
              <IconButton>
                <DeleteSharp color="error" />
              </IconButton>
            </Tooltip>
          </a>
          <a onClick={() => resendgreeting(row.g_id)}>
            <Tooltip title="Send">
              <IconButton>
                <NotificationsActive color="warning" />
              </IconButton>
            </Tooltip>
          </a>
        </>
      ),
    },
  ];

  const EventType = [
    { label: "All", value: -1 },
    { label: "LVB ", value: 0 },
    { label: "State", value: 1 },
    { label: "City", value: 2 },
    { label: "Chapter", value: 3 },
  ];
  const [filterData, setFilterData] = useState({
    greetingType: -1,
    search: "",
    startDate: "",
    endDate: "",
  });

  const handleFilterData = () => {
    const newGreetingData = gettabledata?.filter(
      (greeting) => filterByType(greeting) && filterBySearch(greeting)
    );

    setNewTableData(newGreetingData);
  };

  const filterByType = (greeting) => {
    return (
      filterData.greetingType == -1 ||
      filterData.greetingType == greeting.g_type
    );
  };

  const filterBySearch = (greeting) => {
    let searchFilterText = filterData.search.toLowerCase();

    return (
      filterData.search == "" ||
      greeting?.ch_name?.toLowerCase()?.includes(searchFilterText) ||
      greeting?.city_name?.toLowerCase()?.includes(searchFilterText) ||
      greeting?.s_name?.toLowerCase()?.includes(searchFilterText) ||
      greeting?.g_title?.toLowerCase()?.includes(searchFilterText) ||
      greeting?.g_description?.toLowerCase()?.includes(searchFilterText)
    );
  };

  useEffect(() => {
    handleFilterData();
  }, [gettabledata, filterData]);
  return (
    <>
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={eventannoudelete}
      />

      <Page title="Greeting | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="h4" gutterBottom>
              Greeting
            </Typography>
          </Stack>
          {/* Start Category Form */}
          {step != 0 ? (
            <>
              <Button
                onClick={() => {
                  setStep(0);
                  setgetdata({ g_type: 0, e_status: 0 });
                }}
                style={{ marginBottom: "15px" }}
                variant="contained"
              >
                Back
              </Button>
              <Card
                style={{
                  padding: "25px",
                  paddingBottom: "25px",
                  marginBottom: "25px",
                }}
              >
                <FormLabel id="demo-radio-buttons-group-label">
                  Display
                </FormLabel>
                <Grid item xs={12}>
                  <Stack spacing={3} style={{ marginBottom: "15px" }}>
                    <RadioGroup
                      style={{ marginTop: "0px" }}
                      row
                      defaultValue={0}
                      value={getdata.g_type}
                      onChange={(e) => {
                        onChangeFunction(e);
                      }}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="g_type"
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="LVB"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="State"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="City"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label="Chapter"
                      />
                    </RadioGroup>
                  </Stack>
                </Grid>
                {getdata.g_type != 0 ? (
                  <Grid item xs={12}>
                    <Stack spacing={10} style={{ marginBottom: "15px" }}>
                      <label style={{ marginBottom: "10px" }}>
                        Select
                        {getdata.g_type == 1
                          ? " State"
                          : getdata.g_type == 2
                          ? " City"
                          : getdata.g_type == 3
                          ? " Chapter"
                          : " LVB"}
                      </label>
                      <Select
                        style={{ marginTop: "0px" }}
                        required={true}
                        value={
                          option != null &&
                          option?.map((c) => {
                            if (c.value == getdata?.g_type_id) {
                              return { value: c.value, label: c.label };
                            }
                          })
                        }
                        onChange={(e) => {
                          setgetdata({ ...getdata, g_type_id: e.value });
                        }}
                        menuPortalTarget={document.body}
                        options={option}
                      />
                    </Stack>
                  </Grid>
                ) : (
                  ""
                )}

                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    value={getdata?.g_title ? getdata?.g_title : ""}
                    type="text"
                    label="Name"
                    name="g_title"
                    onChange={onChangeFunction}
                  />
                </Stack>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    type="text"
                    value={getdata?.g_description ? getdata?.g_description : ""}
                    label="Description"
                    name="g_description"
                    onChange={onChangeFunction}
                  />
                </Stack>
                <Button
                  style={{ marginTop: "15px" }}
                  onClick={handleSubmit}
                  variant="contained"
                >
                  submit
                </Button>
              </Card>
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  setStep(1);
                }}
                style={{ marginBottom: "15px" }}
                variant="contained"
              >
                Add New Greeting
              </Button>
              <Card className="p-20">
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item xs={4}>
                    <Stack sx={{ my: "5px" }}>
                      <label>Select Type</label>
                      <Select
                        menuPortalTarget={document.body}
                        value={EventType.map((c) => {
                          if (c.value == filterData.greetingType) {
                            return { value: c.value, label: c.label };
                          }
                        })}
                        onChange={(e) => {
                          setFilterData({
                            ...filterData,
                            greetingType: e.value,
                          });
                        }}
                        options={EventType}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={7}>
                    <Stack spacing={10}>
                      <TextField
                        style={{ marginTop: "0px" }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Search"
                        name="name"
                        autoCorrect="off"
                        value={filterData.search}
                        onChange={(e) => {
                          setFilterData({
                            ...filterData,
                            search: e.target.value,
                          });
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      onClick={async () => {
                        setFilterData({
                          greetingType: -1,
                          search: "",
                          startDate: "",
                          endDate: "",
                        });
                      }}
                      variant="contained"
                    >
                      clear
                    </Button>
                  </Grid>
                </Grid>
              </Card>
              <Card className="p-20" sx={{ mt: 2 }}>
                {gettabledata && (
                  <>
                    <Button className="btn btn-export">
                      <CSVLink
                        data={gettabledata}
                        headers={expo_columns}
                        filename={"GreetingList.csv"}
                        className="btn btn-primary test"
                        target="_blank"
                      >
                        Export CSV
                      </CSVLink>
                    </Button>
                    <DataTable
                      filter
                      pagination
                      highlightOnHover
                      columns={columns}
                      data={newGetTableData != null && newGetTableData}
                    />
                  </>
                )}
              </Card>
            </>
          )}
          {/* End Category Form */}
        </Container>
      </Page>
    </>
  );
}
