import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";

// Kanban Board View
import Board from "react-trello";
import "../Assets/css/style.css";
import Label from "../components/Label";
import {
  Card,
  Stack,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Button,
  TextField,
  Grid,
  Checkbox,
  MenuItem,
  FormLabel,
  CardFormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Icon,
  Box,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  FormControl,
  FormGroup,
  Switch,
  CircularProgress,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Select from "react-select";
import Page from "../components/Page";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  featchleadhistory,
  fetchallocatelistbyid,
  fetchchapterbyuser,
  fetchCityList,
  fetchleadlistbyid,
  fetchNewLeadList,
  fetchrenewalmemberdata,
  fetchStateList,
  GetAllMember,
} from "src/utils/common";
import {
  getLeadHistory,
  getNewLeadDataForExport,
  getNewLeadList,
  insertLead,
  insertmember,
  leadTransferTO,
  memberRenewal,
  updateLeadMemberForm,
  updatePublicLeadFormEditMode,
  updateleadstatus,
  updatetaskstatus,
  uploadPaymentImage,
} from "src/api/commonapi";
import moment from "moment";
import { notification } from "src/utils/messages";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import {
  AutoGraphSharp,
  EditSharp,
  LeaderboardSharp,
  PersonAddAlt,
  PersonAddAltSharp,
  SendSharp,
  ThumbUpAlt,
  ExpandMoreIcon,
  ExpandMoreSharp,
  CancelRounded,
  CancelOutlined,
  CancelPresentation,
  Close,
  PhotoCamera,
  PhotoAlbumSharp,
  AddPhotoAlternateOutlined,
  AddPhotoAlternateRounded,
  Upload,
  CopyAll,
  CopyAllRounded,
  CopyAllOutlined,
  ContentPaste,
  ContentPasteGo,
  ContentPasteOff,
  ContentCopy,
  Person,
  Business,
  AddPhotoAlternate,
  ChangeCircle,
  Check,
} from "@mui/icons-material";
import { Routes } from "src/constant/api_url";
import {
  getLeadByLid,
  insertFollowUp,
  updateleaddetails,
} from "../api/commonapi";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";
export default function Lead() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const [chaptlist, setchaplist] = useState([]);
  const [step, setStep] = useState(0);
  const [formdata, setformdata] = useState({
    l_created_by: loginUser.u_id,
    l_type: 0,
  });
  const [memberdata, setnewmemberdata] = useState({
    chpt_id: 0,
    joining_date: moment().format("DD-MM-YYYY"),
    due_date: moment().add(1, "years").format("DD-MM-YYYY"),
    type: 0,
    invite_by: 0,
  });

  const [memberrenewaldata, setmemberrenewaldata] = useState({
    newdue_date: moment().add(1, "years").format("DD-MM-YYYY"),
  });

  const [myModal, setMyModal] = useState({
    mval: false,
    lead_id: 0,
    l_phase: 0,
  });
  const [allocatelist, setallocatelist] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [leadHistory, setLeadHistory] = useState([]);
  const [filterlead, setfilterlead] = useState({
    u_id: loginUser.u_id,
    u_type: loginUser.u_type,
    filterl: "",
    s_date: null,
    e_date: null,
    filteruser: 0,
    l_phase: -1,
    lead_status: -1,
    lead_type: -1,
    // user_allocated: 0,
  });
  const [filter, setFilter] = useState({
    defaulselect: { value: 0, label: "All Users" },
    user_id: 0,
    lead_status: -1,
    lead_type: 0,
  });
  const [filterOption, setFilterOption] = useState([
    { value: 0, label: "All Users" },
  ]);

  const [newleadData, setnewLeadData] = useState([]);
  const [followUpModal, setFollowUpModal] = useState(false);
  const [followUpData, setFollowUpData] = useState({ u_id: loginUser.u_id });
  const [leadtransModal, setLeadTransModal] = useState(false);
  const [paymentimgModal, setpaymentimgModal] = useState(false);
  const [leadtransData, setLeadTransData] = useState({ u_id: loginUser.u_id });
  const [payimgData, setpayimgData] = useState({});
  const [fileimage, setfileimage] = useState();
  const [srDate, setSrdate] = useState({ s_date: null, e_date: null });
  const [convertmemberModal, setConvertMemberModal] = useState(false);
  const [memberrenewalmodal, setmemberrenewalmodal] = useState(false);

  const [memberlist, setmemberlist] = useState([]);
  const [citylist, setcitylist] = useState(null);
  const [statelist, setstatelist] = useState(null);
  // Tab View
  const [value, setValue] = useState("1");

  const [expanded, setExpanded] = useState(0);

  const [leadStatusChangeModal, setLeadStatusChangeModal] = useState(false);
  const closeLeadStatusChangeModal = () => {
    setLeadStatusChangeModal(false);
    setLeadStatusChangeData({
      lead_id: 0, // Lead ID
      l_phase: 0, // Updated Lead Status
      u_id: loginUser?.u_id || 0, // User ID
      from_l_phase: 0, // Old Lead Status
    });
  };

  const [leadStatusChangeData, setLeadStatusChangeData] = useState({
    lead_id: 0, // Lead ID
    l_phase: 0, // Updated Lead Status
    u_id: loginUser?.u_id || 0, // User ID
    from_l_phase: 0, // Old Lead Status
  });

  const handleInsuranceCheckBoxEvent = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      // If checkbox is checked, add the value to the array
      const updatedInsuranceType = [...formdata.insuranceType, value];
      setformdata({
        ...formdata,
        insuranceType: updatedInsuranceType,
      });
    } else {
      // If checkbox is unchecked, remove the value from the array
      const updatedInsuranceType = formdata.insuranceType.filter(
        (item) => item !== value
      );
      setformdata({
        ...formdata,
        insuranceType: updatedInsuranceType,
      });
    }
  };

  const statusList = [
    { value: 0, label: "New" },
    { value: 1, label: "Phase 1" },
    // { value: 2, label: "Phase 2" },
    { value: 3, label: "Confirmed" },
    { value: 4, label: "Waiting For Payment" },
    { value: 5, label: "Payment Verified" },
    { value: 6, label: "Lead Complete" },
    { value: 7, label: "Rejected" },
  ];

  const SearchTypeOptions = [
    { value: -1, label: "All Lead" },
    { value: 1, label: "Visitor Lead" },
    { value: 2, label: "Member Renewal Lead" },
    { value: 0, label: "Other Lead" },
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
  };

  const followupstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    padding: "10px",
  };

  // const filterData = () => {
  //   let ncity = [];
  //   ncity =
  //     leadData &&
  //     leadData.filter((lead) => {
  //       if (
  //         lead.name.toLowerCase().includes(filterlead?.toLowerCase()) ||
  //         lead.l_contact_no.toLowerCase().includes(filterlead?.toLowerCase()) ||
  //         lead.createdBy.toLowerCase().includes(filterlead?.toLowerCase()) ||
  //         lead.allocatedTo.toLowerCase().includes(filterlead?.toLowerCase())
  //       ) {
  //         return lead;
  //       }
  //     });

  //   setnewLeadData(ncity);
  // };

  const filterDate = () => {
    let ndate = [];
    ndate =
      newleadData &&
      newleadData.filter((lead) => {
        if (lead.date >= srDate.s_date && lead.date <= srDate.e_date) {
          return lead;
        }
      });
    setnewLeadData(ndate);
  };

  const filterData = () => {
    let ncity = [];
    ncity =
      leadData &&
      leadData.filter((lead) => {
        if (
          (lead?.l_allocated_to == filterlead?.filteruser ||
            lead?.l_created_by == filterlead?.filteruser ||
            lead?.p_u_id == filterlead?.filteruser ||
            filterlead?.filteruser == 0) &&
          (lead?.name
            ?.toLowerCase()
            .includes(filterlead?.filterl?.toLowerCase()) ||
            lead?.l_contact_no
              ?.toLowerCase()
              .includes(filterlead?.filterl?.toLowerCase()) ||
            lead?.createdBy
              ?.toLowerCase()
              .includes(filterlead?.filterl?.toLowerCase()) ||
            lead?.allocatedTo
              ?.toLowerCase()
              .includes(filterlead?.filterl?.toLowerCase()))
        ) {
          return lead;
        }
      });

    if (filterlead.e_date != null && filterlead.s_date != null) {
      ncity =
        ncity &&
        ncity.filter((lead) => {
          if (
            moment(lead.date, "DD-MM-YYYY").isBetween(
              filterlead.s_date,
              filterlead.e_date,
              "days",
              "[]"
            )
          ) {
            return lead;
          }
        });
    }
    setnewLeadData(ncity);
  };

  // useEffect(async () => {
  //   if (filterlead.filterl != undefined || filterlead.filteruser != undefined) {
  //     await filterData();
  //   }
  // }, [filterlead, leadData]);

  useEffect(async () => {
    getAllPhaseLeads();
  }, []);

  const initNewLan = {
    allLeadList: [],
    allLeadIds: [],
    newLead: {
      counter: 0,
      leadList: [],
      id_array: [],
      is_no_more: false,
    },
    phase1Lead: {
      counter: 0,
      leadList: [],
      id_array: [],
      is_no_more: false,
    },
    confirmedLead: {
      counter: 0,
      leadList: [],
      id_array: [],
      is_no_more: false,
    },
    waitingForPaymentLead: {
      counter: 0,
      leadList: [],
      id_array: [],
      is_no_more: false,
    },
    paymentVerifiedLead: {
      counter: 0,
      leadList: [],
      id_array: [],
      is_no_more: false,
    },
    completeLead: {
      counter: 0,
      leadList: [],
      id_array: [],
      is_no_more: false,
    },
    rejectedLead: {
      counter: 0,
      leadList: [],
      id_array: [],
      is_no_more: false,
    },
  };
  const [newLan, setNewLan] = useState({
    allLeadList: [],
    allLeadIds: [],
    newLead: {
      counter: 0,
      leadList: [],
      id_array: [],
      is_no_more: false,
    },
    phase1Lead: {
      counter: 0,
      leadList: [],
      id_array: [],
      is_no_more: false,
    },
    confirmedLead: {
      counter: 0,
      leadList: [],
      id_array: [],
      is_no_more: false,
    },
    waitingForPaymentLead: {
      counter: 0,
      leadList: [],
      id_array: [],
      is_no_more: false,
    },
    paymentVerifiedLead: {
      counter: 0,
      leadList: [],
      id_array: [],
      is_no_more: false,
    },
    completeLead: {
      counter: 0,
      leadList: [],
      id_array: [],
      is_no_more: false,
    },
    rejectedLead: {
      counter: 0,
      leadList: [],
      id_array: [],
      is_no_more: false,
    },
  });
  const getAllPhaseLeads = async (newState = false) => {
    // let newLead = await getNewLeadList({
    //   ...filterlead,
    //   l_phase: 0,
    //   id_array: [],
    // });
    // let phase1Lead = await getNewLeadList({
    //   ...filterlead,
    //   l_phase: 1,
    //   id_array: [],
    // });
    // let confirmedLead = await getNewLeadList({
    //   ...filterlead,
    //   l_phase: 3,
    //   id_array: [],
    // });
    // let waitingForPaymentLead = await getNewLeadList({
    //   ...filterlead,
    //   l_phase: 4,
    //   id_array: [],
    // });

    // let paymentVerifiedLead = await getNewLeadList({
    //   ...filterlead,
    //   l_phase: 5,
    //   id_array: [],
    // });
    // let completeLead = await getNewLeadList({
    //   ...filterlead,
    //   l_phase: 6,
    //   id_array: [],
    // });
    // let rejectedLead = await getNewLeadList({
    //   ...filterlead,
    //   l_phase: 7,
    //   id_array: [],
    // });

    const [
      newLead,
      phase1Lead,
      confirmedLead,
      waitingForPaymentLead,
      paymentVerifiedLead,
      completeLead,
      rejectedLead,
    ] = await Promise.all([
      // New Lead
      getNewLeadList({
        ...(newState ? newState : filterlead),
        l_phase: 0,
        id_array: [],
      }),
      // Phase 1 Lead
      getNewLeadList({
        ...(newState ? newState : filterlead),
        l_phase: 1,
        id_array: [],
      }),
      // Confirm Lead
      getNewLeadList({
        ...(newState ? newState : filterlead),
        l_phase: 3,
        id_array: [],
      }),
      // Waiting For Payment
      getNewLeadList({
        ...(newState ? newState : filterlead),
        l_phase: 4,
        id_array: [],
      }),
      // paymentVerifiedLead
      getNewLeadList({
        ...(newState ? newState : filterlead),
        l_phase: 5,
        id_array: [],
      }),
      // completeLead
      getNewLeadList({
        ...(newState ? newState : filterlead),
        l_phase: 6,
        id_array: [],
      }),
      // rejectedLead
      getNewLeadList({
        ...(newState ? newState : filterlead),
        l_phase: 7,
        id_array: [],
      }),
    ]);

    if (newLead.status == 1) {
      setNewLan((prevState) =>
        updateAPIDataToState(prevState, newLead, "newLead")
      );
    } else {
      setNewLan((prevState) => prevState);
    }
    if (phase1Lead.status == 1) {
      setNewLan((prevState) =>
        updateAPIDataToState(prevState, phase1Lead, "phase1Lead")
      );
    } else {
      setNewLan((prevState) => prevState);
    }
    if (confirmedLead.status == 1) {
      setNewLan((prevState) =>
        updateAPIDataToState(prevState, confirmedLead, "confirmedLead")
      );
    } else {
      setNewLan((prevState) => prevState);
    }

    if (waitingForPaymentLead.status == 1) {
      setNewLan((prevState) =>
        updateAPIDataToState(
          prevState,
          waitingForPaymentLead,
          "waitingForPaymentLead"
        )
      );
    } else {
      setNewLan((prevState) => prevState);
    }

    if (paymentVerifiedLead.status == 1) {
      setNewLan((prevState) =>
        updateAPIDataToState(
          prevState,
          paymentVerifiedLead,
          "paymentVerifiedLead"
        )
      );
    } else {
      setNewLan((prevState) => prevState);
    }

    if (completeLead.status == 1) {
      setNewLan((prevState) =>
        updateAPIDataToState(prevState, completeLead, "completeLead")
      );
    } else {
      setNewLan((prevState) => prevState);
    }

    if (rejectedLead.status == 1) {
      setNewLan((prevState) =>
        updateAPIDataToState(prevState, rejectedLead, "rejectedLead")
      );
    } else {
      setNewLan((prevState) => prevState);
    }
  };

  const getFilterLeadData = async () => {
    setNewLan({
      allLeadList: [],
      allLeadIds: [],
      newLead: {
        counter: 0,
        leadList: [],
        id_array: [],
        is_no_more: false,
      },
      phase1Lead: {
        counter: 0,
        leadList: [],
        id_array: [],
        is_no_more: false,
      },
      confirmedLead: {
        counter: 0,
        leadList: [],
        id_array: [],
        is_no_more: false,
      },
      waitingForPaymentLead: {
        counter: 0,
        leadList: [],
        id_array: [],
        is_no_more: false,
      },
      paymentVerifiedLead: {
        counter: 0,
        leadList: [],
        id_array: [],
        is_no_more: false,
      },
      completeLead: {
        counter: 0,
        leadList: [],
        id_array: [],
        is_no_more: false,
      },
      rejectedLead: {
        counter: 0,
        leadList: [],
        id_array: [],
        is_no_more: false,
      },
    });

    if (filterlead.lead_status != -1) {
      // go for Specific lead Status
      await handleCustomLaneScroll(filterlead.lead_status, true);
    } else {
      // get All Lead
      await getAllPhaseLeads();
    }
  };

  const [exportLeadList, setExportLeadList] = useState([]);
  const [loading, setLoading] = useState(false);
  const exportFilteredLead = async (event, done) => {
    setExportLeadList([]);
    setLoading(true);
    let resp = await getNewLeadDataForExport({
      ...filterlead,
    });

    if (resp.status == 1) {
      // return resp.data;
      setExportLeadList(resp?.data);
      downloadCSV(resp.data);
    } else {
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
    }
    setLoading(false);
  };

  const downloadCSV = (data) => {
    // return exportLeadList;
    const csvContent =
      `${expo_columns.map((column) => column.label).join(",")}\n` +
      `${data
        .map((row) =>
          expo_columns
            .map((column) => {
              // Convert value to string
              // const value = (row[column.key] || "").toString();
              const value =
                row[column.key] == undefined ||
                row[column.key] === null ||
                row[column.key] === "null"
                  ? ""
                  : row[column.key].toString();
              return `"${value.replace(/"/g, '""')}"`; // Escape double quotes and enclose in quotes
            })
            .join(",")
        )
        .join("\n")}`;
    // `${data
    //   .map((row) =>
    //     expo_columns?.map((column) => row[column.key] || "").join(",")
    //   )
    //   .join("\n")}`;

    // Create Blob from CSV content
    const blob = new Blob([csvContent], { type: "text/csv" });
    // Create URL for the Blob
    const blobUrl = window.URL.createObjectURL(blob);

    // Create anchor element
    const link = document.createElement("a");
    link.setAttribute("href", blobUrl);
    link.setAttribute("download", "LeadList.csv");

    // Append anchor element to the body and trigger click event
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);

    // const encodedUri = encodeURI(csvContent);
    // const link = document.createElement("a");
    // link.setAttribute("href", encodedUri);
    // link.setAttribute("download", "LeadList.csv");
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  const resetSearchForm = async (newState) => {
    setNewLan({
      allLeadList: [],
      allLeadIds: [],
      newLead: {
        counter: 0,
        leadList: [],
        id_array: [],
        is_no_more: false,
      },
      phase1Lead: {
        counter: 0,
        leadList: [],
        id_array: [],
        is_no_more: false,
      },
      confirmedLead: {
        counter: 0,
        leadList: [],
        id_array: [],
        is_no_more: false,
      },
      waitingForPaymentLead: {
        counter: 0,
        leadList: [],
        id_array: [],
        is_no_more: false,
      },
      paymentVerifiedLead: {
        counter: 0,
        leadList: [],
        id_array: [],
        is_no_more: false,
      },
      completeLead: {
        counter: 0,
        leadList: [],
        id_array: [],
        is_no_more: false,
      },
      rejectedLead: {
        counter: 0,
        leadList: [],
        id_array: [],
        is_no_more: false,
      },
    });

    await getAllPhaseLeads(newState);
  };

  const getDataForSearchLead = async () => {};

  const updateAPIDataToState = (prevState, newResponse, lead_key) => {
    const { leadList } = newResponse.data;
    const { counter } = newResponse.data;
    return {
      ...prevState,
      ["allLeadList"]: [
        ...prevState["allLeadList"],
        ...setDataInCard(leadList),
      ],
      ["allLeadIds"]: [
        ...prevState["allLeadIds"],
        ...leadList?.map((lead) => {
          return lead?.lead_id;
        }),
      ],
      [lead_key]: {
        ...prevState[lead_key],
        counter: counter,
        // leadList: [...prevState[lead_key].leadList, ...setDataInCard(leadList)],
        id_array: [
          ...prevState[lead_key].id_array,
          ...leadList.map((lead) => lead.lead_id),
        ],
        is_no_more: leadList.length < 20 ? true : false,
      },
    };
  };

  const setDataInCard = (list) => {
    return list.map((e, key) => {
      return {
        id: e.lead_id,
        l_type: e.l_type,
        taskTitle: e.name,
        referenceBy: e.reference_by != null ? e.reference_by : "No Ref",
        createdDate: e.date,
        body: e.name,
        contactNo: e.l_contact_no,
        taskCreatedPerson: e.createdBy,
        metadata: { id: e.lead_id },
        person: e.allocatedTo,
        phase: e.l_phase,
        tags: {
          cardId: e.lead_id,
          priority_Id: e.l_type,
          attendance_status: e.attendance_status,
          title:
            e.l_type == 0
              ? "Other"
              : e.l_type == 1
              ? e.attendance_status == 0
                ? "Visitor Present"
                : "Visitor Absent"
              : "Member Renewal",
        },
        expireDate: e.l_type == 2 && e.Due_date,
        chepter_name: e.chepter_name,
        lastElement: key == list.length - 1 ? true : false,
      };
    });
  };

  const [loadingMoreData, setLoadingMoreData] = useState(-1);
  const handleCustomLaneScroll = async (laneId, isCallFromSearch = false) => {
    let lead_key =
      laneId == 0
        ? "newLead"
        : laneId == 1
        ? "phase1Lead"
        : laneId == 3
        ? "confirmedLead"
        : laneId == 4
        ? "waitingForPaymentLead"
        : laneId == 5
        ? "paymentVerifiedLead"
        : laneId == 6
        ? "completeLead"
        : laneId == 7
        ? "rejectedLead"
        : "";

    if (isCallFromSearch || newLan[lead_key].is_no_more != true) {
      if (loadingMoreData != laneId) {
        setLoadingMoreData(laneId);
        let resp = await getNewLeadList({
          ...filterlead,
          l_phase: laneId,
          id_array: isCallFromSearch ? [] : newLan["allLeadIds"],
        });

        if (resp.status == 1) {
          let newDataList = resp.data.leadList;
          let counter = resp.data.counter;
          setNewLan((prevState) => ({
            ...prevState,

            ["allLeadList"]: [
              ...prevState["allLeadList"],
              ...setDataInCard(newDataList),
            ],

            ["allLeadIds"]: [
              ...prevState["allLeadIds"],
              ...newDataList?.map((lead) => {
                return lead?.lead_id;
              }),
            ],

            [lead_key]: {
              ...prevState[lead_key],
              // leadList: [
              //   ...prevState[lead_key].leadList,
              //   ...setDataInCard(newDataList),
              // ],
              counter: counter,
              id_array: [
                ...prevState[lead_key].id_array,
                ...newDataList.map((lead) => lead.lead_id),
              ],
              is_no_more: newDataList.length < 20 ? true : false,
            },
          }));
          setLoadingMoreData(-1);
        } else {
          setNewLan((prevState) => prevState);
          setLoadingMoreData(-1);
        }
      }
      setLoadingMoreData(-1);
    }
    setLoadingMoreData(-1);
  };
  useEffect(async () => {
    allocatelist?.map((option) => {
      filterOption.push({ value: option.u_id, label: option.name });
    });
  }, [allocatelist]);

  const getallocatelistbyid = async () => {
    if (loginUser.u_type == 2) {
      setallocatelist(
        await fetchallocatelistbyid(loginUser.p_user_id, loginUser.u_type)
      );
    } else {
      setallocatelist(
        await fetchallocatelistbyid(loginUser.u_id, loginUser.u_type)
      );
    }
  };

  const onChangeFunction = (e) => {
    setformdata({ ...formdata, [e.target.name]: e.target.value });
  };

  const getleadlist = async () => {
    setLeadData(await fetchleadlistbyid(loginUser.u_id, loginUser.u_type));
  };

  const getLeadByLeadId = async (l_id) => {
    let res = await getLeadByLid(l_id);
    let arr = [];
    if (res.insuranceType != "") {
      arr = res.insuranceType.split(",");
    }
    setformdata({ ...res, insuranceType: arr });
  };

  useEffect(async () => {
    await getallocatelistbyid();
    // await getleadlist();
    setchaplist(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
    // setmemberlist(await GetAllMember(loginUser.city_id, loginUser.u_type));
    // Load All LVB Member List For Reference In Lead
    // get all member but only load  the active member only on the list

    let res = await GetAllMember(0, 0);
    let newMemberList = res.filter((member) => {
      return member.status == 1;
    });
    setmemberlist(newMemberList);
    setstatelist(await fetchStateList());
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const submitformdata = async () => {
    setIsLoading(true);
    let fdata = new FormData();
    Object.keys(formdata)?.map((key) => {
      fdata.append(
        key,
        key == "insuranceType" ? formdata[key].join(",") : formdata[key]
      );
    });

    if (
      formdata.l_allocated_to == undefined ||
      formdata.l_phase == undefined ||
      formdata.name == undefined ||
      formdata.name == "" ||
      formdata.l_business_name == undefined ||
      formdata.l_business_name == "" ||
      formdata.l_contact_no == undefined ||
      formdata.l_contact_no == "" ||
      formdata.l_business_category == undefined ||
      formdata.l_business_category == "" ||
      formdata.date == undefined ||
      formdata.date == ""
    ) {
      let notify = notification({
        type: "error",
        message: "Please Fill Required Fields",
      });
      notify();
      setIsLoading(false);
    } else {
      let resp;
      if (step == 1) {
        if (loginUser.u_id == formdata?.u_allocated && loginUser.u_type == 1) {
          fdata.append("p_u_id", loginUser?.p_user_id);
        } else if (loginUser.u_type == 2 || loginUser.u_type == 1) {
          fdata.append("p_u_id", loginUser?.p_user_id);
        } else {
          fdata.append("p_u_id", loginUser?.u_id);
        }
        // if (loginUser.u_type != 0) {
        //   fdata.append("p_u_id", loginUser?.p_user_id);
        // } else {
        //   fdata.append("p_u_id", loginUser?.u_id);
        // }
        resp = await insertLead(fdata);
      } else if (step == 2) {
        fdata.append("u_id", loginUser?.u_id);
        fdata.append("edit_from_panel", 0);
        // updateLeadMemberForm()
        // resp = await updateleaddetails(fdata);
        resp = await updateLeadMemberForm(fdata);
      }
      if (resp.status == 1) {
        setStep(0);
        setActiveTab(1);
        setformdata({ l_created_by: loginUser.u_id, l_type: 0 });

        // New Lead API CALL
        setNewLan(initNewLan);
        await getAllPhaseLeads();
        // getleadlist();
      }
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      setIsLoading(false);
    }
  };

  const submitfollowup = async () => {
    const resp = await insertFollowUp(JSON.stringify(followUpData));
    if (resp.status == 1) {
      setFollowUpModal(false);
      setFollowUpData({ u_id: loginUser.u_id });
      setLeadHistory(await featchleadhistory(myModal.lead_id));
    }
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
  };

  const submitleadtransfer = async () => {
    const resp = await leadTransferTO(JSON.stringify(leadtransData));
    if (resp.status == 1) {
      setLeadTransModal(false);
      setLeadTransData({ u_id: loginUser.u_id });
      setLeadHistory(await featchleadhistory(myModal.lead_id));
      // New Lead API CALL
      setNewLan(initNewLan);
      await getAllPhaseLeads();
      // getleadlist();
    }
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
  };

  const submitmemberrenewal = async () => {
    let resp = await memberRenewal(JSON.stringify(memberrenewaldata));
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      let lfdata = new FormData();
      lfdata.append("lead_id", memberrenewaldata.cardId);
      lfdata.append("l_phase", memberrenewaldata.targetLaneId);
      lfdata.append("u_id", loginUser.u_id);
      lfdata.append("from_l_phase", memberrenewaldata.sourceLaneId);

      const resp = await updateleadstatus(lfdata);
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      if (resp.status == 1) {
        setmemberrenewalmodal(false);
        setmemberrenewaldata({
          newdue_date: moment().add(1, "years").format("DD-MM-YYYY"),
        });
        // await getleadlist();
        // New Lead API CALL
        setNewLan(initNewLan);
        await getAllPhaseLeads();
      }
    }
    // if (resp.status == 1) {
    //   setmemberrenewalmodal(false);
    //   setmemberrenewaldata({ newdue_date: moment().add(1, "years").format("DD-MM-YYYY") });
    //   await getleadlist();
    // }
  };

  const leadStatusChange = async (cardId, targetLaneId, sourceLaneId) => {
    if (targetLaneId == sourceLaneId) {
    } else if (
      ((sourceLaneId == 4 &&
        ((loginUser.p_user_id != 1 && loginUser.u_type == 2) ||
          (loginUser.p_user_id == 1 && loginUser.u_type == 1) ||
          (loginUser.p_user_id == 1 &&
            loginUser.u_type == 2 &&
            loginUser.management_p == 0 &&
            loginUser.account_p == 0))) ||
        ((sourceLaneId == 5 || sourceLaneId == 6) &&
          loginUser.u_type == 2 &&
          loginUser.management_p == 0 &&
          loginUser.account_p == 0)) &&
      loginUser.u_type != 0
    ) {
      closeLeadStatusChangeModal();

      let notify = notification({
        type: "error",
        message: "You don't have Permission ",
      });
      notify();
      // await getleadlist();
      // New Lead API CALL
      setNewLan(initNewLan);
      await getAllPhaseLeads();
    } else if (
      ((targetLaneId == 5 &&
        ((loginUser.p_user_id != 1 && loginUser.u_type == 2) ||
          (loginUser.p_user_id == 1 && loginUser.u_type == 1) ||
          (loginUser.p_user_id == 1 &&
            loginUser.u_type == 2 &&
            loginUser.management_p == 0 &&
            loginUser.account_p == 0))) ||
        (targetLaneId == 6 &&
          loginUser.u_type == 2 &&
          loginUser.management_p == 0)) &&
      loginUser.u_type != 0
    ) {
      closeLeadStatusChangeModal();

      let notify = notification({
        type: "error",
        message: "You don't have Permission ",
      });
      notify();
      // await getleadlist();
      // New Lead API CALL
      setNewLan(initNewLan);
      await getAllPhaseLeads();
    } else if (
      targetLaneId == 6 &&
      (loginUser.u_type == 0 ||
        loginUser.u_type == 1 ||
        (loginUser.u_type == 2 && loginUser.management_p == 1))
    ) {
      let resp = await featchleadhistory(cardId);
      if (resp?.leaddetails?.l_type == 2) {
        let memberresp = await fetchrenewalmemberdata(cardId);
        setmemberrenewaldata({
          ...memberrenewaldata,
          ...memberresp,
          cardId: cardId,
          targetLaneId: targetLaneId,
          sourceLaneId: sourceLaneId,
        });

        setmemberrenewalmodal(true);
      } else {
        setConvertMemberModal(true);
        setnewmemberdata({
          ...memberdata,
          ...resp.leaddetails,
          cardId: cardId,
          targetLaneId: targetLaneId,
          sourceLaneId: sourceLaneId,
          member_type: resp?.leaddetails?.l_type == 2 ? 1 : 0,
        });
      }

      // New Lead API CALL
      setNewLan(initNewLan);
      await getAllPhaseLeads();
      // await getleadlist();
    } else {
      let lfdata = new FormData();
      lfdata.append("lead_id", cardId);
      lfdata.append("l_phase", targetLaneId);
      lfdata.append("u_id", loginUser.u_id);
      lfdata.append("from_l_phase", sourceLaneId);

      const resp = await updateleadstatus(lfdata);
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      if (resp.status == 1) {
        // New Lead API CALL
        setNewLan(initNewLan);
        closeLeadStatusChangeModal();
        await getAllPhaseLeads();
        // await getleadlist();
      }
    }
  };

  // KanBan Board
  let len = [[], [], [], [], [], [], [], []];
  newleadData?.map((e, key) => {
    let temp = {
      id: e.lead_id,
      l_type: e.l_type,
      taskTitle: e.name,
      referenceBy: e.reference_by != null ? e.reference_by : "No Ref",
      createdDate: e.date,
      body: e.name,
      contactNo: e.l_contact_no,
      taskCreatedPerson: e.createdBy,
      metadata: { id: e.lead_id },
      person: e.allocatedTo,
      phase: e.l_phase,
      tags: {
        cardId: e.lead_id,
        priority_Id: e.l_type,
        attendance_status: e.attendance_status,
        title:
          e.l_type == 0
            ? "Other"
            : e.l_type == 1
            ? e.attendance_status == 0
              ? "Visitor Present"
              : "Visitor Absent"
            : "Member Renewal",
      },
      expireDate: e.l_type == 2 && e.Due_date,
      chepter_name: e.chepter_name,
    };
    // In Complete And  Rejected Lead Status Set to display on 30 Record on it
    if (e.l_phase == 6) {
      // if (len[e.l_phase].length < 30) {
      len[e.l_phase].push(temp);
      // }
    } else if (e.l_phase == 7) {
      // if (len[e.l_phase].length < 30) {
      len[e.l_phase].push(temp);
      // }
    } else {
      len[e.l_phase].push(temp);
    }
  });

  const leadList = {
    lanes: [
      {
        id: "0",
        title: "New Lead",
        className: "taskListColumn openTask",
        cards: newLan.allLeadList.filter((item) => item.phase == 0),
        label: newLan.newLead.counter,
        currentPage: 1,
        // cards: len[0],
        // label: `${len[0].length}`,

        style: { position: "relative" },
      },
      {
        id: "1",
        title: "Phase 1",
        className: "taskListColumn openTask",
        cards: newLan.allLeadList.filter((item) => item.phase == 1),
        // cards: newLan.phase1Lead.leadList,
        label: newLan.phase1Lead.counter,
        currentPage: 1,

        // cards: len[1],
        // label: `${len[1].length}`,
        style: { position: "relative" },
      },
      // {
      //   id: "2",
      //   title: "Phase 2",
      //   className: "taskListColumn completeTask",
      //   label: `${len[2].length}`,
      //   cards: len[2],
      //   style: { position: "relative" }
      // },
      {
        id: "3",
        title: "Confirm",
        className: "taskListColumn inReviewTask",
        cards: newLan.allLeadList.filter((item) => item.phase == 3),

        // cards: newLan.confirmedLead.leadList,
        label: newLan.confirmedLead.counter,
        currentPage: 1,

        // label: `${len[3].length}`,
        // cards: len[3],
        style: { position: "relative" },
      },

      {
        id: "4",
        title: "Waiting For Payment",
        className: "taskListColumn finishTask",
        cards: newLan.allLeadList.filter((item) => item.phase == 4),

        // cards: newLan.waitingForPaymentLead.leadList,
        label: newLan.waitingForPaymentLead.counter,
        currentPage: 1,

        // label: `${len[4].length}`,
        // cards: len[4],
        style: { position: "relative" },
      },
      {
        id: "5",
        title: "Payment Verified",
        className: "taskListColumn finishTask",
        cards: newLan.allLeadList.filter((item) => item.phase == 5),
        // cards: newLan.paymentVerifiedLead.leadList,

        label: newLan.paymentVerifiedLead.counter,
        currentPage: 1,

        // label: `${len[5].length}`,
        // cards: len[5],
        style: { position: "relative" },
      },
      {
        id: "6",
        title: "Complete",
        className: "taskListColumn finishTask",
        cards: newLan.allLeadList.filter((item) => item.phase == 6),

        // cards: newLan.completeLead.leadList,
        label: newLan.completeLead.counter,
        currentPage: 1,

        // label: `${len[6].length}`,
        // cards: len[6],
        style: { position: "relative" },
      },
      {
        id: "7",
        title: "Rejectd",
        className: "taskListColumn rejectedTask",
        cards: newLan.allLeadList.filter((item) => item.phase == 7),

        // cards: newLan.rejectedLead.leadList,
        label: newLan?.rejectedLead?.counter || 0,
        currentPage: 1,

        // label: `${len[7].length}`,
        // cards: len[7],
        style: { position: "relative" },
      },
    ],
  };

  const CustomCard = ({
    onClick,
    taskTitle,
    body,
    contactNo,
    createdDate,
    subTitle,
    taskCreatedPerson,
    tags,
    showDeleteButton,
    onDelete,
    person,
    phase,
    referenceBy,
    l_type,
    id,
    expireDate,
    chepter_name,
    lastElement,
  }) => {
    const clickDelete = (e) => {
      onDelete();
      e.stopPropagation();
    };

    const handleRightClick = (e) => {};

    return (
      <>
        <div
          id={id}
          phase={phase}
          className="myCard"
          style={{ position: "relative" }}
        >
          <div className="taskCard" style={{ position: "relative" }}>
            <div>
              <header
                className="taskHeader"
                style={{ paddingBottom: "8px" }}
                onClick={() => {
                  setLeadStatusChangeData({
                    ...leadStatusChangeData,
                    lead_id: id,
                    from_l_phase: phase,
                  });
                  setLeadStatusChangeModal(true);
                  // setDvisible({ mval: true, id: id });
                }}
              >
                <div className="TaskTitle">{taskTitle}</div>
                {/* <Tooltip title="Copy Form Link" style={{ padding: 0, margin: 0 }}>
                <span
                  id={id}
                  style={{
                    position: "absolute",
                    right: "0px",
                    cursor: "pointer",
                    background: "#1c4766",
                    borderRadius: "20px",
                    boxShadow: "0 0 11px 0px #787878ab",
                    display: "flex",
                    height: "25px",
                    width: "25px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={async () => {
                    let base64data = new Buffer(
                      "LVB_" + id.toString()
                    ).toString("base64");
                    let url =
                      window.location.origin +
                      "/lvb-membership-form/" +
                      base64data;
                    navigator.clipboard.writeText(url);
                    let notify = notification({
                      type: "success",
                      message: "Copy to clipboard",
                      position: "top",
                    });
                    await notify();
                  }}
                >
                  <ContentCopy
                    style={{ height: "15px", width: "15px", color: "white" }}
                  />
                </span>
              </Tooltip> */}
                <a
                  className=""
                  style={{ position: "absolute", right: "0px", zIndex: "0" }}
                  onClick={() => {
                    setLeadStatusChangeData({
                      ...leadStatusChangeData,
                      lead_id: id,

                      from_l_phase: phase,
                    });
                    setLeadStatusChangeModal(true);
                    // setDvisible({ mval: true, id: id });
                  }}
                >
                  <Tooltip
                    title="Change Lead Status"
                    style={{ padding: 0, margin: 0 }}
                  >
                    <IconButton>
                      <ChangeCircle
                        color="success"
                        style={{ height: "20px", width: "20px" }}
                      />
                    </IconButton>
                  </Tooltip>
                </a>
                {showDeleteButton && <div onClick={clickDelete} />}
              </header>
              <div
                className="taskBody"
                onContextMenu={handleRightClick}
                onClick={onClick}
              >
                {/* <div>{subTitle}</div> */}
                <p>
                  <b>Name : </b> {taskTitle}
                </p>
                <p>
                  <b>Contact : </b> {contactNo}
                </p>
                {l_type != 0 && (
                  <p>
                    <b>Chapter : </b> {chepter_name}
                  </p>
                )}
                {l_type == 2 && (
                  <p>
                    <b>Membership Expire : </b> {expireDate}
                  </p>
                )}
                <p>
                  {l_type != 0 && referenceBy != "null" && (
                    <>
                      <b>Ref : </b> {referenceBy}
                    </>
                  )}
                </p>
              </div>
              <div className="taskUserDetail">
                <div className="taskCreatedName">
                  <b>Assign To : {person}</b>
                </div>
                <div className="taskDate">
                  <p>
                    <b>Lead Date :</b> {createdDate}
                  </p>
                </div>
              </div>
            </div>
            <div className="taskFooter">
              <div>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {taskCreatedPerson}
                </p>
              </div>
              <div>
                <a
                  key={tags.title}
                  {...tags}
                  className="taskPriority"
                  attendance_status={tags.attendance_status}
                  // onClick={() => {
                  //   alert("hii");
                  // }}
                >
                  {tags.title}
                </a>
              </div>
            </div>
          </div>
        </div>

        {loadingMoreData == phase && lastElement ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              color="primary"
              fourColor
              variant="indeterminate"
            />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const cancelModal = () => {
    setMyModal({ mval: false, lead_id: 0, l_phase: 0 });
    setFollowUpModal(false);
    setLeadTransModal(false);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const paymentimg = async () => {
    let fndata = new FormData();
    fndata.append("l_payment_ss", payimgData.img);
    fndata.append("lead_id", myModal.lead_id);
    fndata.append("note", payimgData.note);
    const resp = await uploadPaymentImage(fndata);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      // New Lead API CALL
      setNewLan(initNewLan);
      await getAllPhaseLeads();
      // await getleadlist();
      setLeadHistory(await featchleadhistory(myModal.lead_id));
      setpayimgData({});
      setpaymentimgModal(false);
      setfileimage();
    }
  };

  const handleChange = (panel) => {
    setExpanded(expanded == panel ? -1 : panel);
    // setExpanded(newExpanded ? panel : false);
  };

  const handlememberform = (e) => {
    setnewmemberdata({ ...memberdata, [e.target.name]: e.target.value });
  };

  const expo_columns = [
    { label: "No", key: "lead_id" },
    { label: "Lead Date", key: "date" },
    { label: "Type", key: "lead_type" },
    { label: "Name", key: "name" },
    { label: "DOB", key: "dob" },
    { label: "Contact No", key: "l_contact_no" },
    { label: "Alternate Contact No", key: "alternate_contact_number" },
    { label: "Insurance", key: "new_insurance" },
    { label: "aadhaar Card Link", key: "aadhaarFilePath" },
    { label: "FaceBook Id", key: "facebook_url" },
    { label: "Instagram Id", key: "instagram_url" },
    { label: "Business Name", key: "l_business_name" },
    { label: "Business Category", key: "l_business_category" },
    { label: "Business Description", key: "l_business_description" },
    { label: "GST Number", key: "gst_no" },
    { label: "Email Id", key: "l_email" },
    { label: "Other Group", key: "other_group_name" },
    { label: "Office Address", key: "l_address" },
    { label: "Home Address", key: "l_h_address" },
    { label: "Payment Date", key: "payment_date" },
    { label: "Payment Mode", key: "new_payment_mode" },
    { label: "Professional Photo", key: "professionalPhotoPath" },
    { label: "Product Photo", key: "productFilePath" },
    { label: "Reference 1", key: "l_reference_1" },
    { label: "Reference 2", key: "l_reference_2" },
    { label: "Payment Screenshot", key: "paymentScreenshotPath" },
    { label: "Payment Note", key: "note" },
    { label: "Attendance Status", key: "new_Attendance_Status" },
    { label: "Created By", key: "createdBy" },
    { label: "Allocated To", key: "allocatedTo" },
    { label: "Phase", key: "lead_phase" },
    { label: "Refrence By", key: "reference_by" },
  ];

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row.lead_id,
      sortable: true,
      width: "70px",
    },
    {
      name: "Date",
      wrap: true,
      selector: (row) => row.date,
    },
    {
      name: "Type",
      wrap: true,
      selector: (row) =>
        row.l_type == 0
          ? "Other"
          : row.l_type == 1
          ? row.attendance_status == 0
            ? "Visitor Present"
            : "Visitor Absent"
          : "Member Renewal",
    },
    {
      name: "Name",
      wrap: true,
      selector: (row) => row.name,
    },

    {
      name: "Contact No",
      wrap: true,
      selector: (row) => row.l_contact_no,
    },
    {
      name: "Created By",
      wrap: true,
      selector: (row) => row.createdBy,
    },
    {
      name: "Allocated To",
      wrap: true,
      selector: (row) => row.allocatedTo,
    },
    {
      name: "Phase",
      wrap: true,
      selector: (row) => (
        <>
          {statusList &&
            statusList.map((k) => {
              if (k.value == row.l_phase) {
                return k.label;
              }
            })}
        </>
      ),
      sortable: true,
    },
    {
      name: "Refrence By",
      wrap: true,
      selector: (row) => row.reference_by,
    },

    // {
    //   name: "Status",
    //   selector: (row) => (
    //     <>
    //       {row.c_status == 0 ? (
    //         <>
    //           <div style={{ margin: "10px 0" }}>
    //             <Label
    //               variant="ghost"
    //               color="success"
    //               style={{ marginBottom: "10px", display: "flex", fontSize: "16px" }}
    //             >
    //               Active
    //             </Label>

    //             <Button
    //               variant="contained"
    //               onClick={() => {
    //                 changestatus(row.city_id, 1);
    //               }}
    //               style={{ background: "#ff4842" }}
    //             >
    //               Deactive
    //             </Button>
    //           </div>
    //         </>
    //       ) : (
    //         <>
    //           <div style={{ margin: "10px 0" }}>
    //             <Label
    //               variant="ghost"
    //               color="error"
    //               style={{ marginBottom: "10px", display: "flex", fontSize: "14px" }}
    //             >
    //               Deactive
    //             </Label>

    //             <Button
    //               variant="contained"
    //               onClick={() => {
    //                 changestatus(row.city_id, 0);
    //               }}
    //             >
    //               Active
    //             </Button>
    //           </div>
    //         </>
    //       )}
    //     </>
    //   )
    // },
    // {
    //   name: "Action",
    //   sortable: true,
    //   cell: (row) => (
    //     <>
    //       <a
    //         onClick={async () => {
    //           setformdata(row);
    //           setStep(2);
    //           getstate();
    //         }}
    //       >
    //         <Tooltip title="Edit City">
    //           <IconButton>
    //             <EditSharp color="warning" />
    //           </IconButton>
    //         </Tooltip>
    //       </a>
    //       <a
    //         onClick={async () => {
    //           setCityFormLink(row.city_id);
    //         }}
    //       >
    //         <Tooltip title="Click to Copy Link">
    //           <IconButton>
    //             <ContentCopy color="dark" />
    //           </IconButton>
    //         </Tooltip>
    //       </a>
    //     </>
    //   )
    // }
  ];
  const [
    isLoading_leadConvertMember_Btn,
    setIsLoading_leadConvertMember_Btn,
  ] = useState(false);

  const submitMemberForm = async () => {
    setIsLoading_leadConvertMember_Btn(true);
    if (
      // Chapter
      memberdata.chpt_id != undefined &&
      memberdata.chpt_id != 0 &&
      // State
      memberdata.state_id != undefined &&
      memberdata.state_id != 0 &&
      // City
      memberdata.city_id != undefined &&
      memberdata.city_id != 0 &&
      // Member Name
      memberdata.name != undefined &&
      memberdata.name.trim().length != 0 &&
      memberdata.name.trim() != "" &&
      // Join Date
      memberdata.joining_date != undefined &&
      memberdata.joining_date.trim().length != 0 &&
      memberdata.joining_date.trim() != "" &&
      // Expiry Date
      memberdata.due_date != undefined &&
      memberdata.due_date.trim().length != 0 &&
      memberdata.due_date.trim() != "" &&
      // Password
      memberdata.password != undefined &&
      memberdata.password.trim().length != 0 &&
      memberdata.password.trim() != "" &&
      // Contact Number
      memberdata.l_contact_no != undefined &&
      memberdata.l_contact_no.trim().length != 0 &&
      memberdata.l_contact_no.trim() != "" &&
      // Business Number
      memberdata.l_business_name != undefined &&
      memberdata.l_business_name.trim().length != 0 &&
      memberdata.l_business_name.trim() != "" &&
      // Business Category
      memberdata.l_business_category != undefined &&
      memberdata.l_business_category.trim().length != 0 &&
      memberdata.l_business_category.trim() != ""
    ) {
      setIsLoading_leadConvertMember_Btn(false);
      let fndata = new FormData();
      fndata.append("chpt_id", memberdata.chpt_id);
      fndata.append("m_name", memberdata.name);
      fndata.append("mobile_no", memberdata.l_contact_no);
      fndata.append("m_email_id", memberdata.l_email);
      fndata.append("password", memberdata.password);
      fndata.append("type", memberdata.type);
      fndata.append("business_name", memberdata.l_business_name);
      fndata.append("business_category", memberdata.l_business_category);
      fndata.append("city_id", memberdata.city_id);
      fndata.append("state_id", memberdata.state_id);
      fndata.append("joining_date", memberdata.joining_date);
      fndata.append("due_date", memberdata.due_date);
      fndata.append("reference_m_id", memberdata.invite_by);
      fndata.append("member_type", memberdata.member_type);
      let resp = await insertmember(fndata);
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      if (resp.status == 1) {
        let lfdata = new FormData();
        lfdata.append("lead_id", memberdata.cardId);
        lfdata.append("l_phase", memberdata.targetLaneId);
        lfdata.append("u_id", loginUser.u_id);
        lfdata.append("from_l_phase", memberdata.sourceLaneId);

        const resp = await updateleadstatus(lfdata);
        let notify = notification({
          type: resp.status == 0 ? "error" : "success",
          message: resp.message,
        });
        notify();
        if (resp.status == 1) {
          setIsLoading_leadConvertMember_Btn(false);
          setConvertMemberModal(false);
          setnewmemberdata({
            joining_date: moment().format("DD-MM-YYYY"),
            due_date: moment().add(1, "years").format("DD-MM-YYYY"),
            type: 0,
          });
          // New Lead API CALL
          setNewLan(initNewLan);
          await getAllPhaseLeads();
          // await getleadlist();
        }
      }
    } else {
      let notify = notification({
        type: "error",
        message: `Please Fill All Required Information In Form`,
      });
      notify();
      setIsLoading_leadConvertMember_Btn(false);
    }
  };

  const [activeTab, setActiveTab] = useState(1);
  const allowedFileTypes = [
    // Images
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    // PDFs
    "application/pdf",
    // Microsoft Word Documents
    "application/msword", // .doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
  ];

  // F  or Product & Professional Photos
  const allowFileImageTypes = [
    // Images
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
  ];
  const handleFileChange = (event, inputName) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size <= 11 * 1024 * 1024) {
        // 2MB in bytes
        if (
          (inputName == "aadhaar"
            ? allowedFileTypes
            : allowFileImageTypes
          ).includes(file.type)
        ) {
          // setSelectedFile(file);
          setformdata({ ...formdata, [inputName]: file });
          // setErrors({ ...errors, [inputName]: "" });
          // setErrorMessage('');
        } else {
          // setErrors({
          //   ...errors,
          //   [inputName]: `${
          //     inputName == "aadhaar"
          //       ? "Upload File Must Be PDF, Image or Doc"
          //       : "Upload File Must be an Image"
          //   }  `,
          // });
          // setSelectedFile(null);
          // setErrorMessage('Invalid file type. Please upload an image, PDF, or DOC file.');
        }
      } else {
        // setErrors({
        //   ...errors,
        //   [inputName]: "File Sizes Greater Than 2MB Are Not Supported",
        // });
        // setSelectedFile(null);
        // setErrorMessage('File size exceeds 2MB limit.');
      }
    }
  };

  const [updateby_member, setUpdateByMember] = useState(false);
  useEffect(() => {
    setUpdateByMember(
      leadHistory?.leaddetails?.updateby_member == 0 ? true : false
    );
  }, [leadHistory]);

  return (
    <>
      <Modal
        style={{ overflow: "scroll" }}
        open={myModal.mval}
        onClose={cancelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="div"
            component="div"
            style={{
              margin: "10px",
              textAlign: "center",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%" }}>
              <Tooltip
                title="Edit Lead"
                onClick={async () => {
                  await getLeadByLeadId(myModal.lead_id);
                  await cancelModal();
                  await setStep(2);
                }}
              >
                <IconButton>
                  <EditSharp color="warning" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Add Followup"
                onClick={async () => {
                  // await setMyModal({ mval: false, lead_id: 0 });
                  await setFollowUpData({
                    ...followUpData,
                    lead_id: myModal.lead_id,
                  });
                  await setFollowUpModal(true);
                }}
              >
                <IconButton>
                  <SendSharp color="info" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Transfer Lead"
                onClick={async () => {
                  await setLeadTransData({
                    ...leadtransData,
                    lead_id: myModal.lead_id,
                  });
                  await setLeadTransModal(true);
                }}
              >
                <IconButton>
                  <PersonAddAltSharp color="success" />
                </IconButton>
              </Tooltip>
            </div>

            <div>
              <Tooltip
                title="Close"
                onClick={async () => {
                  await cancelModal();
                  setValue("1");
                }}
              >
                <IconButton>
                  <Close color="blank" />
                </IconButton>
              </Tooltip>
            </div>
          </Typography>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
                centered
              >
                <Tab
                  icon={<LeaderboardSharp />}
                  iconPosition="end"
                  label="Lead Details"
                  value="1"
                />
                <Tab
                  icon={<ThumbUpAlt />}
                  iconPosition="end"
                  label="Follow Up History"
                  value="2"
                />
                <Tab
                  icon={<AutoGraphSharp />}
                  iconPosition="end"
                  label="Activity Log"
                  value="3"
                />
              </TabList>
            </Box>
            <div
              style={{
                maxHeight: "500px",
                height: "500px",
                overflowY: "scroll",
              }}
            >
              <TabPanel value="1">
                <>
                  <Grid container spacing={2}>
                    <Grid xs={8} item>
                      {/* Personal Details Card */}
                      <Card style={{ border: "1px solid #abababb5" }}>
                        <div>
                          <h3
                            style={{
                              padding: "15px",
                              background: "#1c4766",
                              color: "#fff",
                            }}
                          >
                            Personal Information
                          </h3>
                          <Grid container spacing={0}>
                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>Name </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.name || "-"}
                                </p>
                              </div>
                            </Grid>
                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>DOB </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.dob || "-"}
                                </p>
                              </div>
                            </Grid>
                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Contact No{" "}
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.l_contact_no ||
                                    "-"}
                                </p>
                              </div>
                            </Grid>
                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Alternate Contact No{" "}
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails
                                    ?.alternate_contact_number || "-"}
                                </p>
                              </div>
                            </Grid>
                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>Insurance</h4>
                                <p className="word-break-all">
                                  {/* {leadHistory?.leaddetails?.insuranceType == 1
                                    ? "Health Insurance"
                                    : leadHistory?.leaddetails?.insuranceType ==
                                      2
                                    ? "Life Insurance"
                                    : leadHistory?.leaddetails?.insuranceType ==
                                      3
                                    ? "Term Insurance"
                                    : leadHistory?.leaddetails?.insuranceType ==
                                      4
                                    ? "None"
                                    : leadHistory?.leaddetails?.insuranceType ==
                                      0
                                    ? `Other - ${leadHistory?.leaddetails?.otherInsurance}`
                                    : "-"} */}

                                  {leadHistory?.leaddetails?.insuranceType
                                    ?.split(",")
                                    ?.map((type, index) => (
                                      <span key={index}>
                                        {type == 1
                                          ? "Health Insurance"
                                          : type == 2
                                          ? "Life Insurance"
                                          : type == 3
                                          ? "Term Insurance"
                                          : type == 4
                                          ? "None"
                                          : type == 0
                                          ? `Other - ${leadHistory?.leaddetails?.otherInsurance}`
                                          : "-"}
                                        {index !==
                                          leadHistory?.leaddetails?.insuranceType?.split(
                                            ","
                                          ).length -
                                            1 && ", "}
                                      </span>
                                    ))}
                                </p>
                              </div>
                            </Grid>
                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <Grid container spacing={0}>
                                  <Grid xs={9} item>
                                    <h4 style={{ color: "#838383" }}>
                                      Aadhaar Card
                                    </h4>
                                    <p className="word-break-all">
                                      {leadHistory?.leaddetails?.aadhaar || "-"}
                                    </p>
                                  </Grid>
                                  {leadHistory?.leaddetails?.aadhaar && (
                                    <Grid xs={3} item>
                                      <div
                                        className="d-flex"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "end",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <a
                                          className="cursor-pointer"
                                          href="#"
                                          onClick={() => {
                                            saveAs(
                                              Routes.API_HOSTNAME +
                                                "images/leadMemberAadhaar/" +
                                                leadHistory?.leaddetails
                                                  ?.aadhaar,
                                              leadHistory?.leaddetails?.aadhaar
                                            );
                                          }}
                                        >
                                          Download
                                        </a>
                                        <a
                                          className="cursor-pointer"
                                          href={
                                            Routes.API_HOSTNAME +
                                            "images/leadMemberAadhaar/" +
                                            leadHistory?.leaddetails?.aadhaar
                                          }
                                          target="_blank"
                                        >
                                          Open
                                        </a>
                                      </div>
                                    </Grid>
                                  )}
                                </Grid>
                              </div>
                            </Grid>
                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Facebook ID
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.facebook_url ||
                                    "-"}
                                </p>
                              </div>
                            </Grid>
                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Instagram ID
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.instagram_url ||
                                    "-"}
                                </p>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Card>

                      {/* Business Details Card */}
                      <Card
                        style={{
                          border: "1px solid #abababb5",
                          marginTop: "15px",
                        }}
                      >
                        <div>
                          <h3
                            style={{
                              padding: "15px",
                              background: "#1c4766",
                              color: "#fff",
                            }}
                          >
                            Business Information
                          </h3>
                          <Grid container spacing={0}>
                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Business Name
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.l_business_name ||
                                    "-"}
                                </p>
                              </div>
                            </Grid>

                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Business Category
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails
                                    ?.l_business_category || "-"}
                                </p>
                              </div>
                            </Grid>
                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Business Description
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails
                                    ?.l_business_description || "-"}
                                </p>
                              </div>
                            </Grid>
                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>GST No </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.gst_no || "-"}
                                </p>
                              </div>
                            </Grid>
                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>Email ID</h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.l_email || "-"}
                                </p>
                              </div>
                            </Grid>
                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Other Group Name
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.other_group_name ||
                                    "-"}
                                </p>
                              </div>
                            </Grid>

                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Office Address
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.l_address || "-"}
                                </p>
                              </div>
                            </Grid>

                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Home Address
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.l_h_address || "-"}
                                </p>
                              </div>
                            </Grid>

                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Payment Date
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.payment_date ||
                                    "-"}
                                </p>
                              </div>
                            </Grid>
                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Payment Mode
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.payment_mode == 0
                                    ? "Cash"
                                    : leadHistory?.leaddetails?.payment_mode ==
                                      1
                                    ? "Transfer"
                                    : leadHistory?.leaddetails?.payment_mode ==
                                      2
                                    ? "Cheque"
                                    : "-"}
                                </p>
                              </div>
                            </Grid>
                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <Grid container spacing={0}>
                                  <Grid xs={9} item>
                                    <h4 style={{ color: "#838383" }}>
                                      Professional Photo
                                    </h4>
                                    <p className="word-break-all">
                                      {leadHistory?.leaddetails
                                        ?.l_professional_photo || "-"}
                                    </p>
                                  </Grid>
                                  {leadHistory?.leaddetails
                                    ?.l_professional_photo && (
                                    <Grid xs={3} item>
                                      <div
                                        className="d-flex"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "end",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <a
                                          className="cursor-pointer"
                                          href="#"
                                          onClick={() => {
                                            saveAs(
                                              Routes.API_HOSTNAME +
                                                "images/leadMemberProfile/" +
                                                leadHistory?.leaddetails
                                                  ?.l_professional_photo,
                                              leadHistory?.leaddetails
                                                ?.l_professional_photo
                                            );
                                          }}
                                        >
                                          Download
                                        </a>
                                        <a
                                          className="cursor-pointer"
                                          href={
                                            Routes.API_HOSTNAME +
                                            "images/leadMemberProfile/" +
                                            leadHistory?.leaddetails
                                              ?.l_professional_photo
                                          }
                                          target="_blank"
                                        >
                                          Open
                                        </a>
                                      </div>
                                    </Grid>
                                  )}
                                </Grid>
                              </div>
                            </Grid>
                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <Grid container spacing={0}>
                                  <Grid xs={9} item>
                                    <h4 style={{ color: "#838383" }}>
                                      Product Photo
                                    </h4>
                                    <p className="word-break-all">
                                      {leadHistory?.leaddetails
                                        ?.l_product_photo || "-"}
                                    </p>
                                  </Grid>
                                  {leadHistory?.leaddetails
                                    ?.l_product_photo && (
                                    <Grid xs={3} item>
                                      <div
                                        className="d-flex"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "end",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <a
                                          className="cursor-pointer"
                                          href="#"
                                          onClick={() => {
                                            saveAs(
                                              Routes.API_HOSTNAME +
                                                "images/leadMemberProduct/" +
                                                leadHistory?.leaddetails
                                                  ?.l_product_photo,
                                              leadHistory?.leaddetails
                                                ?.l_product_photo
                                            );
                                          }}
                                        >
                                          Download
                                        </a>
                                        <a
                                          className="cursor-pointer"
                                          href={
                                            Routes.API_HOSTNAME +
                                            "images/leadMemberProduct/" +
                                            leadHistory?.leaddetails
                                              ?.l_product_photo
                                          }
                                          target="_blank"
                                        >
                                          Open
                                        </a>
                                      </div>
                                    </Grid>
                                  )}
                                </Grid>
                              </div>
                            </Grid>

                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Reference 1
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.l_reference_1 ||
                                    "-"}
                                </p>
                              </div>
                            </Grid>
                            <Grid xs={6} item>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Reference 2
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.l_reference_2 ||
                                    "-"}
                                </p>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Card>
                    </Grid>

                    {/* Right Side  */}
                    <Grid xs={4} item>
                      <div style={{ position: "sticky", top: "10px" }}>
                        <Card
                          style={{
                            border: "1px solid #abababb5",
                          }}
                        >
                          <div>
                            <h3
                              style={{
                                padding: "15px",
                                background: "#1c4766",
                                color: "#fff",
                              }}
                            >
                              Lead Information
                            </h3>
                            <div>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>Date </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.date}
                                </p>
                              </div>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>Lead Type </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.l_type == 0
                                    ? "Other"
                                    : leadHistory?.leaddetails?.l_type == 1
                                    ? "Visitor"
                                    : "Member"}
                                </p>
                              </div>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Lead Status{" "}
                                </h4>
                                <p className="word-break-all">
                                  {statusList?.map((e) => {
                                    if (
                                      e.value ==
                                      leadHistory?.leaddetails?.l_phase
                                    ) {
                                      return e.label;
                                    }
                                  })}
                                </p>
                              </div>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Allocated To
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.allocatedTo}
                                </p>
                              </div>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Created By{" "}
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.createdBy}
                                </p>
                              </div>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                  borderBottom: "1px solid #abababb5",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Reference By{" "}
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.reference_by ||
                                    "-"}
                                </p>
                              </div>
                              <div
                                style={{
                                  margin: "5px 15px",
                                  paddingBottom: "5px",
                                }}
                              >
                                <h4 style={{ color: "#838383" }}>
                                  Payment Note{" "}
                                </h4>
                                <p className="word-break-all">
                                  {leadHistory?.leaddetails?.note || "-"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Card>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "10px auto",
                          }}
                        >
                          <div style={{ padding: "5px" }}>
                            {leadHistory?.leaddetails?.l_phase == 4 && (
                              <Button
                                variant="contained"
                                component="label"
                                onClick={() => {
                                  setpaymentimgModal(true);
                                }}
                              >
                                Upload Payment Deatils
                                {/* <input
                                id="file"
                                name="file"
                                type="file"
                                hidden
                                onChange={async (event) => {
                                  await paymentimg(event.currentTarget.files[0], myModal.lead_id);
                                }}
                                className="form-control"
                              /> */}
                              </Button>
                            )}
                          </div>
                          <div style={{ padding: "5px" }}>
                            {leadHistory?.leaddetails?.l_payment_ss != null && (
                              <Button
                                // href={`${Routes.API_HOSTNAME}images/payment/${leadHistory?.leaddetails?.l_payment_ss}`}
                                variant="contained"
                                component="label"
                                onClick={() => {
                                  saveAs(
                                    `${Routes.API_HOSTNAME}images/payment/${leadHistory?.leaddetails?.l_payment_ss}`,
                                    `${leadHistory?.leaddetails?.name}_Payment`
                                  );
                                }}
                              >
                                Click To Download
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </>
              </TabPanel>
              <TabPanel value="2">
                <>
                  {leadHistory?.followupdetails?.map((e, key) => {
                    return (
                      <Accordion
                        style={{
                          boxShadow: "#0000003d 0px 0px 6px 0px",
                          marginBottom: "10px",
                          borderRadius: "11px",
                          border: "1px solid #1c47669c",
                        }}
                        expanded={expanded === key}
                        onChange={() => handleChange(key)}
                      >
                        <AccordionSummary
                          style={{
                            background: "#1c4766",
                            color: "#fff",
                            borderRadius: "10px",
                          }}
                          aria-controls="panel1d-content"
                          id={key}
                          // panel1d-header
                        >
                          <Typography>
                            {e.userName + " ( " + e.date + " )"}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <p>Next Follow Date : {e.next_f_date}</p>
                            <p>Comment : {e.comment}</p>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </>
              </TabPanel>
              <TabPanel value="3">
                <Timeline position="right">
                  {leadHistory?.activitylog?.map((e) => {
                    return (
                      <TimelineItem>
                        <TimelineOppositeContent
                          color="text.secondary"
                          style={{
                            textAlign: "left",
                            width: "150px",
                            maxWidth: "150px",
                          }}
                        >
                          {e.date}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot color="primary" />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent style={{ wordBreak: "break-word" }}>
                          <h4>{e.userName}</h4>
                          <p>{e.l_a_description}</p>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
                </Timeline>
              </TabPanel>
            </div>
          </TabContext>
        </Box>
      </Modal>
      <Modal
        open={followUpModal}
        onClose={cancelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={followupstyle}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h4"
            component="h2"
            style={{ borderBottom: "2px solid #000" }}
          >
            Follow Up
          </Typography>
          <div style={{ padding: "10px" }}>
            <div style={{ maxHeight: "320px" }}>
              <Stack spacing={10} style={{ marginBottom: "15px" }}>
                <Card style={{ padding: "25px", paddingBottom: "25px" }}>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Stack spacing={3}>
                          {/* <label style={{ marginBottom: '10px' }}>Join Date</label> */}
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            style={{ marginTop: "15px" }}
                          >
                            <DatePicker
                              type="date"
                              name="next_f_date "
                              label="Next FollowUp date"
                              inputFormat="dd/MM/yyyy"
                              value={moment(
                                followUpData.next_f_date,
                                "DD-MM-YYYY"
                              )}
                              onChange={(newDate) => {
                                setFollowUpData({
                                  ...followUpData,
                                  next_f_date: moment(
                                    newDate,
                                    "DD-MM-YYYY"
                                  ).format("DD-MM-YYYY"),
                                });
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={3} style={{ marginBottom: "15px" }}>
                          <TextField
                            style={{ marginTop: "0px" }}
                            fullWidth
                            autoComplete="off"
                            type="text"
                            label="Comment"
                            name="comment"
                            value={followUpData?.comment}
                            onChange={(e) => {
                              setFollowUpData({
                                ...followUpData,
                                comment: e.target.value,
                              });
                            }}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                </Card>
              </Stack>
            </div>
            <hr />
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setFollowUpModal(false);
                }}
              >
                Close
              </Button>
              <Button variant="contained" onClick={submitfollowup}>
                Confirm
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={leadtransModal}
        // onClose={cancelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={followupstyle}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h4"
            component="h2"
            style={{ borderBottom: "2px solid #000" }}
          >
            Lead Transfer
          </Typography>
          <div style={{ padding: "10px" }}>
            <div style={{ maxHeight: "320px" }}>
              <Stack spacing={10} style={{ marginBottom: "15px" }}>
                {/* <Card style={{ padding: "25px", paddingBottom: "25px" }}> */}
                <Stack spacing={10} style={{ marginBottom: "15px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <label style={{ marginBottom: "10px" }}>
                          Transer To
                        </label>
                        <Select
                          value={
                            allocatelist &&
                            allocatelist
                              .map((p) => {
                                if (p.u_id == leadtransData.l_transfer_to) {
                                  return { value: p.u_id, label: p.u_name };
                                }
                              })
                              .filter((n) => n)[0]
                          }
                          style={{ marginTop: "0px" }}
                          name="l_transfer_to"
                          onChange={(e) => {
                            setLeadTransData({
                              ...leadtransData,
                              l_transfer_to: e.value,
                            });
                          }}
                          options={
                            allocatelist != null &&
                            allocatelist.map((p) => {
                              return { value: p.u_id, label: p.u_name };
                            })
                          }
                        ></Select>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                {/* </Card> */}
              </Stack>
            </div>
            <hr />
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setLeadTransModal(false);
                }}
              >
                Close
              </Button>
              <Button variant="contained" onClick={submitleadtransfer}>
                Confirm
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={paymentimgModal}
        // onClose={cancelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={followupstyle}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h4"
            component="h2"
            style={{ borderBottom: "2px solid #000" }}
          >
            Payment Details
          </Typography>
          <div style={{ padding: "10px" }}>
            <div style={{ maxHeight: "320px" }}>
              <Stack
                spacing={10}
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                {/* <Card style={{ padding: "25px", paddingBottom: "25px" }}> */}
                <Stack spacing={10} style={{ marginBottom: "15px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Payment Note"
                          name="note"
                          // value={formdata?.t_title}
                          onChange={(e) => {
                            setpayimgData({
                              ...payimgData,
                              note: e.target.value,
                            });
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      {/* {fileimage != undefined && (
                        <img
                          src={fileimage}
                          alt="img"
                          width="100px"
                          height="80px"
                          style={{ margin: "5px" }}
                        />
                      )} */}
                      {/* <Stack spacing={3} style={{ marginBottom: "15px" }}> */}
                      <Button variant="contained" component="label">
                        Upload Payment ScreenShot
                        <input
                          id="file"
                          name="file"
                          type="file"
                          hidden
                          onChange={async (event) => {
                            setpayimgData({
                              ...payimgData,
                              img: event.currentTarget.files[0],
                            });
                            setfileimage(
                              URL.createObjectURL(event.currentTarget.files[0])
                            );
                          }}
                          className="form-control"
                        />
                      </Button>
                      {/* </Stack> */}
                    </Grid>
                  </Grid>
                </Stack>
                {/* </Card> */}
              </Stack>
            </div>
            <hr />
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setpaymentimgModal(false);
                  setfileimage();
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  paymentimg();
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={convertmemberModal}
        // onClose={cancelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h4"
            component="h2"
            style={{ borderBottom: "2px solid #000" }}
          >
            Lead Convert To Member
          </Typography>
          <div style={{ padding: "10px" }}>
            <div
              style={{
                maxHeight: "420px",
                overflow: "scroll",
                padding: "10px",
              }}
            >
              <Stack
                spacing={10}
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                {/* <Card style={{ padding: "25px", paddingBottom: "25px" }}> */}
                <Stack spacing={10} style={{ marginBottom: "15px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        {/* <label style={{ marginBottom: "10px" }}>
                          Select Chapter
                        </label>
                        <Select
                          value={
                            chaptlist &&
                            chaptlist.map((c) => {
                              if (c.chpt_id == memberdata.chpt_id) {
                                return {
                                  value: c.chpt_id,
                                  label: c.chpt_name,
                                };
                              }
                            })
                            // .filter((n) => n)[0]
                          }
                          style={{ marginTop: "0px" }}
                          name="chpt_id"
                          onChange={(e) => {
                            setnewmemberdata({
                              ...memberdata,
                              chpt_id: e.value,
                            });
                          }}
                          // onChange={handleChapterSelect}
                          options={
                            chaptlist &&
                            chaptlist?.map((chpt) => {
                              return {
                                value: chpt.chpt_id,
                                label: chpt.chpt_name,
                              };
                            })
                          }
                        ></Select> */}

                        {/*  */}
                        <ChapterSelectDropDown
                          dropDownLabel={"Select Chapter"}
                          selectedState={memberdata}
                          chapterKey="chpt_id"
                          // customMenuPortalTarget={false}
                          handleChange={(e) => {
                            setnewmemberdata({
                              ...memberdata,
                              chpt_id: e.value,
                            });
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <label style={{ marginBottom: "10px" }}>
                          Reference By
                        </label>
                        <Select
                          value={
                            memberlist == null
                              ? { value: 0, label: "Select Chapter" }
                              : memberlist
                                  .map((c) => {
                                    if (c.m_id == memberdata.invite_by) {
                                      return {
                                        value: c.m_id,
                                        label:
                                          c.m_name +
                                          " (" +
                                          c.business_name +
                                          ") [" +
                                          c.chpt_name +
                                          "]",
                                      };
                                    }
                                  })
                                  .filter((n) => n)[0]
                          }
                          style={{ marginTop: "0px" }}
                          name="invite_by"
                          onChange={(e) => {
                            setnewmemberdata({
                              ...memberdata,
                              invite_by: e.value,
                            });
                          }}
                          options={
                            memberlist != null &&
                            memberlist.map((chpt) => {
                              return {
                                value: chpt.m_id,
                                label:
                                  chpt.m_name +
                                  " (" +
                                  chpt.business_name +
                                  ") [" +
                                  chpt.chpt_name +
                                  "]",
                              };
                            })
                          }
                        ></Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <label style={{ marginBottom: "10px" }}>
                          Select State
                        </label>
                        <Select
                          value={
                            statelist == null
                              ? { value: 0, label: "Select State" }
                              : statelist
                                  .map((c) => {
                                    if (c.state_id == memberdata.state_id) {
                                      return {
                                        value: c.state_id,
                                        label: c.state_name,
                                      };
                                    }
                                  })
                                  .filter((n) => n)[0]
                          }
                          style={{ marginTop: "0px" }}
                          name="state_id"
                          onChange={async (e) => {
                            setnewmemberdata({
                              ...memberdata,
                              state_id: e.value,
                            });
                            setcitylist(await fetchCityList(e.value));
                          }}
                          options={
                            statelist != null &&
                            statelist.map(
                              (sta) => {
                                return {
                                  value: sta.state_id,
                                  label: sta.state_name,
                                };
                              }
                              // <MenuItem value={sta.state_id}>{sta.state_name}</MenuItem>
                            )
                          }
                        ></Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <label style={{ marginBottom: "10px" }}>
                          Select City
                        </label>
                        <Select
                          value={
                            citylist == null
                              ? { value: 0, label: "Select State" }
                              : citylist
                                  .map((c) => {
                                    if (c.city_id == memberdata.city_id) {
                                      return {
                                        value: c.city_id,
                                        label: c.city_name,
                                      };
                                    }
                                  })
                                  .filter((n) => n)[0]
                          }
                          style={{ marginTop: "0px" }}
                          name="city_id"
                          onChange={(e) => {
                            setnewmemberdata({
                              ...memberdata,
                              city_id: e.value,
                            });
                          }}
                          options={
                            citylist != null &&
                            citylist.map((c) => {
                              return { value: c.city_id, label: c.city_name };
                            })
                          }
                        ></Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack
                        spacing={3}
                        style={{ marginBottom: "15px", marginTop: "5px" }}
                      >
                        {/* <label style={{ marginBottom: '10px' }}>Join Date</label> */}
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          style={{ marginTop: "15px" }}
                        >
                          <DatePicker
                            type="date"
                            name="joindate"
                            label="Joining Date "
                            value={moment(
                              memberdata.joining_date,
                              "DD-MM-YYYY"
                            )}
                            inputFormat="dd/MM/yyyy"
                            onChange={(newDate) => {
                              setnewmemberdata({
                                ...memberdata,
                                joining_date: moment(
                                  newDate,
                                  "DD-MM-YYYY"
                                ).format("DD-MM-YYYY"),
                                due_date: moment(newDate, "DD-MM-YYYY")
                                  .add(1, "years")
                                  .format("DD-MM-YYYY"),
                              });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack
                        spacing={3}
                        style={{ marginBottom: "15px", marginTop: "5px" }}
                      >
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          style={{ marginTop: "15px" }}
                        >
                          <DatePicker
                            label="Expire Date "
                            name="expiredate"
                            value={moment(memberdata.due_date, "DD-MM-YYYY")}
                            inputFormat="dd/MM/yyyy"
                            onChange={(newDate) => {
                              setnewmemberdata({
                                ...memberdata,
                                due_date: moment(newDate, "DD-MM-YYYY").format(
                                  "DD-MM-YYYY"
                                ),
                              });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Member Name"
                          name="name"
                          value={memberdata?.name}
                          onChange={handlememberform}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="email"
                          label="Member Email Id"
                          name="l_email"
                          value={memberdata?.l_email}
                          onChange={handlememberform}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="number"
                          label="Member Contact No"
                          name="l_contact_no"
                          value={memberdata?.l_contact_no}
                          onChange={handlememberform}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Member Password"
                          name="password"
                          value={memberdata?.password}
                          onChange={handlememberform}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Business Name"
                          name="l_business_name"
                          value={memberdata?.l_business_name}
                          onChange={handlememberform}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Business Category"
                          name="l_business_category"
                          value={memberdata?.l_business_category}
                          onChange={handlememberform}
                        />
                      </Stack>
                    </Grid>

                    {/* <Grid item xs={12}>
                      <label style={{ marginBottom: "10px" }}>
                        {step == 3 ? "Upload New Image If You Want " : "Upload User Profile Image"}
                      </label>
                      <br />
                      <Stack direction="row" alignItems={"center"} spacing={2}>
                        <Button
                          variant="contained"
                          component="label"
                          sx={{ height: 40 }}
                          style={{ marginTop: "10px" }}
                        >
                          Click To Upload File
                          <input
                            id="file"
                            name="file"
                            type="file"
                            hidden
                            onChange={(event) => {
                              setnewmemberdata({
                                ...memberdata,
                                photo: event.currentTarget.files[0]
                              });
                            }}
                            className="form-control"
                          />
                        </Button>
                      </Stack>
                    </Grid> */}
                  </Grid>
                </Stack>
                {/* </Card> */}
              </Stack>
            </div>
            <hr />
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setIsLoading_leadConvertMember_Btn(false);
                  setConvertMemberModal(false);
                  setnewmemberdata({
                    joining_date: moment().format("DD-MM-YYYY"),
                    due_date: moment().add(1, "years").format("DD-MM-YYYY"),
                    type: 0,
                    invite_by: 0,
                  });
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                disabled={isLoading_leadConvertMember_Btn}
                onClick={submitMemberForm}
              >
                Confirm{"  "}
                {isLoading_leadConvertMember_Btn && (
                  <CircularProgress size={14} style={{ marginLeft: "5px" }} />
                )}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      {/* Member Renewal Modal */}
      <Modal
        open={memberrenewalmodal}
        // onClose={cancelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h4"
            component="h2"
            style={{ borderBottom: "2px solid #000" }}
          >
            Member Renewal
          </Typography>
          <div style={{ padding: "10px" }}>
            <div
              style={{
                maxHeight: "420px",
                overflow: "scroll",
                padding: "10px",
              }}
            >
              <Stack
                spacing={10}
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                {/* <Card style={{ padding: "25px", paddingBottom: "25px" }}> */}
                <Stack spacing={10} style={{ marginBottom: "15px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <ChapterSelectDropDown
                          dropDownLabel={"Select Chapter"}
                          selectedState={memberrenewaldata}
                          chapterKey="chpt_id"
                          makeDisableSelect={true}
                          // handleChange={(e) => {
                          //   setFilter({ chpt_id: e.value });
                          //   getmemberbychp(e.value);
                          // }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <label style={{ marginBottom: "10px" }}>
                          Reference By
                        </label>
                        <Select
                          isDisabled
                          value={
                            memberlist == null
                              ? { value: 0, label: "Reference By" }
                              : memberlist
                                  .map((c) => {
                                    if (
                                      c.m_id == memberrenewaldata.reference_m_id
                                    ) {
                                      return {
                                        value: c.m_id,
                                        label:
                                          c.m_name +
                                          " (" +
                                          c.business_name +
                                          ") [" +
                                          c.chpt_name +
                                          "]",
                                      };
                                    }
                                  })
                                  .filter((n) => n)[0]
                          }
                          style={{ marginTop: "0px" }}
                          name="invite_by"
                          options={
                            memberlist != null &&
                            memberlist.map((chpt) => {
                              return {
                                value: chpt.m_id,
                                label:
                                  chpt.m_name +
                                  " (" +
                                  chpt.business_name +
                                  ") [" +
                                  chpt.chpt_name +
                                  "]",
                              };
                            })
                          }
                        ></Select>
                      </Stack>
                    </Grid>

                    <Grid item xs={6}>
                      <Stack
                        spacing={3}
                        style={{ marginBottom: "15px", marginTop: "5px" }}
                      >
                        {/* <label style={{ marginBottom: '10px' }}>Join Date</label> */}
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          style={{ marginTop: "15px" }}
                        >
                          <DatePicker
                            disabled
                            type="date"
                            name="joindate"
                            label="Joining Date "
                            value={moment(
                              memberrenewaldata.joining_date,
                              "DD-MM-YYYY"
                            )}
                            inputFormat="dd/MM/yyyy"
                            // onChange={(newDate) => {
                            //   setnewmemberdata({
                            //     ...memberdata,
                            //     joining_date: moment(newDate, "DD-MM-YYYY").format("DD-MM-YYYY"),
                            //     due_date: moment(newDate, "DD-MM-YYYY")
                            //       .add(1, "years")
                            //       .format("DD-MM-YYYY")
                            //   });
                            // }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack
                        spacing={3}
                        style={{ marginBottom: "15px", marginTop: "5px" }}
                      >
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          style={{ marginTop: "15px" }}
                        >
                          <DatePicker
                            label="Expire Date "
                            name="expiredate"
                            value={moment(
                              memberrenewaldata.newdue_date,
                              "DD-MM-YYYY"
                            )}
                            inputFormat="dd/MM/yyyy"
                            onChange={(newDate) => {
                              setmemberrenewaldata({
                                ...memberrenewaldata,
                                newdue_date: moment(
                                  newDate,
                                  "DD-MM-YYYY"
                                ).format("DD-MM-YYYY"),
                              });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          disabled
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Member Name"
                          name="name"
                          value={memberrenewaldata?.m_name}
                          // onChange={handlememberform}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          disabled
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="email"
                          label="Member Email Id"
                          name="l_email"
                          value={memberrenewaldata?.m_email_id}
                          // onChange={handlememberform}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          disabled
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="number"
                          label="Member Contact No"
                          name="l_contact_no"
                          value={memberrenewaldata?.mobile_no}
                          // onChange={handlememberform}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          disabled
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Member Password"
                          name="password"
                          value={memberrenewaldata?.password}
                          // onChange={handlememberform}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          disabled
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Business Name"
                          name="l_business_name"
                          value={memberrenewaldata?.business_name}
                          // onChange={handlememberform}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          disabled
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Business Category"
                          name="l_business_category"
                          value={memberrenewaldata?.business_category}
                          // onChange={handlememberform}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                {/* </Card> */}
              </Stack>
            </div>
            <hr />
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setmemberrenewalmodal(false);
                  // setnewmemberdata({
                  //   joining_date: moment().format("DD-MM-YYYY"),
                  //   due_date: moment().add(1, "years").format("DD-MM-YYYY"),
                  //   type: 0,
                  //   invite_by: 0
                  // });
                }}
              >
                Close
              </Button>
              <Button variant="contained" onClick={submitmemberrenewal}>
                Confirm
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Lead Change Modal */}
      <Modal
        open={leadStatusChangeModal}
        onClose={closeLeadStatusChangeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={followupstyle}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h4"
            component="h2"
            style={{ borderBottom: "2px solid #000" }}
          >
            Update Lead Status
          </Typography>
          <div style={{ padding: "10px" }}>
            {statusList?.map((status) => {
              return (
                <div
                  value={status?.value}
                  style={{
                    position: "relative",
                    marginBottom: "10px",
                    border:
                      leadStatusChangeData?.from_l_phase == status?.value
                        ? "none"
                        : "2px solid #1c4766",
                    padding: "7px 15px",
                    borderRadius: "10px",
                    background:
                      leadStatusChangeData?.from_l_phase == status?.value
                        ? "#1c4766"
                        : "#fff",
                    color:
                      leadStatusChangeData?.from_l_phase == status?.value
                        ? "#fff"
                        : "#000",
                    cursor: "pointer",
                    WebkitUserSelect: "none", // Optional: Disable text selection on double-click
                    MozUserSelect: "none", // Optional: Disable text selection on double-click
                    msUserSelect: "none", // Optional: Disable text selection on double-click
                    userSelect: "none", // Optional: Disable text selection on double-click
                  }}
                  onClick={async (e) => {
                    const divValue = e.currentTarget.getAttribute("value");
                    await leadStatusChange(
                      leadStatusChangeData.lead_id,
                      divValue,
                      leadStatusChangeData.from_l_phase
                    );

                    // if (divValue == leadStatusChangeData.from_l_phase) {

                    //   // Display Error
                    //   let notify = notification({
                    //     type: "error",
                    //     message: "Lead Status Not Updated",
                    //   });
                    //   notify();
                    // } else {
                    //   if (
                    //     ((leadStatusChangeData.from_l_phase == 4 &&
                    //       ((loginUser.p_user_id != 1 &&
                    //         loginUser.u_type == 2) ||
                    //         (loginUser.p_user_id == 1 &&
                    //           loginUser.u_type == 1) ||
                    //         (loginUser.p_user_id == 1 &&
                    //           loginUser.u_type == 2 &&
                    //           loginUser.management_p == 0 &&
                    //           loginUser.account_p == 0))) ||
                    //       ((leadStatusChangeData.from_l_phase == 5 ||
                    //         leadStatusChangeData.from_l_phase == 6) &&
                    //         loginUser.u_type == 2 &&
                    //         loginUser.management_p == 0 &&
                    //         loginUser.account_p == 0)) &&
                    //     loginUser.u_type != 0
                    //   ) {
                    //     let notify = notification({
                    //       type: "error",
                    //       message: "You don't have Permission ",
                    //     });
                    //     notify();
                    //   } else if (
                    //     ((divValue == 5 &&
                    //       ((loginUser.p_user_id != 1 &&
                    //         loginUser.u_type == 2) ||
                    //         (loginUser.p_user_id == 1 &&
                    //           loginUser.u_type == 1) ||
                    //         (loginUser.p_user_id == 1 &&
                    //           loginUser.u_type == 2 &&
                    //           loginUser.management_p == 0 &&
                    //           loginUser.account_p == 0))) ||
                    //       (divValue == 6 &&
                    //         loginUser.u_type == 2 &&
                    //         loginUser.management_p == 0)) &&
                    //     loginUser.u_type != 0
                    //   ) {
                    //     let notify = notification({
                    //       type: "error",
                    //       message: "You don't have Permission ",
                    //     });
                    //     notify();
                    //   } else if (
                    //     leadStatusChangeData.from_l_phase == 6 &&
                    //     (loginUser.u_type == 0 ||
                    //       loginUser.u_type == 1 ||
                    //       (loginUser.u_type == 2 &&
                    //         loginUser.management_p == 1))
                    //   ) {
                    //   } else {
                    //     // update the Status
                    //     let lfdata = new FormData();
                    //     lfdata.append("lead_id", leadStatusChangeData.lead_id);
                    //     lfdata.append("l_phase", divValue);
                    //     lfdata.append("u_id", leadStatusChangeData.u_id);
                    //     lfdata.append(
                    //       "from_l_phase",
                    //       leadStatusChangeData.from_l_phase
                    //     );
                    //     const resp = await updateleadstatus(lfdata);
                    //     let notify = notification({
                    //       type: resp.status == 0 ? "error" : "success",
                    //       message: resp.message,
                    //     });
                    //     notify();
                    //     if (resp.status == 1) {
                    //       closeLeadStatusChangeModal();

                    //       // New Lead API CALL
                    //       setNewLan(initNewLan);
                    //       await getAllPhaseLeads();
                    //       // await getleadlist();
                    //     }
                    //   }
                    // }
                  }}
                >
                  <a id="">{status.label}</a>
                  {leadStatusChangeData?.from_l_phase == status?.value && (
                    <Check
                      color="#fff"
                      style={{ position: "absolute", right: "10" }}
                    />
                  )}
                </div>
              );
            })}
            <hr />

            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button variant="outlined" onClick={closeLeadStatusChangeModal}>
                Close
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Page title="Lead | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="h4" gutterBottom>
              Lead
            </Typography>
          </Stack>
          {step == 1 ? (
            <>
              <Button
                onClick={() => {
                  setStep(0);
                  setformdata({ l_created_by: loginUser.u_id, l_type: 0 });
                }}
                style={{ marginBottom: "15px" }}
                variant="contained"
              >
                Back
              </Button>
              <Card
                style={{
                  padding: "25px",
                  paddingBottom: "25px",
                  marginBottom: "25px",
                }}
              >
                Add New Lead
                <Stack spacing={10} style={{ marginBottom: "15px" }}>
                  <Grid container spacing={2}>
                    {step != 2 && (
                      <>
                        <Grid item xs={6}>
                          <Stack spacing={10} style={{ marginBottom: "15px" }}>
                            <label style={{ marginBottom: "10px" }}>
                              Allocate To
                            </label>
                            <Select
                              value={
                                allocatelist &&
                                allocatelist
                                  .map((p) => {
                                    if (p.u_id == formdata.l_allocated_to) {
                                      return { value: p.u_id, label: p.u_name };
                                    }
                                  })
                                  .filter((n) => n)[0]
                              }
                              style={{ marginTop: "0px" }}
                              name="l_allocated_to"
                              onChange={(e) => {
                                setformdata({
                                  ...formdata,
                                  l_allocated_to: e.value,
                                });
                              }}
                              options={
                                allocatelist != null &&
                                allocatelist.map((p) => {
                                  return { value: p.u_id, label: p.u_name };
                                })
                              }
                            ></Select>
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack spacing={10} style={{ marginBottom: "15px" }}>
                            <label style={{ marginBottom: "10px" }}>
                              Status
                            </label>
                            <Select
                              value={
                                statusList &&
                                statusList
                                  .map((p) => {
                                    if (p.value == formdata.l_phase) {
                                      return { value: p.value, label: p.label };
                                    }
                                  })
                                  .filter((n) => n)[0]
                              }
                              style={{ marginTop: "0px" }}
                              name="l_phase"
                              onChange={(e) => {
                                setformdata({ ...formdata, l_phase: e.value });
                              }}
                              options={statusList}
                            ></Select>
                          </Stack>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Name"
                          name="name"
                          value={formdata?.name}
                          onChange={onChangeFunction}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="email"
                          label="Email"
                          name="l_email"
                          value={formdata?.l_email}
                          onChange={onChangeFunction}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Contact No"
                          name="l_contact_no"
                          value={formdata?.l_contact_no}
                          onChange={onChangeFunction}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Address"
                          name="l_address"
                          value={formdata?.l_address}
                          onChange={onChangeFunction}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Business Name"
                          name="l_business_name"
                          value={formdata?.l_business_name}
                          onChange={onChangeFunction}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Business Category"
                          name="l_business_category"
                          value={formdata?.l_business_category}
                          onChange={onChangeFunction}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="GST No"
                          name="gst_no"
                          value={formdata?.gst_no}
                          onChange={onChangeFunction}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          style={{ marginTop: "15px" }}
                        >
                          <DatePicker
                            type="date"
                            name="date"
                            label="Date"
                            inputFormat="dd/MM/yyyy"
                            value={moment(formdata.date, "DD-MM-YYYY")}
                            onChange={(newDate) => {
                              setformdata({
                                ...formdata,
                                date: moment(newDate, "DD-MM-YYYY").format(
                                  "DD-MM-YYYY"
                                ),
                              });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                <Button
                  style={{ marginTop: "15px" }}
                  variant="contained"
                  onClick={submitformdata}
                >
                  Save
                </Button>
              </Card>
            </>
          ) : step == 2 ? (
            <>
              <Button
                onClick={() => {
                  setStep(0);
                  setformdata({ l_created_by: loginUser.u_id, l_type: 0 });
                }}
                style={{ marginBottom: "15px" }}
                variant="contained"
              >
                Back
              </Button>
              <Card className="p-20">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={activeTab}
                    variant="fullWidth"
                    allowScrollButtonsMobile
                    onChange={
                      (e, value) => {
                        // checkValidation(value);
                        setActiveTab(value);
                      }
                      // setMemberDetailData([]),
                      // setActiveTab(value),
                      // getMembersDetailReport(value, activeRow.m_id)
                    }
                  >
                    <Tab
                      className="mb-0 pb-0"
                      disableRipple
                      key={1}
                      value={1}
                      label="Personal Details"
                      icon={<Person />}
                      iconPosition="start"
                    />
                    <Tab
                      className="mb-0 pb-0"
                      disableRipple
                      key={2}
                      value={2}
                      label="Business Details"
                      icon={<Business />}
                      iconPosition="start"
                    />
                  </Tabs>
                </Box>
                <Grid container spacing={2} className="mt-10">
                  {activeTab == 1 ? (
                    <>
                      <Grid item xs={6} className="mb-15">
                        <Stack spacing={10}>
                          <TextField
                            style={{ marginTop: "0px" }}
                            fullWidth
                            required
                            autoComplete="off"
                            type="text"
                            label="Full Name"
                            name="name"
                            value={formdata?.name}
                            onChange={onChangeFunction}
                            autoCorrect="off"
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={6} className="mb-15">
                        <Stack spacing={10}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              type="date"
                              name="dob"
                              label="Date of Birth"
                              value={
                                formdata?.dob != undefined
                                  ? moment(formdata.dob, "DD-MM-YYYY")
                                  : null
                              }
                              disableFuture
                              inputFormat="dd/MM/yyyy"
                              onChange={(newDate) => {
                                setformdata({
                                  ...formdata,
                                  dob: moment(newDate, "DD-MM-YYYY").format(
                                    "DD-MM-YYYY"
                                  ),
                                });
                              }}
                              renderInput={(params) => (
                                <TextField {...params} required />
                              )}
                            />
                          </LocalizationProvider>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <TextField
                            fullWidth
                            required
                            autoComplete="off"
                            type="text"
                            label="Contact Number"
                            name="l_contact_no"
                            value={formdata?.l_contact_no}
                            onChange={onChangeFunction}
                            autoCorrect="off"
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <TextField
                            fullWidth
                            autoComplete="off"
                            type="text"
                            label="Alternate Contact Number"
                            name="alternate_contact_number"
                            value={formdata?.alternate_contact_number}
                            onChange={onChangeFunction}
                            autoCorrect="off"
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <FormControl>
                            <FormLabel
                              id="demo-error-radios"
                              className="font-bold"
                            >
                              Please Select Insurance you have
                              <span
                                aria-hidden="true"
                                className="MuiInputLabel-asterisk MuiFormLabel-asterisk css-1szckaa-MuiFormLabel-asterisk"
                              >
                                *
                              </span>
                            </FormLabel>
                            <FormGroup
                              name="insuranceType"
                              // value={formdata?.insuranceType}
                              // onChange={(e) => {
                              //   setformdata({
                              //     ...formdata,
                              //     insuranceType: e.target.value,
                              //     otherInsurance: "",
                              //   });
                              // }}
                            >
                              {/* <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Health Insurance"
                              />
                              <FormControlLabel
                                value={2}
                                control={<Radio />}
                                label="Life Insurance"
                              />
                              <FormControlLabel
                                value={3}
                                control={<Radio />}
                                label="Term Insurance"
                              />
                              <FormControlLabel
                                value={4}
                                control={<Radio />}
                                label="None"
                              />
                              <FormControlLabel
                                value={0}
                                control={<Radio />}
                                label="Other"
                              /> */}
                              <FormControlLabel
                                onChange={(e) =>
                                  handleInsuranceCheckBoxEvent(e)
                                }
                                value={1}
                                control={
                                  <Checkbox
                                    checked={formdata?.insuranceType?.includes(
                                      "1"
                                    )}
                                    name="Health_Insurance"
                                  />
                                }
                                label="Health Insurance"
                              />
                              <FormControlLabel
                                onChange={(e) =>
                                  handleInsuranceCheckBoxEvent(e)
                                }
                                value={2}
                                control={
                                  <Checkbox
                                    name="Life_Insurance"
                                    checked={formdata?.insuranceType?.includes(
                                      "2"
                                    )}
                                  />
                                }
                                label="Life Insurance"
                              />
                              <FormControlLabel
                                onChange={(e) =>
                                  handleInsuranceCheckBoxEvent(e)
                                }
                                value={3}
                                control={
                                  <Checkbox
                                    name="Term_Insurance"
                                    checked={formdata?.insuranceType?.includes(
                                      "3"
                                    )}
                                  />
                                }
                                label="Term Insurance"
                              />
                              <FormControlLabel
                                onChange={(e) =>
                                  handleInsuranceCheckBoxEvent(e)
                                }
                                value={4}
                                control={
                                  <Checkbox
                                    name="None"
                                    checked={formdata?.insuranceType?.includes(
                                      "4"
                                    )}
                                  />
                                }
                                label="None"
                              />
                              <FormControlLabel
                                value={0}
                                onChange={(e) =>
                                  handleInsuranceCheckBoxEvent(e)
                                }
                                control={
                                  <Checkbox
                                    name="Other"
                                    checked={formdata?.insuranceType?.includes(
                                      "0"
                                    )}
                                  />
                                }
                                label="Other"
                              />

                              {formdata?.insuranceType?.includes("0") && (
                                <TextField
                                  // label="Other Option"
                                  variant="standard"
                                  fullWidth
                                  autoComplete="off"
                                  autoCorrect="off"
                                  name="otherInsurance"
                                  placeholder="Other Insurance"
                                  value={formdata?.otherInsurance}
                                  onChange={onChangeFunction}
                                />
                              )}
                            </FormGroup>
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <FormControl variant="standard">
                            <FormLabel
                              id="demo-error-radios"
                              className="label-bold"
                            >
                              Select Aadhaar Card Front & Back Photo{" "}
                              <span
                                aria-hidden="true"
                                className="MuiInputLabel-asterisk MuiFormLabel-asterisk css-1szckaa-MuiFormLabel-asterisk"
                              >
                                *
                              </span>
                            </FormLabel>
                            <div>
                              <label className="file-label" htmlFor="file">
                                <Label
                                  variant="filled"
                                  color={"primary"}
                                  className="select-file-label"
                                >
                                  <div className="select-file-label-text">
                                    Select File
                                    <AddPhotoAlternate className="select-file-label-icon" />
                                  </div>
                                </Label>
                                <input
                                  id="file"
                                  type="file"
                                  hidden
                                  name="aadhaar"
                                  accept=".pdf,.doc,.docx,.jpeg,.jpg,.png,.gif,.bmp"
                                  onChange={(e) => {
                                    handleFileChange(e, "aadhaar");
                                  }}

                                  // onBlur={checkValidation}
                                />
                              </label>
                              <div>
                                <>
                                  {formdata?.aadhaar && (
                                    <div className="upload-file-name">
                                      {formdata?.aadhaar?.name ||
                                        formdata?.aadhaar}
                                    </div>
                                  )}

                                  {/* Input Helper */}
                                  <Label
                                    variant={"ghost"}
                                    color="error"
                                    className="warning-note"
                                  >
                                    More Then 10MB of File Are Not Supported
                                  </Label>

                                  <Label
                                    variant={"ghost"}
                                    color="error"
                                    className="warning-note"
                                  >
                                    Note: PDF, Doc & Images are Only Supported
                                  </Label>
                                </>
                              </div>
                            </div>
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <TextField
                            fullWidth
                            type="text"
                            label="Enter Facebook ID"
                            name="facebook_url"
                            value={formdata?.facebook_url}
                            onChange={onChangeFunction}
                            autoComplete="off"
                            autoCorrect="off"
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <TextField
                            fullWidth
                            type="text"
                            label="Enter Instagram ID"
                            name="instagram_url"
                            value={formdata?.instagram_url}
                            onChange={onChangeFunction}
                            autoComplete="off"
                            autoCorrect="off"
                          />
                        </Stack>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <TextField
                            fullWidth
                            autoComplete="off"
                            autoCorrect="off"
                            type="text"
                            required
                            label="Company Name"
                            name="l_business_name"
                            value={formdata?.l_business_name}
                            onChange={onChangeFunction}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <TextField
                            fullWidth
                            required
                            autoComplete="off"
                            autoCorrect="off"
                            type="text"
                            label="Business Category"
                            name="l_business_category"
                            value={formdata?.l_business_category}
                            onChange={onChangeFunction}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <TextField
                            fullWidth
                            autoComplete="off"
                            autoCorrect="off"
                            type="text"
                            label="Business Description"
                            name="l_business_description"
                            value={formdata?.l_business_description}
                            onChange={onChangeFunction}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <TextField
                            fullWidth
                            autoComplete="off"
                            type="text"
                            label="GST No"
                            name="gst_no"
                            value={formdata?.gst_no}
                            onChange={onChangeFunction}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <TextField
                            fullWidth
                            autoComplete="off"
                            type="text"
                            required
                            label="Email ID"
                            name="l_email"
                            value={formdata?.l_email}
                            onChange={onChangeFunction}
                            autoCorrect="off"
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <TextField
                            fullWidth
                            autoComplete="off"
                            type="text"
                            label="Other Networking Platform"
                            name="other_group_name"
                            placeholder="Have you join any Other Networking Platform"
                            value={formdata?.other_group_name}
                            onChange={onChangeFunction}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <TextField
                            fullWidth
                            autoComplete="off"
                            type="text"
                            required
                            label="Office Address"
                            name="l_address"
                            value={formdata?.l_address}
                            onChange={onChangeFunction}
                            autoCorrect="off"
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <TextField
                            fullWidth
                            autoComplete="off"
                            type="text"
                            required
                            label="Home Address"
                            name="l_h_address"
                            value={formdata?.l_h_address}
                            onChange={onChangeFunction}
                            autoCorrect="off"
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              type="date"
                              name="payment_date"
                              label="Payment Date"
                              value={
                                formdata?.payment_date != undefined
                                  ? moment(formdata.payment_date, "DD-MM-YYYY")
                                  : null
                              }
                              inputFormat="dd/MM/yyyy"
                              onChange={(newDate) => {
                                setformdata({
                                  ...formdata,
                                  payment_date: moment(
                                    newDate,
                                    "DD-MM-YYYY"
                                  ).format("DD-MM-YYYY"),
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // disabled
                                  {...params}
                                  required
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <FormControl>
                            <FormLabel
                              id="demo-error-radios"
                              className="font-bold"
                            >
                              Please Select Payment Method{" "}
                              <span
                                aria-hidden="true"
                                className="MuiInputLabel-asterisk MuiFormLabel-asterisk css-1szckaa-MuiFormLabel-asterisk"
                              >
                                *
                              </span>
                            </FormLabel>

                            <RadioGroup
                              row // display as Row
                              name="payment_mode"
                              value={formdata?.payment_mode}
                              required
                              onChange={(e) => {
                                setformdata({
                                  ...formdata,
                                  payment_mode: e.target.value,
                                });
                              }}
                            >
                              <FormControlLabel
                                value={0}
                                control={<Radio />}
                                label="Cash"
                              />
                              <FormControlLabel
                                value={1}
                                control={<Radio />}
                                label="Transfer"
                              />
                              <FormControlLabel
                                value={2}
                                control={<Radio />}
                                label="Cheque"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <FormControl variant="standard">
                            <FormLabel
                              id="demo-error-radios"
                              className="font-bold"
                            >
                              Upload Professional Photo{" "}
                              <span
                                aria-hidden="true"
                                class="MuiInputLabel-asterisk MuiFormLabel-asterisk css-1szckaa-MuiFormLabel-asterisk"
                              >
                                *
                              </span>
                            </FormLabel>
                            <div>
                              <label
                                className="file-label"
                                htmlFor="l_professional_photo"
                              >
                                <Label
                                  variant="filled"
                                  color={"primary"}
                                  className="select-file-label"
                                >
                                  <div className="select-file-label-text">
                                    Select File
                                    <AddPhotoAlternate className="select-file-label-icon" />
                                  </div>
                                </Label>
                                <input
                                  id="l_professional_photo"
                                  type="file"
                                  hidden
                                  name="l_professional_photo"
                                  accept=".jpeg,.jpg,.png,.gif"
                                  onChange={(e) =>
                                    handleFileChange(e, "l_professional_photo")
                                  }
                                />
                              </label>
                              <div>
                                <>
                                  {formdata?.l_professional_photo && (
                                    <div
                                      variant={"ghost"}
                                      color="success"
                                      className="upload-file-name"
                                    >
                                      {formdata?.l_professional_photo?.name ||
                                        formdata?.l_professional_photo}
                                    </div>
                                  )}
                                  <Label
                                    variant={"ghost"}
                                    color="error"
                                    className="warning-note"
                                  >
                                    More Then 10MB of File Are Not Supported
                                  </Label>
                                  <Label
                                    variant={"ghost"}
                                    color="error"
                                    className="warning-note"
                                  >
                                    Note: Only Image Supported
                                  </Label>
                                </>
                              </div>
                            </div>
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <FormControl variant="standard">
                            <FormLabel
                              id="demo-error-radios"
                              className="font-bold"
                            >
                              Upload Your Product Photo{" "}
                              <span
                                aria-hidden="true"
                                class="MuiInputLabel-asterisk MuiFormLabel-asterisk css-1szckaa-MuiFormLabel-asterisk"
                              >
                                *
                              </span>
                            </FormLabel>
                            <div>
                              <label
                                htmlFor="product_image"
                                className="file-label"
                              >
                                <Label
                                  variant="filled"
                                  color={"primary"}
                                  className="select-file-label"
                                >
                                  <div className="select-file-label-text">
                                    Select File Product
                                    <AddPhotoAlternate className="select-file-label-icon" />
                                  </div>
                                </Label>
                                <input
                                  id="product_image"
                                  type="file"
                                  hidden
                                  name="l_product_photo"
                                  accept=".jpeg,.jpg,.png,.gif,.bmp"
                                  onChange={(e) => {
                                    handleFileChange(e, "l_product_photo");
                                  }}
                                />
                              </label>
                              <div>
                                <>
                                  {formdata?.l_product_photo && (
                                    <div
                                      variant={"ghost"}
                                      color="success"
                                      className="upload-file-name"
                                    >
                                      Productphoto.png
                                      {formdata?.l_product_photo?.name ||
                                        formdata?.l_product_photo}
                                    </div>
                                  )}

                                  {/* <div
                                  style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FormHelperText>
                                    {errors.l_product_photo}
                                  </FormHelperText>
                                </div> */}

                                  <Label
                                    variant={"ghost"}
                                    color="error"
                                    className="warning-note"
                                  >
                                    More Then 10MB of File Are Not Supported
                                  </Label>
                                  <Label
                                    variant={"ghost"}
                                    color="error"
                                    className="warning-note"
                                  >
                                    Note: Only Image Supported
                                  </Label>
                                </>
                              </div>
                            </div>
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <TextField
                            fullWidth
                            required
                            autoComplete="off"
                            type="text"
                            label="Reference By (1)"
                            name="l_reference_1"
                            value={formdata?.l_reference_1}
                            onChange={onChangeFunction}
                            autoCorrect="off"
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={6} className="mb-15">
                        <Stack spacing={10}>
                          <TextField
                            fullWidth
                            required
                            autoComplete="off"
                            type="text"
                            label="Reference By (2)"
                            name="l_reference_2"
                            value={formdata?.l_reference_2}
                            onChange={onChangeFunction}
                            autoCorrect="off"
                          />
                        </Stack>
                      </Grid>
                    </>
                  )}
                </Grid>
                {/* Submit and next , back handle */}
                <div className="edit-lead-form-footer">
                  {activeTab == 1 && (
                    <Button
                      variant="contained"
                      className="my-auto mx-5"
                      // onClick={async (e) => {
                      //   // checkValidation();
                      // }}
                      onClick={() => setActiveTab(2)}
                    >
                      Next
                    </Button>
                  )}

                  {activeTab == 2 && (
                    <>
                      <Button
                        variant="outlined"
                        className="my-auto mx-5"
                        onClick={() => setActiveTab(1)}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        className="my-auto mx-5"
                        // onClick={async () => {
                        //   checkValidation(1);
                        //   checkValidation(2);
                        // }}
                        disabled={isLoading}
                        onClick={submitformdata}
                      >
                        Submit
                        {isLoading && (
                          <CircularProgress
                            size={14}
                            color="inherit"
                            sx={{ marginLeft: "10px" }}
                          />
                        )}
                      </Button>
                    </>
                  )}
                </div>
              </Card>
            </>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  onClick={() => {
                    setStep(1);
                  }}
                  style={{ marginBottom: "15px" }}
                  variant="contained"
                >
                  Add Lead
                </Button>
                {/* <Button
                  onClick={() => {
                    step == 0 ? setStep(3) : setStep(0);
                  }}
                  style={{ marginBottom: "15px" }}
                  variant="contained"
                >
                  {step == 0 ? "Table View" : "Card View"}
                </Button> */}
                <Button
                  className="btn btn-export"
                  style={{ marginBottom: "15px", marginTop: "0" }}
                  variant="contained"
                  onClick={exportFilteredLead}
                  disabled={loading}
                >
                  {loading ? "Exporting..." : "Export CSV"}
                </Button>
              </div>
              <Card className="p-20">
                {/* <Grid container spacing={2}> */}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Stack spacing={3} style={{ marginBottom: "15px" }}>
                      <TextField
                        fullWidth
                        value={filterlead?.filterl}
                        autoComplete="off"
                        type="text"
                        label="Search Lead"
                        name="search"
                        onChange={(e) => {
                          setfilterlead({
                            ...filterlead,
                            filterl: e.target.value,
                          });
                        }}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={3}>
                    <Stack spacing={3} style={{ marginBottom: "15px" }}>
                      {/* <label style={{ marginBottom: '10px' }}>Join Date</label> */}
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        style={{ marginTop: "15px" }}
                      >
                        <DatePicker
                          type="date"
                          name="s_date"
                          label="Start Date "
                          inputFormat="dd/MM/yyyy"
                          value={
                            filterlead.s_date != null
                              ? moment(filterlead.s_date, "DD-MM-YYYY")
                              : null
                          }
                          onChange={(newDate) => {
                            setfilterlead({
                              ...filterlead,
                              s_date: moment(newDate, "DD-MM-YYYY").format(
                                "DD-MM-YYYY"
                              ),
                            });
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack spacing={3} style={{ marginBottom: "15px" }}>
                      {/* <label style={{ marginBottom: '10px' }}>Join Date</label> */}
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        style={{ marginTop: "15px" }}
                      >
                        <DatePicker
                          type="date"
                          name="e_date"
                          label="End Date "
                          inputFormat="dd/MM/yyyy"
                          value={
                            filterlead.e_date != null
                              ? moment(filterlead.e_date, "DD-MM-YYYY")
                              : null
                          }
                          // onChange={setnewformdata}
                          onChange={(newDate) => {
                            setfilterlead({
                              ...filterlead,
                              e_date: moment(newDate, "DD-MM-YYYY").format(
                                "DD-MM-YYYY"
                              ),
                            });
                            // setSrdate({
                            //   ...srDate,
                            //   e_date: moment(newDate, "DD-MM-YYYY").format("DD-MM-YYYY")
                            // });
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack spacing={3} style={{ marginBottom: "15px" }}>
                      <label>Select User</label>
                      <Select
                        value={filterOption?.map((st) => {
                          if (st.value == filterlead.filteruser) {
                            return { value: st.value, label: st.label };
                          }
                        })}
                        menuPortalTarget={document.body}
                        onChange={(e) => {
                          setfilterlead({
                            ...filterlead,
                            filteruser: e.value,
                          });
                        }}
                        options={filterOption}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack spacing={3} style={{ marginBottom: "15px" }}>
                      <label>Select Lead Status</label>
                      <Select
                        //filter.lead_status
                        value={
                          filterlead.lead_status == -1
                            ? { value: -1, label: "All Lead" }
                            : statusList?.map((st) => {
                                if (st.value == filterlead.lead_status) {
                                  return { value: st.value, label: st.label };
                                }
                              })
                        }
                        menuPortalTarget={document.body}
                        onChange={(e) => {
                          setfilterlead({
                            ...filterlead,
                            lead_status: e.value,
                          });
                        }}
                        options={[
                          { value: -1, label: "All Lead" },
                          ...statusList,
                        ]}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={3}>
                    <Stack spacing={3} style={{ marginBottom: "15px" }}>
                      <label>Select Lead Type</label>
                      <Select
                        value={SearchTypeOptions?.map((st) => {
                          if (st.value == filterlead.lead_type) {
                            return { value: st.value, label: st.label };
                          }
                        })}
                        menuPortalTarget={document.body}
                        onChange={(e) => {
                          setfilterlead({ ...filterlead, lead_type: e.value });
                        }}
                        options={SearchTypeOptions}
                      />
                    </Stack>
                  </Grid>
                  {/* <Grid item xs={3}></Grid> */}
                  <Grid item xs={2}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        style={{ marginTop: "15px" }}
                        onClick={() => {
                          setLoadingMoreData(-1);
                          getFilterLeadData();
                        }}
                        variant="contained"
                      >
                        search
                      </Button>
                      <Button
                        style={{ marginTop: "15px" }}
                        onClick={async () => {
                          await setLoadingMoreData(-1);

                          await setfilterlead({
                            u_id: loginUser.u_id,
                            u_type: loginUser.u_type,
                            filterl: "",
                            s_date: null,
                            e_date: null,
                            filteruser: 0,
                            l_phase: -1,
                            lead_status: -1,
                            lead_type: -1,
                          });
                          await resetSearchForm({
                            u_id: loginUser.u_id,
                            u_type: loginUser.u_type,
                            filterl: "",

                            s_date: null,
                            e_date: null,
                            filteruser: 0,
                            l_phase: -1,
                            lead_status: -1,
                            lead_status: -1,
                            lead_type: -1,
                          });
                          // await setSrdate({ s_date: null, e_date: null });
                          // // await filterData();
                          // await filterData();
                        }}
                        variant="contained"
                      >
                        clear
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Card>
              {/* </Grid> */}
              {step == 0 && (
                <Board
                  className="taskList"
                  data={leadList}
                  style={{ position: "relative", marginTop: "15px" }}
                  components={{ Card: CustomCard }}
                  onCardClick={
                    async (cardId, metadata, phase) => {
                      setValue("1");
                      setMyModal({
                        mval: true,
                        lead_id: cardId,
                        l_phase: phase,
                      });
                      setLeadHistory(await featchleadhistory(cardId));
                      setExpanded(0);
                    }
                    // alert(`Card with id:${cardId} clicked. Has metadata.id: ${metadata.id}`)
                  }
                  handleDragEnd={(
                    cardId,
                    sourceLaneId,
                    targetLaneId,
                    position,
                    cardDetails
                  ) => {
                    leadStatusChange(cardId, targetLaneId, sourceLaneId);
                  }}
                  onLaneScroll={(requestedPage, laneId) =>
                    handleCustomLaneScroll(requestedPage, laneId)
                  }
                />
              )}
              {step == 3 && newleadData && (
                <>
                  {/* <Button
                    onClick={() => {
                      setStep(0);
                    }}
                    style={{ marginBottom: "15px" }}  
                    variant="contained"
                  >
                    Back
                  </Button> */}
                  <Button className="btn btn-export">
                    <CSVLink
                      data={newleadData}
                      headers={expo_columns}
                      filename={"LeadData.csv"}
                      className="btn btn-primary test"
                      target="_blank"
                    >
                      Export CSV
                    </CSVLink>
                  </Button>
                  <DataTable
                    title="Lead List"
                    filter
                    pagination
                    highlightOnHover
                    columns={columns}
                    data={newleadData}
                  />
                </>
              )}
            </>
          )}
        </Container>
      </Page>
    </>
  );
}
