import { filter } from "lodash";
import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Export from "react-data-table-component";
import Label from "../components/Label";
import Modal from "@mui/material/Modal";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Button,
  Box,
  Grid,
  TextField,
  Tooltip,
  IconButton,
  InputAdornment,
} from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import moment from "moment";
import Page from "src/components/Page";
import Select from "react-select";
import {
  fetchallcityamabassador,
  fetchCityList,
  fetchStateList,
  GetAllChapter,
} from "src/utils/common";
import {
  changeCityAmbassador,
  deleteCityAmbassador,
  insertNewcityambassador,
  paymentWithdrawal,
  updateCityAmbssadorDetails,
} from "src/api/commonapi";
import { notification } from "src/utils/messages";
import {
  AccountBalance,
  AccountBalanceWalletSharp,
  CurrencyRupee,
  DeleteSharp,
  EditSharp,
  PersonAddAlt,
  PersonAddAlt1Sharp,
} from "@mui/icons-material";

export default function CityAmbassador() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [citylist, setcitylist] = useState(null);
  const [statelist, setstatelist] = useState(null);
  const [formdata, setformdata] = useState({});
  const [payModal, setPayModal] = useState(false);
  const [payData, setPayData] = useState({ amount: 0, type: 1 });
  const [cityAmbassadorList, setCityAmbassadorList] = useState([]);
  const [fileimage, setfileimage] = useState();
  useEffect(async () => {
    await getstatelist();
    await getcityambassadorlist();
  }, []);

  const getstatelist = async () => {
    setstatelist(await fetchStateList());
  };

  const followupstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    padding: "10px",
  };

  const getcityambassadorlist = async () => {
    setCityAmbassadorList(await fetchallcityamabassador());
  };

  const deletecityambassador = async (u_id) => {
    let resp = await deleteCityAmbassador(u_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      await getcityambassadorlist();
      await getstatelist();
    }
  };

  const handleChange = (e) => {
    setformdata({ ...formdata, [e.target.name]: e.target.value });
  };
  const handleSubmit = async () => {
    let resp;
    if (step == 2) {
      resp = await insertNewcityambassador(JSON.stringify(formdata));
    } else if (step == 3) {
      resp = await updateCityAmbssadorDetails(JSON.stringify(formdata));
    } else if (step == 4) {
      resp = await changeCityAmbassador(JSON.stringify(formdata));
    }
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      await getstatelist();
      await getcityambassadorlist();
      setStep(1);
      setformdata({});
    }
  };

  const submitPaymentData = async () => {
    let fdata = new FormData();
    Object.keys(payData)?.map((key) => {
      fdata.append(key, payData[key]);
    });
    if (
      payData.amount == undefined ||
      payData.amount == 0 ||
      payData.note == undefined ||
      payData.note == "" ||
      payData.file == undefined
    ) {
      let notify = notification({
        type: "error",
        message: "Please Fill Required Fields",
      });
      notify();
    } else {
      let resp = await paymentWithdrawal(fdata);
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();

      if (resp.status == 1) {
        await getcityambassadorlist();
        setPayData({ amount: 0, type: 1 });
        setPayModal(false);
        setfileimage();
      }
    }
  };
  const checkwallet = () => {
    let notify = notification({
      type: "error",
      message: "Our Payment Amount is greater then wallet balance",
    });
    notify();
  };

  const expo_columns = [
    { label: "No", key: "u_id" },
    { label: "City Name", key: "city_name" },
    { label: "Name", key: "name" },
    { label: "Contact No.", key: "mobile" },
    { label: "Email", key: "email" },
    { label: "Wallet", key: "wallet_balance" },
    { label: "Commission", key: "u_commission" },
    { label: "Bank Name", key: "b_name" },
    { label: "Account Holder Name", key: "b_account_h_name" },
    { label: "Account Account No", key: "b_account_no" },
    { label: "Account IFSC Code", key: "b_ifsc_code" },
    { label: "Branch Name", key: "b_branch_name" },
  ];

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row.u_id,
      width: "90px",
      sortable: true,
    },
    {
      name: "City",
      wrap: true,
      selector: (row) => row.city_name,
      width: "150px",
      sortable: true,
    },
    {
      name: "Name",
      wrap: true,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Contact No.",
      wrap: true,
      selector: (row) => row.mobile,
    },
    {
      name: "Email",
      wrap: true,
      selector: (row) => row.email,
    },
    {
      name: "Wallet",
      wrap: true,
      selector: (row) => "₹" + row.wallet_balance,
      width: "130px",
      sortable: true,
    },
    {
      name: "Commission",
      wrap: true,
      selector: (row) => row.u_commission + "%",
      width: "110px",
      sortable: true,
    },
    {
      name: "Action",
      wrap: true,
      width: "200px",
      cell: (row) => (
        <>
          <a
            onClick={async () => {
              setStep(3);
              setformdata(row);
              setcitylist(await fetchCityList(row.state_id));
            }}
          >
            <Tooltip title="Edit">
              <IconButton>
                <EditSharp color="warning" />
              </IconButton>
            </Tooltip>
          </a>
          <a
            onClick={() => {
              setPayModal(true);
              setPayData({
                ...payData,
                c_a_id: row.u_id,
                wallet_balance: row.wallet_balance,
              });
            }}
          >
            <Tooltip title="Pay Now">
              <IconButton>
                <CurrencyRupee color="info" />
              </IconButton>
            </Tooltip>
          </a>
          <a
            onClick={() => {
              navigate(`/wallethistory/${row.u_id}`, { replace: true });
            }}
          >
            <Tooltip title="wallet history">
              <IconButton>
                <AccountBalanceWalletSharp color="primary" />
              </IconButton>
            </Tooltip>
          </a>
          {row.wallet_balance == 0 && (
            <>
              {" "}
              <a
                onClick={async () => {
                  setStep(4);
                  setformdata({
                    u_id: row.u_id,
                    state_id: row.state_id,
                    city_id: row.city_id,
                  });
                  setcitylist(await fetchCityList(row.state_id));
                }}
              >
                <Tooltip title="New City Ambassador">
                  <IconButton>
                    <PersonAddAlt1Sharp color="error" />
                  </IconButton>
                </Tooltip>
              </a>
            </>
          )}
        </>
      ),
    },
  ];

  const rowPreExpanded = (row) => <p> {row.b_name}</p>;
  const ExpandableRow = ({ data }) => {
    return (
      <div style={{ padding: "5px 15px", backgroundColor: "#f3f3f39c" }}>
        <p>
          <b>Bank Name</b> : {data?.b_name}
        </p>
        <p>
          <b>Account Holder Name</b> : {data?.b_account_h_name}
        </p>
        <p>
          <b>Account Account No</b> : {data?.b_account_no}
        </p>
        <p>
          <b>Account IFSC Code</b> : {data?.b_ifsc_code}
        </p>
        <p>
          <b>Branch Name</b> : {data?.b_branch_name}
        </p>
      </div>
    );
  };
  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export</Button>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(cityAmbassadorList)} />,
    []
  );
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  function convertArrayOfObjectsToCSV(array) {
    let result;
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(cityAmbassadorList[0]);
    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;

      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
    });
  }
  return (
    <>
      <Modal
        open={payModal}
        // onClose={cancelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={followupstyle}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h4"
            component="h2"
            style={{ borderBottom: "2px solid #000" }}
          >
            Payment Now
          </Typography>
          <div style={{ padding: "10px" }}>
            <div style={{ maxHeight: "480px" }}>
              <Stack
                spacing={10}
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                <Stack spacing={10} style={{ marginBottom: "15px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                ₹
                              </InputAdornment>
                            ),
                          }}
                          label="Wallet Balance"
                          name="wallet_balance"
                          value={payData?.wallet_balance}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="number"
                          label="Payment Amount"
                          name="amount"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                ₹
                              </InputAdornment>
                            ),
                          }}
                          value={payData?.amount}
                          onKeyUp={(e) => {
                            if (
                              parseInt(e.target.value) >
                              parseInt(payData.wallet_balance)
                            ) {
                              checkwallet();
                            }
                          }}
                          onChange={(e) => {
                            setPayData({
                              ...payData,

                              amount: e.target.value != "" ? e.target.value : 0,
                              r_w_balance:
                                parseInt(payData?.wallet_balance) -
                                parseInt(
                                  e.target.value != "" ? e.target.value : 0
                                ),
                            });
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                ₹
                              </InputAdornment>
                            ),
                          }}
                          label="Remaining Wallet Balance"
                          name="r_w_balance"
                          value={
                            parseInt(payData?.wallet_balance) -
                            parseInt(payData?.amount)
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Payment Note"
                          name="note"
                          value={payData?.note}
                          onChange={(e) => {
                            setPayData({ ...payData, note: e.target.value });
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      {fileimage != undefined && (
                        <img
                          src={fileimage}
                          alt="img"
                          width="100px"
                          height="80px"
                          style={{ margin: "5px" }}
                        />
                      )}
                      <Button variant="contained" component="label">
                        Upload Payment ScreenShot
                        <input
                          id="file"
                          name="file"
                          type="file"
                          hidden
                          onChange={async (event) => {
                            setPayData({
                              ...payData,
                              file: event.currentTarget.files[0],
                            });
                            setfileimage(
                              URL.createObjectURL(event.currentTarget.files[0])
                            );
                          }}
                          className="form-control"
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </div>
            <hr />
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setPayModal(false);
                  setPayData({ amount: 0, type: 1 });
                  setfileimage();
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                disabled={
                  parseInt(payData.amount) > parseInt(payData.wallet_balance)
                    ? true
                    : false
                }
                onClick={submitPaymentData}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Page title="City Ambassador | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="h4" gutterBottom>
              City Ambassador
            </Typography>
          </Stack>
          {/* Form Start */}
          {step != 1 ? (
            <>
              <Button
                onClick={() => {
                  setStep(1);
                  setformdata({});
                }}
                style={{ marginBottom: "15px" }}
                variant="contained"
              >
                Back
              </Button>
              <Card
                style={{
                  paddingTop: "25px",
                  paddingBottom: "25px",
                  marginBottom: "25px",
                }}
              >
                <Container>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Stack spacing={10} style={{ marginBottom: "15px" }}>
                          <label style={{ marginBottom: "10px" }}>
                            Select State
                          </label>
                          <Select
                            isDisabled={step == 2 ? false : true}
                            value={
                              statelist == null
                                ? { value: 0, label: "Select State" }
                                : statelist
                                    .map((c) => {
                                      if (c.state_id == formdata.state_id) {
                                        return {
                                          value: c.state_id,
                                          label: c.state_name,
                                        };
                                      }
                                    })
                                    .filter((n) => n)[0]
                            }
                            style={{ marginTop: "0px" }}
                            name="state_id"
                            onChange={async (e) => {
                              setcitylist(await fetchCityList(e.value));
                            }}
                            options={
                              statelist != null &&
                              statelist.map((sta) => {
                                return {
                                  value: sta.state_id,
                                  label: sta.state_name,
                                };
                              })
                            }
                          ></Select>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack spacing={10} style={{ marginBottom: "15px" }}>
                          <label style={{ marginBottom: "10px" }}>
                            Select City
                          </label>
                          <Select
                            isDisabled={step == 2 ? false : true}
                            value={
                              citylist == null
                                ? { value: 0, label: "Select City" }
                                : citylist
                                    .map((c) => {
                                      if (c.city_id == formdata.city_id) {
                                        return {
                                          value: c.city_id,
                                          label: c.city_name,
                                        };
                                      }
                                    })
                                    .filter((n) => n)[0]
                            }
                            style={{ marginTop: "0px" }}
                            name="city_id"
                            onChange={(e) => {
                              setformdata({ ...formdata, city_id: e.value });
                            }}
                            options={
                              citylist != null &&
                              citylist.map((c) => {
                                return { value: c.city_id, label: c.city_name };
                              })
                            }
                          ></Select>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack spacing={3} style={{ marginBottom: "15px" }}>
                          <TextField
                            style={{ marginTop: "0px" }}
                            fullWidth
                            autoComplete="off"
                            type="text"
                            label="Name"
                            name="name"
                            value={formdata?.name}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack spacing={3} style={{ marginBottom: "15px" }}>
                          <TextField
                            style={{ marginTop: "0px" }}
                            fullWidth
                            autoComplete="off"
                            type="email"
                            label="Email Id"
                            name="email"
                            value={formdata?.email}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack spacing={3} style={{ marginBottom: "15px" }}>
                          <TextField
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                            style={{ marginTop: "0px" }}
                            fullWidth
                            autoComplete="off"
                            type="number"
                            label="Contact No"
                            name="mobile"
                            value={formdata?.mobile}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack spacing={3} style={{ marginBottom: "15px" }}>
                          <TextField
                            style={{ marginTop: "0px" }}
                            fullWidth
                            autoComplete="off"
                            type="text"
                            label="Password"
                            name="password"
                            value={formdata?.password}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack spacing={3} style={{ marginBottom: "15px" }}>
                          <TextField
                            style={{ marginTop: "0px" }}
                            fullWidth
                            autoComplete="off"
                            type="number"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                            label="Commission Percentage"
                            name="u_commission"
                            value={formdata?.u_commission}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>

                  <Button
                    style={{ marginTop: "15px" }}
                    onClick={handleSubmit}
                    variant="contained"
                  >
                    Save
                  </Button>
                </Container>
              </Card>
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  setStep(2);
                }}
                style={{ marginBottom: "15px" }}
                variant="contained"
              >
                Add City Ambassador
              </Button>
              <Card>
                {cityAmbassadorList && (
                  <>
                    <Button className="btn btn-export">
                      <CSVLink
                        data={cityAmbassadorList}
                        headers={expo_columns}
                        filename={"CityAmbassadorList.csv"}
                        className="btn btn-primary test"
                        target="_blank"
                      >
                        Export CSV
                      </CSVLink>
                    </Button>
                    <DataTable
                      filter
                      pagination
                      highlightOnHover
                      columns={columns}
                      data={cityAmbassadorList}
                      expandableRows
                      expandableRowsComponent={ExpandableRow}
                    />
                  </>
                )}
              </Card>
            </>
          )}
        </Container>
      </Page>
    </>
  );
}
