import { filter } from "lodash";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import { Card, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import {
  fetchBusinessList,
  fetchV2vList,
  fetchChaptList,
  fetchchapterbyuser,
  fetchallcitylist,
} from "src/utils/common";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
// components
import Page from "../components/Page";
import {
  deleteBusiness,
  deleteV2v,
  GetMemberByChptId,
} from "src/api/commonapi";
import {
  DeleteForever,
  DeleteOutline,
  DeleteSharp,
  EditSharp,
} from "@mui/icons-material";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";

export default function V2v() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });

  const [joindate, setjoinDate] = useState(null);
  const [chptdata, setchptdata] = useState([]);
  const [amount, setamount] = useState({});
  const [v2vdata, setv2vdata] = useState([]);
  const [chapoption, setchptoption] = useState([]);
  const [memberdata, setmemberdata] = useState([]);
  const [getdata, setgetdata] = useState({});
  const [step, setstep] = useState(0);
  const [filter, setFilter] = useState({
    city_id:
      loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 &&
        loginUser?.p_user_id != 1 &&
        loginUser?.management_p == 1)
        ? loginUser?.city_id
        : 0,
    chpt_id: 0,
  });

  const [selectedmember, setselectedmember] = useState({
    defaulselect: "",
    m_id: 0,
  });

  const getchptlist = async () => {
    setchptdata(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  };
  useEffect(
    (async) => {
      chptdata?.map((option) => {
        chapoption.push({ value: option.chpt_id, label: option.chpt_name });
      });
    },
    [chptdata]
  );

  const getmemberbychp = async (ch_id) => {
    setselectedmember({ defaulselect: "", m_id: 0 });
    setmemberdata(await GetMemberByChptId(ch_id));
  };
  const getv2vlist = async (ch_id, m_id) => {
    setv2vdata(await fetchV2vList(ch_id, m_id));
  };
  const deletev2v = async (v_id) => {
    const resp = await deleteV2v(v_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      getv2vlist(filter.chpt_id, selectedmember.m_id);
      setDvisible({ mval: false, id: 0 });
    }
  };
  useEffect(async () => {
    getchptlist();
  }, []);
  const expo_columns = [
    { label: "No", key: "v2v_id" },
    { label: "V2V By", key: "v_by_name" },
    { label: "V2V To", key: "v_to_name" },
    { label: "Date", key: "v2v_date" },
    { label: "Comment", key: "comment" },
  ];

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row.v2v_id,
      sortable: true,
    },
    {
      name: "V2V By",
      wrap: true,
      selector: (row) => row.v_by_name,
      sortable: true,
    },
    {
      name: "V2V To",
      wrap: true,
      selector: (row) => row.v_to_name,
      sortable: true,
    },
    {
      name: "Date",
      wrap: true,
      selector: (row) => row.v2v_date,
      sortable: true,
    },
    {
      name: "Comment ",
      wrap: true,
      selector: (row) => row.comment,
      sortable: true,
    },

    {
      name: "Action",
      sortable: true,
      wrap: true,
      selector: (row) => (
        <>
          <>
            <Tooltip title="Delete V2V">
              <IconButton
                onClick={() => {
                  setDvisible({ mval: true, id: row.v2v_id });
                }}
              >
                <DeleteSharp color="error" />
              </IconButton>
            </Tooltip>
          </>
        </>
      ),
    },
  ];

  const [citylist, setcitylist] = useState([]);

  const [chaptlist, setchaplist] = useState([]);

  const getallcitylist = async () => {
    setcitylist(await fetchallcitylist());
    setchaplist(
      await fetchchapterbyuser(loginUser?.city_id, loginUser?.u_type)
    );
  };
  useEffect(async () => {
    getallcitylist();
  }, []);
  return (
    <>
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={deletev2v}
      />
      <Page title="V2V | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="h4" gutterBottom>
              V2V List
            </Typography>
          </Stack>

          <Card className="p-20">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <label>Select City</label>
                <Select
                  menuPortalTarget={document.body}
                  isDisabled={
                    loginUser?.u_type == 1 ||
                    (loginUser?.u_type == 2 &&
                      loginUser?.p_user_id != 1 &&
                      loginUser?.management_p == 1)
                      ? true
                      : false
                  }
                  options={[
                    {
                      value: 0,
                      label: "All City",
                    },
                    ...citylist.map((city) => {
                      return {
                        label: city?.city_name,
                        value: city?.city_id,
                      };
                    }),
                  ]}
                  onChange={(e) => {
                    setFilter({ ...filter, city_id: e.value, chpt_id: 0 });
                  }}
                  value={
                    filter.city_id == 0
                      ? {
                          value: 0,
                          label: "All City",
                        }
                      : citylist.map((city) => {
                          return (
                            filter.city_id == city.city_id && {
                              value: city.city_id,
                              label: city.city_name,
                            }
                          );
                        })
                  }
                />
              </Grid>

              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>Select Chapter</label>
                  <Select
                    name="chpt_id"
                    menuPortalTarget={document.body}
                    onChange={(e) => {
                      setFilter({ ...filter, chpt_id: e.value });
                      getmemberbychp(e.value);
                    }}
                    options={[
                      ...(chaptlist &&
                        chaptlist
                          ?.map((chpt) => {
                            if (filter?.city_id != 0) {
                              if (filter?.city_id == chpt?.city_id) {
                                return {
                                  value: chpt.chpt_id,
                                  label: chpt.chpt_name,
                                };
                              }
                            } else {
                              return {
                                value: chpt.chpt_id,
                                label: chpt.chpt_name,
                              };
                            }
                          })
                          .filter((f) => f != undefined)),
                    ]}
                    value={
                      chaptlist &&
                      chaptlist.map((c) => {
                        if (c.chpt_id == filter.chpt_id) {
                          return {
                            value: c.chpt_id,
                            label: c.chpt_name,
                          };
                        }
                      })
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3}>
                  <label>Select Member</label>
                  <Select
                    menuPortalTarget={document.body}
                    value={selectedmember.defaulselect}
                    onChange={(e) => {
                      setselectedmember({ defaulselect: e, m_id: e.value });
                      getv2vlist(filter.chpt_id, e.value);
                    }}
                    options={
                      memberdata &&
                      memberdata.map((option) => {
                        return { value: option.m_id, label: option.m_name };
                      })
                    }
                  />
                </Stack>
              </Grid>
            </Grid>
          </Card>

          <Card className="p-20" sx={{ mt: 2 }}>
            {v2vdata && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={v2vdata}
                    headers={expo_columns}
                    filename={"V2Vdata.csv"}
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  style={{ overflow: "hidden" }}
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={v2vdata}
                />
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
