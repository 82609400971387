import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Stack,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import Page from "../components/Page";
import { Grid } from "@mui/material";
import { fetchAttendance } from "src/utils/common";
import Label from "src/components/Label";

export default function Attendace() {
  const location = useLocation();
  const navigate = useNavigate();
  const [presentlist, setpresentlist] = useState([]);
  const [absentlist, setabsentlist] = useState([]);
  const [visitorlist, setvisitorlist] = useState([]);
  let { m_id } = useParams();
  useEffect(async () => {
    const resp = await fetchAttendance(m_id);
    setpresentlist(resp.data.presentList);
    setabsentlist(resp.data.absentList);
    setvisitorlist(resp.data.visitorList);
  }, []);
  return (
    <Page title="Attendance | Local Vocal Business Group">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h4" gutterBottom>
            Attendance
          </Typography>
        </Stack>
        {location.state && (
          <h4> Meeting End By : {location.state?.state?.name}</h4>
        )}

        <Button
          variant="contained"
          onClick={() => {
            navigate("/meeting");
          }}
        >
          Back
        </Button>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card
              style={{
                marginBottom: "25px",
                marginTop: "25px",
              }}
            >
              <div
                style={{
                  padding: "25px",
                  background: "#1c4766",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <label
                  style={{
                    marginBottom: "0",
                    fontWeight: "bold",
                    color: "white",
                    fontSize: "20pVisitorx",
                  }}
                >
                  Present Members
                </label>
                <label
                  style={{
                    marginBottom: "0",
                    fontWeight: "bold",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  {presentlist != [] ? presentlist.length : 0}
                </label>
              </div>

              {presentlist &&
                presentlist?.map((k) => {
                  return (
                    <>
                      <h4
                        style={{
                          padding: "5px 25px",
                          borderBottom: "1px solid #e5e8eb",
                        }}
                      >
                        {k.m_name}
                      </h4>
                    </>
                  );
                })}
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card
              style={{
                marginBottom: "25px",
                marginTop: "25px",
              }}
            >
              <div
                style={{
                  padding: "25px",
                  background: "#1c4766",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <label
                  style={{
                    marginBottom: "0",
                    fontWeight: "bold",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  Absent Members
                </label>
                <label
                  style={{
                    marginBottom: "0",
                    fontWeight: "bold",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  {absentlist != [] ? absentlist.length : 0}
                </label>
              </div>

              {absentlist &&
                absentlist?.map((k) => {
                  return (
                    <>
                      <h4
                        style={{
                          padding: "5px 25px",
                          borderBottom: "1px solid #e5e8eb",
                        }}
                      >
                        {k.m_name}
                      </h4>
                    </>
                  );
                })}
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card
              style={{
                marginBottom: "25px",
                marginTop: "25px",
              }}
            >
              <div
                style={{
                  padding: "25px",
                  background: "#1c4766",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <label
                  style={{
                    marginBottom: "0",
                    fontWeight: "bold",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  Visitor
                </label>
                <label
                  style={{
                    marginBottom: "0",
                    fontWeight: "bold",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  {visitorlist != [] ? visitorlist.length : 0}
                </label>
              </div>

              {visitorlist &&
                visitorlist?.map((k) => {
                  return (
                    <div
                      style={{
                        padding: "5px 25px",
                        borderBottom: "1px solid #e5e8eb",
                      }}
                    >
                      <h4>
                        Visitor name :{" "}
                        <span style={{ fontWeight: "normal" }}>{k.m_name}</span>
                      </h4>
                      <h4>
                        Invited By :{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {k.visitor_invited_by}
                        </span>
                      </h4>

                      <h4>Type : {k.visitor_in_chapter_type}</h4>
                    </div>
                  );
                })}
            </Card>
          </Grid>
        </Grid>

        {/* End Category Form */}
      </Container>
    </Page>
  );
}
