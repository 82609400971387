import { filter } from "lodash";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { notification } from "src/utils/messages";
import DatePicker from "@mui/lab/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Button,
  Box,
  IconButton,
  Grid,
  Tooltip,
  TextField,
} from "@mui/material";

// components
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Modal from "@mui/material/Modal";

import Page from "../components/Page";
import { ChapterForm } from "../components/Form/Chapter";
import { GetChapterById } from "src/api/chapterSetting";
import {
  fetchallcitylist,
  fetchchapterbyuser,
  GetAllChapter,
} from "src/utils/common";
import {
  GetMemberByChptId,
  deleteChapter,
  UpdateMemberPost,
  chapterReActivation,
  getChapterActivityLog,
} from "src/api/commonapi";
import {
  DeleteSharp,
  CalendarMonth,
  EditSharp,
  PersonAddAlt1,
  AssignmentInd,
  Visibility,
  VisibilityRounded,
} from "@mui/icons-material";

import { insertmeeting } from "src/api/commonapi";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import Label from "src/components/Label";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";

//-----------------------------------------------------------------------------------

export default function Chapter() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });
  const [step, setStep] = useState(1);
  const [chaptId, setChaptId] = useState(null);
  const [response, setResponse] = useState(0);
  const [dayIndex, setDayIndex] = useState(null);
  const [chapterlist, setchapterlist] = useState([]);
  const [memberlist, setmemberlist] = useState(null);
  const [myModal, setMyModal] = useState(false);
  const [editChapter, setEditChapterData] = useState(null);
  const [meetingDay, setMeetingDay] = useState(null);
  const [gaurdModal, setgaurdModal] = useState({ mval: false, chaptId: null });
  const [meetinglist, setMeetinglist] = useState(null);
  const [meeting_start_date, setMeeting_start_date] = useState(null);
  const [postData, setPostData] = useState([]);
  const [filterchapter, setfilterchapter] = useState("");
  const [filter, setFilter] = useState({
    city_id:
      loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 &&
        loginUser?.p_user_id != 1 &&
        loginUser?.management_p == 1)
        ? loginUser?.city_id
        : 0,
    chpt_name: "",
  });
  const [newchaplist, setnewchptlist] = useState([]);

  let datelist = [];

  const getallchapter = async () => {
    setchapterlist(
      await fetchchapterbyuser(loginUser.city_id, loginUser.u_type)
    );
  };

  useEffect(async () => {
    getallchapter();
  }, [response]);
  const getchapt = async (id) => {
    const response = await GetChapterById(id);
    if (response.status == 1) setEditChapterData(response.data);
    setStep(3);
  };
  let day;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 4,
  };

  const modalBodyStyle = {
    bgcolor: "background.red",
  };

  const [citylist, setcitylist] = useState([]);

  const getallcitylist = async () => {
    setcitylist(await fetchallcitylist());
  };
  useEffect(async () => {
    getallcitylist();
  }, []);

  const filterData = () => {
    let nchapter = [];
    nchapter =
      chapterlist &&
      chapterlist.filter((chpt) => {
        if (
          (filter.city_id == chpt.city_id || filter.city_id == 0) &&
          (chpt.chpt_name
            .toLowerCase()
            .includes(filter.chpt_name?.toLowerCase()) ||
            filter.chpt_name == "")
        ) {
          return chpt;
        }
      });

    setnewchptlist(nchapter);
  };

  useEffect(async () => {
    if (filter.city_id != undefined || filter.chpt_name != undefined) {
      await filterData();
    }
  }, [filter, chapterlist]);

  const getMeetingDate = (dayIndex, chapterId) => {
    setChaptId(chapterId);
    const today = new Date();
    let i = 0;
    today.setDate(
      today.getDate() + ((dayIndex - 1 - today.getDay() + 7) % 7) + 1
    );

    const d = String(today.getDate()).padStart(2, "0");
    const m = String(today.getMonth() + 1).padStart(2, "0");
    const y = today.getFullYear();

    datelist.push(`${d}-${m}-${y}`);
    for (; i < 14; i += 1) {
      today.setDate(today.getDate() + 14);
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();

      datelist.push(`${dd}-${mm}-${yyyy}`);
    }
    setMeetinglist(datelist);
  };

  const setNewMeetingDate = (start_date = "") => {
    datelist = [];
    const today = start_date;
    if (today != "Invalid Date") {
      let i = 0;
      const d = String(start_date.getDate()).padStart(2, "0");
      const m = String(start_date.getMonth() + 1).padStart(2, "0");
      const y = start_date.getFullYear();

      datelist.push(`${d}-${m}-${y}`);
      for (; i < 14; i += 1) {
        today.setDate(today.getDate() + 14);
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        datelist.push(`${dd}-${mm}-${yyyy}`);
      }
      setMeetinglist(datelist);
    }
  };

  const deletechapter = async (chpt_id) => {
    const resp = await deleteChapter(chpt_id, loginUser?.u_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      getallchapter();
      setDvisible({ mval: false, id: 0 });
    }
  };

  const getmembrlist = async (chpt_id) => {
    const resp = await GetMemberByChptId(chpt_id);
    // Filtering members with status 1 -- active members only
    const filteredMembers = resp.filter((member) => member.status === 1);

    // Setting the filtered members to memberlist
    setmemberlist(filteredMembers);
    // setmemberlist(resp);
    let post = {};
    resp &&
      resp?.map((k) => {
        if (k.type == 1) {
          post = { ...post, pre_id: k.m_id };
        } else if (k.type == 2) {
          post = { ...post, vpre_id: k.m_id };
        } else if (k.type == 3) {
          post = { ...post, st_id: k.m_id };
        } else if (k.type == 4) {
          post = { ...post, lvh_id: k.m_id };
        }
      });
    setPostData(post);
  };

  const expo_columns = [
    { label: "No", key: "chpt_id" },
    { label: "City Name", key: "city_name" },
    { label: "Chapter Name", key: "chpt_name" },
    { label: "Meeting Day", key: "m_day" },
    { label: "Launch Director", key: "m_name" },
    { label: "Launch Date", key: "launch_date" },
    { label: "De-Active Date", key: "ch_deactive_date" },
  ];

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row.chpt_id,
      width: "50px",
    },
    {
      name: "City",
      wrap: true,
      selector: (row) => row.city_name,
      sortable: true,
      width: "100px",
    },
    {
      name: "Chapter Name",
      wrap: true,
      selector: (row) => row.chpt_name,
      sortable: true,
      width: "150px",
    },
    {
      name: "Meeting Day",
      wrap: true,
      selector: (row) => (
        <>
          {row.metting_day == 0 ? (
            <>Sunday</>
          ) : row.metting_day == 1 ? (
            <>Monday</>
          ) : row.metting_day == 2 ? (
            <>Tuesday</>
          ) : row.metting_day == 3 ? (
            <>Wednesday</>
          ) : row.metting_day == 4 ? (
            <>Thursday</>
          ) : row.metting_day == 5 ? (
            <>Friday</>
          ) : row.metting_day == 6 ? (
            <>Saturday</>
          ) : (
            ""
          )}
        </>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Director",
      wrap: true,
      selector: (row) => row.m_name,
      sortable: true,
    },
    {
      name: "Launch Date",
      wrap: true,
      selector: (row) => row.launch_date,
      sortable: true,
      width: "120px",
    },
    {
      name: "Status",
      wrap: true,
      selector: (row) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "5px auto",
          }}
        >
          <Label
            variant={"ghost"}
            color={row.ch_status == 0 ? "success" : "error"}
          >
            {row.ch_status == 0 ? "Active" : "Disabled"}
          </Label>
          {loginUser?.u_type != 2 && loginUser?.u_type != 1 && (
            <Button
              className="bnt-btn-primary "
              style={{ padding: "2px 10px", marginTop: "5px" }}
              color={row.ch_status == 0 ? "error" : "primary"}
              variant="contained"
              onClick={(e) => {
                setActiveChapterId(row?.chpt_id);
                row.ch_status == 0
                  ? setIsOpenDeActiveModal(true)
                  : setIsOpenReDeactivateModal(true);
              }}
            >
              {row.ch_status == 0 ? "De-Active" : " Active"}
            </Button>
          )}
        </div>
      ),
    },
    loginUser?.u_type != 2 &&
      loginUser?.u_type != 1 && {
        name: "De-Activate Date",
        wrap: true,
        selector: (row) => row.ch_deactive_date || "-",
        sortable: true,
        width: "120px",
      },
    {
      name: "Action",
      wrap: true,
      sortable: true,
      cell: (row) => (
        <>
          {/* <a
            onClick={() => {
              setDvisible({ mval: true, id: row.chpt_id });
            }}
          >
            <Tooltip title="Delete Chapter ">
              <IconButton>
                <DeleteSharp color="error" />
              </IconButton>
            </Tooltip>
          </a> */}
          <a
            tabIndex={0}
            role="button"
            data={row.metting_day}
            onClick={() => {
              getMeetingDate(row.metting_day, row.chpt_id);
              setMyModal(true);
            }}
          >
            <Tooltip title="Set Meeting Dates">
              <IconButton>
                <CalendarMonth color="info" />
              </IconButton>
            </Tooltip>
          </a>

          <a
            onClick={() => {
              getchapt(row.chpt_id);
            }}
          >
            <Tooltip title="Edit Chapter">
              <IconButton>
                <EditSharp color="warning" />
              </IconButton>
            </Tooltip>
          </a>
          <a
            onClick={async () => {
              setgaurdModal({ mval: true, chaptId: row.chpt_id });
              getmembrlist(row.chpt_id);
            }}
          >
            <Tooltip title="Add Guard">
              <IconButton>
                <AssignmentInd color="success" />
              </IconButton>
            </Tooltip>
          </a>

          {loginUser?.u_type != 2 && loginUser?.u_type != 1 && (
            <a
              onClick={() => {
                setActiveChapterId(row?.chpt_id);
                // setIsOpenChapterActionHistory(true);
                getChapterHistoryById(row?.chpt_id);
              }}
            >
              <Tooltip title="View History">
                <IconButton>
                  <VisibilityRounded color="primary" />
                </IconButton>
              </Tooltip>
            </a>
          )}
        </>
      ),
    },
  ];
  const setMeeting = async () => {
    const meetingdata = {
      c_id: chaptId,
      meeting: meetinglist,
    };

    const jsmeetingdata = JSON.stringify(meetingdata);
    const resp = await insertmeeting(jsmeetingdata);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
  };

  const personPost = async (chap_id) => {
    let fdata = new FormData();
    fdata.append("pre_id", postData.pre_id);
    fdata.append("vpre_id", postData.vpre_id);
    fdata.append("st_id", postData.st_id);
    fdata.append("lvh_id", postData.lvh_id);
    fdata.append("chpt_id", chap_id);

    const resp = await UpdateMemberPost(fdata);

    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      cancelModal();
    }
  };
  const cancelModal = () => {
    setMyModal(false);
    setgaurdModal({ mval: false, chaptId: null });
    setPostData([]);
  };
  const [activeChapterId, setActiveChapterId] = useState(0);
  const [isOpenDeActiveModal, setIsOpenDeActiveModal] = useState(false);
  const [isOpenReDeactivateModal, setIsOpenReDeactivateModal] = useState(false);

  const handleActiveChapter = async (chapterId, activeType) => {
    if (chapterId != 0) {
      let resp = await chapterReActivation({
        chapterId: chapterId,
        activeType: activeType,
        u_id: loginUser?.u_id,
      });
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      if (resp.status == 1) {
        getallchapter();
        setActiveChapterId(0);
        setIsOpenDeActiveModal(false);
        setIsOpenReDeactivateModal(false);
      }
    } else {
      let notify = notification({
        type: "error",
        message: "Something went wrong On Active Chapter",
      });
      notify();
    }
  };

  const [isOpenChapterActionHistory, setIsOpenChapterActionHistory] = useState(
    false
  );

  const [chapterActivityLogList, setChapterActivityLogList] = useState([]);

  // const Get Chapter History
  const getChapterHistoryById = async (chapterId) => {
    let resp = await getChapterActivityLog(chapterId);
    if (resp.status == 1) {
      setChapterActivityLogList(resp.data);
    } else {
      setChapterActivityLogList([]);
    }
    setIsOpenChapterActionHistory(true);
  };

  const RenderChapterTimeLine = () => {
    return (
      <>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {chapterActivityLogList &&
            chapterActivityLogList.map((item) => {
              return (
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="primary"></TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "12px", px: 2 }}>
                    <Typography variant="h6" component="span">
                      {item?.date}
                    </Typography>
                    <Typography>{item?.note}</Typography>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
        </Timeline>
      </>
    );
  };
  return (
    <>
      {/* Chapter De-Activate Modal */}

      <Modal
        open={isOpenDeActiveModal}
        onClose={() => {
          setActiveChapterId(0);
          setIsOpenDeActiveModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: "450px" }}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h4"
            component="h2"
          >
            Are Your Sure ?
          </Typography>
          <div
            className=""
            style={{ margin: "10px auto auto ", textAlign: "center" }}
          >
            Do you want to De-Active Chapter ?
          </div>
          <hr style={{ margin: "0 auto" }} />
          <div
            style={{
              margin: "10px auto",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setActiveChapterId(0);
                setIsOpenDeActiveModal(false);
              }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                deletechapter(activeChapterId);
                setIsOpenDeActiveModal(false);
                setActiveChapterId(0);
              }}
            >
              Confirm
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Chapter Re-Activate Modal */}
      <Modal
        open={isOpenReDeactivateModal}
        onClose={() => {
          setActiveChapterId(0);
          setIsOpenReDeactivateModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h4"
            component="h2"
          >
            Do you want to Active Chapter ?
          </Typography>
          <div style={{ padding: "10px 30px", textAlign: "center" }}>
            <p>
              Do you want to active Member With Existing Membership Or You Want
              to Extend the Membership of the Members ?
            </p>
          </div>
          <hr />
          <div
            style={{
              margin: "10px auto",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleActiveChapter(activeChapterId, 1)}
            >
              Yes, Extend Existing Membership
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleActiveChapter(activeChapterId, 0)}
            >
              No, Continue With Existing Membership
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Chapter Activity History */}

      <Modal
        open={isOpenChapterActionHistory}
        onClose={() => {
          setActiveChapterId(0);
          setIsOpenChapterActionHistory(false);
          setChapterActivityLogList([]);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h5"
            component="h3"
          >
            {console.log("chapterlist", chapterlist)}
            {chapterlist.map((chapter) => {
              if (chapter.chpt_id == activeChapterId) return chapter?.chpt_name;
            })}{" "}
            Chapter Activity
          </Typography>
          <hr style={{ marginBottom: "5px" }} />
          <div
            style={
              chapterActivityLogList.length != 0
                ? {
                    maxHeight: "400px",
                    minHeight: "400px",
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }
                : {
                    display: "flex",
                    maxHeight: "200px",
                    minHeight: "200px",
                    alignItems: "center",
                    justifyContent: "center",
                  }
            }
          >
            {chapterActivityLogList.length != 0 ? (
              <RenderChapterTimeLine />
            ) : (
              <div>
                <Typography
                  align="center"
                  id="modal-modal-title"
                  variant="h5"
                  component="h3"
                >
                  No Data Found
                </Typography>
              </div>
            )}
          </div>
        </Box>
      </Modal>

      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={deletechapter}
      />
      {/* Meetiing Modal */}
      <Modal
        open={myModal}
        onClose={cancelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ marginBottom: "20px" }}
          >
            Confirm Meeting Date
          </Typography>
          <div className="row">
            <div className="col-md-4">
              <h5>Select Meeting Start Date : </h5>
              <Stack
                spacing={3}
                style={{ marginTop: "15px", marginBottom: "15px" }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  style={{ marginTop: "15px" }}
                >
                  <DatePicker
                    type="date"
                    label="Select Meeting Start Date"
                    value={moment(meeting_start_date, "DD-MM-YYYYY").format(
                      "MM-DD-YYYY"
                    )}
                    toolbarFormat="dd-MM-yyyy"
                    inputFormat="dd-MM-yyyy"
                    onChange={(newDate) => {
                      setMeeting_start_date(
                        moment(newDate, "DD-MM-YYYY").format("DD-MM-YYYY")
                      );
                      setNewMeetingDate(moment(newDate, "DD-MM-YYYY")._i);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </div>
          </div>
          <div style={{ maxHeight: "280px" }}>
            <Grid
              container
              spacing={2}
              style={{
                padding: "20px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {meetinglist != null &&
                meetinglist.map((p) => (
                  <div
                    key={p}
                    item
                    style={{
                      margin: "10px",
                      boxShadow: "0 0 5px #000",
                      padding: "5px 10px",
                      borderRadius: "10px",
                    }}
                  >
                    {p}
                  </div>
                ))}
            </Grid>
          </div>
          <hr />
          <div
            style={{
              margin: "10px auto",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button variant="outlined" onClick={cancelModal}>
              Close
            </Button>
            <Button variant="contained" onClick={setMeeting}>
              Confirm Date
            </Button>
          </div>
        </Box>
      </Modal>
      {/* Guard Modal */}
      <Modal
        open={gaurdModal.mval}
        onClose={cancelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h4"
            component="h2"
            style={{ borderBottom: "2px solid #000" }}
          >
            Allocate Post
          </Typography>
          <div style={{ padding: "10px" }}>
            <div style={{ maxHeight: "320px" }}>
              <Stack spacing={10} style={{ marginBottom: "15px" }}>
                <label style={{ marginBottom: "10px", fontWeight: "bold" }}>
                  President
                </label>
                <Select
                  value={
                    memberlist == null
                      ? { value: 0, label: "Select Member" }
                      : memberlist
                          .map((c) => {
                            if (c.m_id == postData.pre_id && c.status == 1) {
                              return { value: c.m_id, label: c.m_name };
                            }
                          })
                          .filter((n) => n)[0]
                  }
                  onChange={(e) =>
                    setPostData({ ...postData, pre_id: e.value })
                  }
                  options={
                    memberlist == null
                      ? []
                      : [
                          { value: 0, label: "Select Member" },
                          ...memberlist.map((p) => {
                            if (p.status == 1) {
                              return { value: p.m_id, label: p.m_name };
                            }
                          }),
                        ]
                  }
                />
                <label
                  style={{
                    marginBottom: "10px",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Vice-President
                </label>
                <Select
                  value={
                    memberlist == null
                      ? { value: 0, label: "Select Member" }
                      : memberlist
                          .map((c) => {
                            if (c.m_id == postData.vpre_id) {
                              return { value: c.m_id, label: c.m_name };
                            }
                          })
                          .filter((n) => n)[0]
                  }
                  onChange={(e) =>
                    setPostData({ ...postData, vpre_id: e.value })
                  }
                  options={
                    memberlist == null
                      ? []
                      : [
                          { value: 0, label: "Select Member" },
                          ...memberlist.map((p) => {
                            return { value: p.m_id, label: p.m_name };
                          }),
                        ]
                  }
                />
                <label
                  style={{
                    marginBottom: "10px",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  ST
                </label>
                <Select
                  value={
                    memberlist == null
                      ? { value: 0, label: "Select Member" }
                      : memberlist
                          .map((c) => {
                            if (c.m_id == postData.st_id) {
                              return { value: c.m_id, label: c.m_name };
                            }
                          })
                          .filter((n) => n)[0]
                  }
                  onChange={(e) => setPostData({ ...postData, st_id: e.value })}
                  options={
                    memberlist == null
                      ? []
                      : [
                          { value: 0, label: "Select Member" },
                          ...memberlist.map((p) => {
                            return { value: p.m_id, label: p.m_name };
                          }),
                        ]
                  }
                />
                <label
                  style={{
                    marginBottom: "10px",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  LVH
                </label>
                <Select
                  value={
                    memberlist == null
                      ? { value: 0, label: "Select Member" }
                      : memberlist
                          .map((c) => {
                            if (c.m_id == postData.lvh_id) {
                              return { value: c.m_id, label: c.m_name };
                            }
                          })
                          .filter((n) => n)[0]
                  }
                  onChange={(e) =>
                    setPostData({ ...postData, lvh_id: e.value })
                  }
                  options={
                    memberlist == null
                      ? []
                      : [
                          { value: 0, label: "Select Member" },
                          ...memberlist.map((p) => {
                            return { value: p.m_id, label: p.m_name };
                          }),
                        ]
                  }
                />
              </Stack>
            </div>
            <hr />
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button variant="outlined" onClick={cancelModal}>
                Close
              </Button>
              <Button
                variant="contained"
                onClick={() => personPost(gaurdModal.chaptId)}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Page title="Chapter | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="h4" gutterBottom>
              Chapter
            </Typography>
          </Stack>
          <Button
            onClick={() => {
              step == 1 ? setStep(2) : setStep(1);
            }}
            style={{ marginBottom: "15px" }}
            variant="contained"
          >
            {step == 1 ? "Add Chapter" : "Back"}
          </Button>

          {step != 1 && (
            <Card
              style={{
                paddingTop: "25px",
                paddingBottom: "25px",
                marginBottom: "25px",
              }}
            >
              <ChapterForm
                data={editChapter}
                setStep={setStep}
                step={step}
                myFunction={getallchapter}
              />
            </Card>
          )}

          {step == 1 && (
            <>
              <Card style={{ padding: "25px" }}>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item xs={6}>
                    <Stack sx={{ marginBottom: "15px" }}>
                      <label>Select City</label>
                      <Select
                        menuPortalTarget={document.body}
                        isDisabled={
                          loginUser?.u_type == 1 ||
                          (loginUser?.u_type == 2 &&
                            loginUser?.p_user_id != 1 &&
                            loginUser?.management_p == 1)
                            ? true
                            : false
                        }
                        options={[
                          {
                            value: 0,
                            label: "All City",
                          },
                          ...citylist.map((city) => {
                            return {
                              label: city?.city_name,
                              value: city?.city_id,
                            };
                          }),
                        ]}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            city_id: e.value,
                            chpt_name: "",
                          });
                        }}
                        value={
                          filter.city_id == 0
                            ? {
                                value: 0,
                                label: "All City",
                              }
                            : citylist.map((city) => {
                                return (
                                  filter.city_id == city.city_id && {
                                    value: city.city_id,
                                    label: city.city_name,
                                  }
                                );
                              })
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={3} style={{ marginBottom: "15px" }}>
                      <TextField
                        style={{ marginTop: "5px" }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Search Chapter"
                        name="search"
                        value={filter.chpt_name}
                        onChange={(e) => {
                          setFilter({ ...filter, chpt_name: e.target.value });
                        }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                {chapterlist && (
                  <>
                    <Button className="btn btn-export">
                      <CSVLink
                        data={newchaplist}
                        headers={expo_columns}
                        filename={"ChapterList.csv"}
                        className="btn btn-primary test"
                        target="_blank"
                      >
                        Export CSV
                      </CSVLink>
                    </Button>
                    <DataTable
                      filter
                      pagination
                      highlightOnHover
                      columns={columns}
                      data={newchaplist}
                    />
                  </>
                )}
              </Card>
            </>
          )}
        </Container>
      </Page>
    </>
  );
}
