import { filter } from "lodash";
import { saveAs } from "file-saver";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Grid,
  TextField,
  Box,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  CircularProgress,
} from "@mui/material";
import Label from "../components/Label";
import { notification } from "src/utils/messages";
import Select from "react-select";
import Modal from "@mui/material/Modal";
import Page from "src/components/Page";
import {
  DeleteOutline,
  DeleteSharp,
  EditSharp,
  Image,
  KeyboardArrowRight,
  PlusOne,
} from "@mui/icons-material";
import {
  fetchallcitylist,
  fetchDocumentByFid,
  fetchFolderByCityId,
  fetchlvbdocument,
} from "src/utils/common";
import {
  deleteDocuement,
  deleteSubFolder,
  insertFolder,
  uploadLvbDocument,
} from "src/api/commonapi";
import { Routes } from "src/constant/api_url";
import Deletemodal from "./DeleteModal";
import General from "src/components/General";
export default function LvbDocuments() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 2,
  };
  const [progress, setProgress] = React.useState(10);
  const [step, setStep] = useState(0);
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });
  const [fvisible, setFvisible] = useState({ mval: false, id: 0 });
  const [uploadModal, setuploadModal] = useState(false);
  const [documentdata, setdocumentdata] = useState({
    d_type:
      loginUser?.u_type == 0 ||
      (loginUser.u_type == 2 && loginUser.p_user_id == 1)
        ? 0
        : 1,
    city_id: loginUser?.city_id,
    f_id: 0,
  });
  const [cityList, setClityList] = useState([]);
  const [folderdocument, setfolderdocument] = useState([]);
  const [filtercity, setfiltercity] = useState("");
  const [newcitylist, setnewcitylist] = useState([]);
  const [newfolderdocument, setnewfolderdocument] = useState([]);
  const [newsubfolder, setnewsubfolder] = useState([]);
  const [currFolder, setCurrFolder] = useState(null);
  const [creatFolderModal, setcreatFolderModal] = useState(false);
  const [newfolder, setnewfolder] = useState({
    city_id:
      loginUser?.u_type == 0 ||
      (loginUser.u_type == 2 && loginUser.p_user_id == 1)
        ? 0
        : loginUser.city_id,
    f_name: "",
  });
  const [subCurrentSubFolder, setsubCurrentSubFolder] = useState([]);
  const [optionfolderlist, setoptionfolderlist] = useState([]);
  const [subFileList, setSubFileList] = useState([]);
  const [newFileList, setNewFileList] = useState([]);

  useEffect(async () => {
    await folderList(
      loginUser?.u_type == 0 ||
        (loginUser.u_type == 2 && loginUser.p_user_id == 1)
        ? 0
        : loginUser.city_id
    );
  }, []);
  const folderList = async (id = 0) => {
    setoptionfolderlist(await fetchFolderByCityId(id));
  };
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(async () => {
    setClityList(await fetchallcitylist());
  }, []);
  const cancelModal = () => {
    setuploadModal(false);
  };
  const cancelFolderModal = () => {
    setcreatFolderModal(false);
  };
  const submitDocumentData = async () => {
    let fndata = new FormData();
    fndata.append("city_id", documentdata.city_id);
    if (documentdata.file != undefined) {
      for (const key of Object.keys(documentdata.file)) {
        fndata.append("file", documentdata.file[key]);
      }
    }
    // fndata.append("file", documentdata.file);
    fndata.append("f_id", documentdata.f_id);
    let resp = await uploadLvbDocument(fndata);
    if (resp.status == 1) {
      setdocumentdata({
        d_type:
          loginUser?.u_type == 0 ||
          (loginUser.u_type == 2 && loginUser.p_user_id == 1)
            ? 0
            : 1,
        city_id: loginUser?.city_id,
        f_id: 0,
      });
      setuploadModal(false);
    }
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
  };

  const getLvbDocument = async (id) => {
    setfolderdocument(await fetchlvbdocument(id));
    setStep(1);
  };
  const filterData = () => {
    let ncity = [];

    ncity =
      cityList &&
      cityList.filter((city) => {
        if (city.city_name.toLowerCase().includes(filtercity?.toLowerCase())) {
          return city;
        }
      });
    setnewcitylist(ncity);
  };

  const filterDataFolderData = () => {
    let ncity = [];
    let nsfolder = [];
    ncity =
      folderdocument?.fileList &&
      folderdocument?.fileList.filter((city) => {
        if (
          city.document_name.toLowerCase().includes(filtercity?.toLowerCase())
        ) {
          return city;
        }
      });
    nsfolder =
      folderdocument?.folderList &&
      folderdocument?.folderList.filter((city) => {
        if (city.f_name.toLowerCase().includes(filtercity?.toLowerCase())) {
          return city;
        }
      });
    setnewfolderdocument(ncity);
    setnewsubfolder(nsfolder);
  };

  useEffect(async () => {
    if (filtercity != undefined) {
      await filterData();
    }
  }, [filtercity, cityList]);

  useEffect(async () => {
    if (filtercity != undefined) {
      await filterDataFolderData();
    }
  }, [filtercity, folderdocument]);

  const filterSubFolderFile = () => {
    let file = [];

    file =
      subFileList &&
      subFileList.filter((city) => {
        if (
          city.document_name.toLowerCase().includes(filtercity?.toLowerCase())
        ) {
          return city;
        }
      });
    setNewFileList(file);
  };

  useEffect(async () => {
    if (filtercity != undefined) {
      await filterSubFolderFile();
    }
  }, [filtercity, subFileList]);

  const docuementDelete = async (d_id) => {
    let resp = await deleteDocuement(d_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      setfolderdocument(await fetchlvbdocument(currFolder));
      setDvisible({ mval: false, id: 0 });
      if (subCurrentSubFolder != null && subCurrentSubFolder != []) {
        getSubFile(subCurrentSubFolder?.f_id);
      }
    }
  };
  const folderDelete = async (f_id) => {
    let resp = await deleteSubFolder(f_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      setfolderdocument(await fetchlvbdocument(currFolder));
      setFvisible({ mval: false, id: 0 });
    }
  };

  const createFolder = async () => {
    let resp = await insertFolder(JSON.stringify(newfolder));
    if (resp.status == 1) {
      setnewfolder({
        ...newfolder,
        f_name: "",
      });

      setcreatFolderModal(false);
      setfolderdocument(await fetchlvbdocument(currFolder));
    }
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
  };

  const getSubFile = async (id) => {
    setSubFileList(await fetchDocumentByFid(id));
  };
  return (
    <>
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={docuementDelete}
      />
      <Deletemodal
        visible={fvisible}
        setVisible={setFvisible}
        deletefunc={folderDelete}
      />
      <Modal
        open={uploadModal}
        onClose={cancelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h4"
            component="h2"
            style={{ borderBottom: "2px solid #000" }}
          >
            Upload Document
          </Typography>
          <div style={{ padding: "10px" }}>
            <div style={{ maxHeight: "420px" }}>
              <Stack spacing={10} style={{ marginBottom: "15px" }}>
                <Stack spacing={10} style={{ marginBottom: "15px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <FormLabel
                          id="demo-row-radio-buttons-group-label"
                          style={{ marginBottom: "0px" }}
                        >
                          Document For
                        </FormLabel>
                        <RadioGroup
                          style={{ marginTop: "0px" }}
                          row
                          defaultValue={0}
                          value={documentdata.d_type}
                          onChange={(e) => {
                            setdocumentdata({
                              ...documentdata,
                              d_type: e.target.value,
                              city_id: 0,
                              f_id: 0,
                            });
                            if (e.target.value == 0) {
                              folderList(0);
                            } else {
                              setoptionfolderlist([]);
                            }
                          }}
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="d_type"
                        >
                          {(loginUser?.u_type == 0 ||
                            (loginUser.u_type == 2 &&
                              loginUser.p_user_id == 1)) && (
                            <>
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="General"
                              />
                            </>
                          )}
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="City"
                          />
                        </RadioGroup>
                      </Stack>
                    </Grid>

                    {documentdata?.d_type == 1 && (
                      <>
                        <Grid item xs={12}>
                          <Stack spacing={10} style={{ marginBottom: "15px" }}>
                            <label style={{ marginBottom: "10px" }}>
                              Select City
                            </label>
                            <Select
                              isDisabled={
                                loginUser.u_type == 0 ||
                                (loginUser.u_type == 2 &&
                                  loginUser.p_user_id == 1)
                                  ? false
                                  : true
                              }
                              value={
                                cityList &&
                                cityList
                                  .map((c) => {
                                    if (c.city_id == documentdata.city_id) {
                                      return {
                                        value: c.city_id,
                                        label: c.city_name,
                                      };
                                    }
                                  })
                                  .filter((n) => n)[0]
                              }
                              style={{ marginTop: "0px" }}
                              name="city_id"
                              onChange={(e) => {
                                setdocumentdata({
                                  ...documentdata,
                                  city_id: e.value,
                                });
                                folderList(e.value);
                              }}
                              options={
                                cityList &&
                                cityList?.map((e) => {
                                  return {
                                    value: e.city_id,
                                    label: e.city_name,
                                  };
                                })
                              }
                            ></Select>
                          </Stack>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <label style={{ marginBottom: "10px" }}>
                          Select Folder
                        </label>
                        <Select
                          value={
                            optionfolderlist &&
                            optionfolderlist
                              .map((c) => {
                                if (c.f_id == documentdata.f_id) {
                                  return { value: c.f_id, label: c.f_name };
                                }
                              })
                              .filter((n) => n)[0]
                          }
                          style={{ marginTop: "0px" }}
                          name="f_id"
                          onChange={(e) => {
                            setdocumentdata({ ...documentdata, f_id: e.value });
                          }}
                          options={
                            optionfolderlist &&
                            optionfolderlist?.map((e) => {
                              return { value: e.f_id, label: e.f_name };
                            })
                          }
                        ></Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" alignItems={"center"} spacing={2}>
                        <Button
                          variant="contained"
                          component="label"
                          sx={{ height: 40 }}
                          style={{ marginTop: "10px" }}
                        >
                          Click To Upload File
                          <input
                            id="file"
                            name="file"
                            type="file"
                            hidden
                            multiple
                            onChange={(event) => {
                              setdocumentdata({
                                ...documentdata,
                                file: event.currentTarget.files,
                              });
                            }}
                            className="form-control"
                          />
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </div>
            <hr />
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button variant="outlined" onClick={cancelModal}>
                Close
              </Button>
              <Button variant="contained" onClick={submitDocumentData}>
                Confirm
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={creatFolderModal}
        onClose={cancelFolderModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h4"
            component="h2"
            style={{ borderBottom: "2px solid #000" }}
          >
            Create Folder
          </Typography>

          <div style={{ padding: "10px" }}>
            <div style={{ maxHeight: "320px" }}>
              <Stack spacing={10} style={{ marginBottom: "15px" }}>
                <Stack spacing={10} style={{ marginBottom: "15px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "5px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Folder Name"
                          name="f_name"
                          onChange={(e) => {
                            setnewfolder({
                              ...newfolder,
                              f_name: e.target.value,
                            });
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </div>
            <hr />
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button variant="outlined" onClick={cancelFolderModal}>
                Close
              </Button>
              <Button variant="contained" onClick={createFolder}>
                Confirm
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Page title="LVB Documents | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="h4" gutterBottom>
              Documents
            </Typography>
          </Stack>

          <Grid
            container
            spacing={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={6}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={1}
              >
                <ul
                  className="cus-nav-item"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <li className={step == 0 ? "Active" : ""}>
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setStep(0);
                        setfolderdocument([]);
                        setnewfolder({
                          city_id: 0,
                          f_name: "",
                        });
                      }}
                    >
                      Home
                    </p>
                  </li>
                  {step != 0 && (
                    <li
                      className={step == 1 ? "Active" : ""}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <KeyboardArrowRight style={{ height: "20px" }} />
                      <p
                        onClick={() => {
                          setStep(1);
                          setsubCurrentSubFolder(null);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {cityList &&
                          cityList
                            .map((c) => {
                              if (currFolder == 0) {
                                return "General";
                              } else {
                                if (c.city_id == currFolder) {
                                  return c.city_name;
                                }
                              }
                            })
                            .filter((n) => n)[0]}
                      </p>
                    </li>
                  )}
                  {step == 2 && (
                    <li
                      className={step == 2 ? "Active" : ""}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <KeyboardArrowRight style={{ height: "20px" }} />
                      <p style={{ cursor: "pointer" }}>
                        {subCurrentSubFolder?.f_name}
                      </p>
                    </li>
                  )}
                </ul>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              {step == 0 &&
                (loginUser.u_type == 0 ||
                  (loginUser.u_type == 2 &&
                    loginUser.p_user_id == 1 &&
                    loginUser.management_p == 1) ||
                  (loginUser.u_type == 1 && loginUser.p_user_id == 1) ||
                  (loginUser.u_type == 2 &&
                    loginUser.p_user_id != 1 &&
                    loginUser.management_p == 1)) && (
                  <Button
                    onClick={() => {
                      setuploadModal(true);
                    }}
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginBottom: "15px",
                    }}
                    variant="contained"
                  >
                    Upload Documents
                  </Button>
                )}
              {step == 1 && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {loginUser?.u_type == 0 ||
                  (loginUser.u_type == 2 && loginUser.p_user_id == 1) ||
                  (loginUser.u_type == 1 && newfolder.city_id != 0) ||
                  (loginUser.u_type == 2 &&
                    newfolder.city_id != 0 &&
                    loginUser.management_p == 1) == true ? (
                    <Button
                      onClick={() => {
                        setcreatFolderModal(true);
                      }}
                      style={{ display: "block", marginBottom: "15px" }}
                      variant="contained"
                    >
                      Create Folder
                    </Button>
                  ) : (
                    ""
                  )}

                  <Button
                    onClick={() => {
                      setStep(0);
                      setfolderdocument([]);
                    }}
                    style={{
                      display: "block",
                      marginLeft: "15px",
                      marginBottom: "15px",
                    }}
                    variant="contained"
                  >
                    Back
                  </Button>
                </div>
              )}
              {step == 2 && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    onClick={() => {
                      setStep(1);
                      setsubCurrentSubFolder(null);
                    }}
                    style={{ display: "block", marginBottom: "15px" }}
                    variant="contained"
                  >
                    Back
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <TextField
                  style={{ marginTop: "5px" }}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Search"
                  name="search"
                  value={filtercity}
                  onChange={(e) => {
                    setfiltercity(e.target.value);
                  }}
                />
              </Stack>
            </Grid>
          </Grid>

          {step == 0 && (
            <Stack spacing={10} style={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={3} style={{ padding: "15px" }}>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      getLvbDocument(0);
                      setCurrFolder(0);
                      setnewfolder({
                        city_id: 0,
                        f_name: "",
                      });
                    }}
                  >
                    <Box
                      style={{ display: "block", margin: "auto" }}
                      component="img"
                      src="/static/general.png"
                    />
                    <p style={{ textAlign: "center" }}>General</p>
                  </div>
                </Grid>
                {newcitylist &&
                  newcitylist.map((city) => {
                    if (
                      loginUser.city_id == city.city_id &&
                      loginUser.u_type != 0
                    ) {
                      return (
                        <>
                          <Grid item xs={3} style={{ padding: "15px" }}>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                getLvbDocument(city?.city_id);
                                setCurrFolder(city?.city_id);
                                setnewfolder({
                                  city_id: city?.city_id,
                                  f_name: "",
                                });
                              }}
                            >
                              <Box
                                style={{ display: "block", margin: "auto" }}
                                component="img"
                                src="/static/folder.png"
                              />
                              <p style={{ textAlign: "center" }}>
                                {city.city_name}
                              </p>
                            </div>
                          </Grid>
                        </>
                      );
                    } else if (
                      loginUser.u_type == 0 ||
                      (loginUser.u_type == 2 && loginUser.p_user_id == 1)
                    ) {
                      return (
                        <>
                          <Grid item xs={3} style={{ padding: "15px" }}>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                getLvbDocument(city?.city_id);
                                setCurrFolder(city?.city_id);
                                setnewfolder({
                                  ...newfolder,
                                  city_id: city?.city_id,
                                });
                              }}
                            >
                              <Box
                                style={{ display: "block", margin: "auto" }}
                                component="img"
                                src="/static/folder.png"
                              />
                              <p style={{ textAlign: "center" }}>
                                {city.city_name}
                              </p>
                            </div>
                          </Grid>
                        </>
                      );
                    }
                  })}
              </Grid>
            </Stack>
          )}
          {step == 1 && (
            <>
              <Stack spacing={10} style={{ margin: "15px 0 " }}>
                <Grid container spacing={2}>
                  {newsubfolder?.length != 0 &&
                    newsubfolder?.map((folder) => {
                      return (
                        <>
                          <Grid item xs={2} style={{ padding: "15px" }}>
                            <div
                              className="fileGrig"
                              style={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                onClick={() => {
                                  setStep(2);
                                  setsubCurrentSubFolder({
                                    f_name: folder.f_name,
                                    f_id: folder.f_id,
                                  });
                                  getSubFile(folder?.f_id);
                                }}
                              >
                                <Box
                                  width="85px"
                                  style={{ display: "block", margin: "auto" }}
                                  component="img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    `/static/open-folder.png`
                                  }
                                />
                                <p
                                  style={{
                                    textAlign: "center",
                                    textDecoration: "none",
                                  }}
                                >
                                  {folder.f_name}
                                </p>
                              </div>

                              {(loginUser.u_type == 0 ||
                                (loginUser.u_type == 2 &&
                                  loginUser.p_user_id == 1 &&
                                  loginUser.management_p == 1)) && (
                                <Tooltip
                                  className="delete_btn"
                                  title="Delete File"
                                  onClick={() => {
                                    setFvisible({
                                      mval: true,
                                      id: folder.f_id,
                                    });
                                  }}
                                >
                                  <IconButton>
                                    <DeleteSharp color="error" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                          </Grid>
                        </>
                      );
                    })}
                  {newfolderdocument?.length != 0 &&
                    newfolderdocument?.map((folder) => {
                      let name = folder.document_name.split("_").pop();
                      let extension = folder.document_name.split(".").pop();
                      return (
                        <>
                          <Grid item xs={2} style={{ padding: "15px" }}>
                            <div
                              className="fileGrig"
                              style={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                onClick={() => {
                                  saveAs(
                                    `${Routes.API_HOSTNAME}images/lvbdocument/${folder.document_name}`,
                                    `${folder.document_name}`
                                  );
                                }}
                              >
                                <Box
                                  width="85px"
                                  style={{ display: "block", margin: "auto" }}
                                  component="img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    `/static/${
                                      extension == "pdf"
                                        ? "pdf.png"
                                        : extension == "docx" ||
                                          extension == "doc"
                                        ? "doc.png"
                                        : extension == "csv" ||
                                          extension == "xlsx"
                                        ? "sheets.png"
                                        : extension == "ppt" ||
                                          extension == "pptx"
                                        ? "ppt.png"
                                        : extension == "jpg" ||
                                          extension == "jpeg" ||
                                          extension == "png"
                                        ? "picture.png"
                                        : "file.png"
                                    }`
                                  }
                                />
                                <img
                                  style={{ display: "block", margin: "auto" }}
                                  width="85px"
                                  // src={
                                  //   process.env.PUBLIC_URL +
                                  //   `/static/Assets/images/${
                                  //     extension == "pdf"
                                  //       ? "pdf.png"
                                  //       : extension == "docx" || extension == "doc"
                                  //       ? "doc.png"
                                  //       : extension == "csv" || extension == "xlsx"
                                  //       ? "sheets.png"
                                  //       : extension == "ppt" || extension == "pptx"
                                  //       ? "ppt.png"
                                  //       : extension == "jpg" ||
                                  //         extension == "jpeg" ||
                                  //         extension == "png"
                                  //       ? "picture.png"
                                  //       : "file.png"
                                  //   }`
                                  // }
                                />
                              </div>
                              <p
                                style={{
                                  textAlign: "center",
                                  textDecoration: "none",
                                }}
                              >
                                {name}
                              </p>
                              {(loginUser.u_type == 0 ||
                                (loginUser.u_type == 2 &&
                                  loginUser.p_user_id == 1 &&
                                  loginUser.management_p == 1)) && (
                                <Tooltip
                                  className="delete_btn"
                                  title="Delete Folder"
                                  onClick={() => {
                                    setDvisible({
                                      mval: true,
                                      id: folder.d_id,
                                    });
                                    // docuementDelete(folder.d_id);
                                  }}
                                >
                                  <IconButton>
                                    <DeleteSharp color="error" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                          </Grid>
                        </>
                      );
                    })}
                </Grid>
              </Stack>
            </>
          )}

          {step == 2 && (
            <>
              <Stack spacing={10} style={{ margin: "15px 0 " }}>
                <Grid container spacing={2}>
                  {newFileList?.length != 0 ? (
                    newFileList?.map((folder) => {
                      let name = folder.document_name.split("_").pop();
                      let extension = folder.document_name.split(".").pop();
                      return (
                        <>
                          <Grid item xs={2} style={{ padding: "15px" }}>
                            <div
                              className="fileGrig"
                              style={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                onClick={() => {
                                  saveAs(
                                    `${Routes.API_HOSTNAME}images/lvbdocument/${folder.document_name}`,
                                    `${folder.document_name}`
                                  );
                                }}
                              >
                                <Box
                                  width="85px"
                                  style={{ display: "block", margin: "auto" }}
                                  component="img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    `/static/${
                                      extension == "pdf"
                                        ? "pdf.png"
                                        : extension == "docx" ||
                                          extension == "doc"
                                        ? "doc.png"
                                        : extension == "csv" ||
                                          extension == "xlsx"
                                        ? "sheets.png"
                                        : extension == "ppt" ||
                                          extension == "pptx"
                                        ? "ppt.png"
                                        : extension == "jpg" ||
                                          extension == "jpeg" ||
                                          extension == "png"
                                        ? "picture.png"
                                        : "file.png"
                                    }`
                                  }
                                />
                                <img
                                  style={{ display: "block", margin: "auto" }}
                                  width="85px"
                                  // src={
                                  //   process.env.PUBLIC_URL +
                                  //   `/static/Assets/images/${
                                  //     extension == "pdf"
                                  //       ? "pdf.png"
                                  //       : extension == "docx" || extension == "doc"
                                  //       ? "doc.png"
                                  //       : extension == "csv" || extension == "xlsx"
                                  //       ? "sheets.png"
                                  //       : extension == "ppt" || extension == "pptx"
                                  //       ? "ppt.png"
                                  //       : extension == "jpg" ||
                                  //         extension == "jpeg" ||
                                  //         extension == "png"
                                  //       ? "picture.png"
                                  //       : "file.png"
                                  //   }`
                                  // }
                                />
                              </div>
                              <p
                                style={{
                                  textAlign: "center",
                                  textDecoration: "none",
                                }}
                              >
                                {name}
                              </p>
                              {(loginUser.u_type == 0 ||
                                (loginUser.u_type == 2 &&
                                  loginUser.p_user_id == 1 &&
                                  loginUser.management_p == 1)) && (
                                <Tooltip
                                  className="delete_btn"
                                  title="Delete File"
                                  onClick={() => {
                                    setDvisible({
                                      mval: true,
                                      id: folder.d_id,
                                    });
                                  }}
                                >
                                  <IconButton>
                                    <DeleteSharp color="error" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                          </Grid>
                        </>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        marginTop: "15px",
                      }}
                    >
                      <h4 style={{ textAlign: "center" }}>No Data Found</h4>
                    </div>
                  )}
                  {/* {folderdocument?.folderList.length != 0 ? (
                    folderdocument?.folderList?.map((folder) => {
                      return (
                        <>
                          <Grid item xs={2} style={{ padding: "15px" }}>
                            <div
                              className="fileGrig"
                              style={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                cursor: "pointer"
                              }}
                            >
                              <div>
                                <Box
                                  width="85px"
                                  style={{ display: "block", margin: "auto" }}
                                  component="img"
                                  src={process.env.PUBLIC_URL + `/static/subfolder.png`}
                                />
                              </div>
                              <p style={{ textAlign: "center", textDecoration: "none" }}>
                                {folder.f_name}
                              </p>
                              {(loginUser.u_type == 0 ||
                                (loginUser.u_type == 2 &&
                                  loginUser.p_user_id == 1 &&
                                  loginUser.management_p == 1)) && (
                                <Tooltip
                                  className="delete_btn"
                                  title="Delete File"
                                  onClick={() => {
                                    setDvisible({ mval: true, id: folder.d_id });
                                    // docuementDelete(folder.d_id);
                                  }}
                                >
                                  <IconButton>
                                    <DeleteSharp color="error" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                          </Grid>
                        </>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        marginTop: "15px"
                      }}
                    >
                      <h4 style={{ textAlign: "center" }}>No Data Found</h4>
                    </div>
                  )} */}
                  {/* {newfolderdocument.length != 0 ? (
                    newfolderdocument?.map((folder) => {
                      let name = folder.document_name.split("_").pop();
                      let extension = folder.document_name.split(".").pop();
                      return (
                        <>
                          <Grid item xs={2} style={{ padding: "15px" }}>
                            <div
                              className="fileGrig"
                              style={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                cursor: "pointer"
                              }}
                            >
                              <div
                                // href={`${Routes.API_HOSTNAME}images/lvbdocument/${folder.document_name}`}
                                // target="_blank"
                                // download="true"
                                onClick={() => {
                                  saveAs(
                                    `${Routes.API_HOSTNAME}images/lvbdocument/${folder.document_name}`,
                                    `${folder.document_name}`
                                  );
                                }}
                              >
                                <Box
                                  width="85px"
                                  style={{ display: "block", margin: "auto" }}
                                  component="img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    `/static/${
                                      extension == "pdf"
                                        ? "pdf.png"
                                        : extension == "docx" || extension == "doc"
                                        ? "doc.png"
                                        : extension == "csv" || extension == "xlsx"
                                        ? "sheets.png"
                                        : extension == "ppt" || extension == "pptx"
                                        ? "ppt.png"
                                        : extension == "jpg" ||
                                          extension == "jpeg" ||
                                          extension == "png"
                                        ? "picture.png"
                                        : "file.png"
                                    }`
                                  }
                                />
                                <img
                                  style={{ display: "block", margin: "auto" }}
                                  width="85px"
                                  // src={
                                  //   process.env.PUBLIC_URL +
                                  //   `/static/Assets/images/${
                                  //     extension == "pdf"
                                  //       ? "pdf.png"
                                  //       : extension == "docx" || extension == "doc"
                                  //       ? "doc.png"
                                  //       : extension == "csv" || extension == "xlsx"
                                  //       ? "sheets.png"
                                  //       : extension == "ppt" || extension == "pptx"
                                  //       ? "ppt.png"
                                  //       : extension == "jpg" ||
                                  //         extension == "jpeg" ||
                                  //         extension == "png"
                                  //       ? "picture.png"
                                  //       : "file.png"
                                  //   }`
                                  // }
                                />
                              </div>
                              <p style={{ textAlign: "center", textDecoration: "none" }}>{name}</p>
                              {(loginUser.u_type == 0 ||
                                (loginUser.u_type == 2 &&
                                  loginUser.p_user_id == 1 &&
                                  loginUser.management_p == 1)) && (
                                <Tooltip
                                  className="delete_btn"
                                  title="Delete File"
                                  onClick={() => {
                                    setDvisible({ mval: true, id: folder.d_id });
                                    // docuementDelete(folder.d_id);
                                  }}
                                >
                                  <IconButton>
                                    <DeleteSharp color="error" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                          </Grid>
                        </>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        marginTop: "15px"
                      }}
                    >
                      <h4 style={{ textAlign: "center" }}>No Data Found</h4>
                    </div>
                  )} */}
                </Grid>
              </Stack>
            </>
          )}
        </Container>
      </Page>
    </>
  );
}
