import { Navigate, useNavigate, useRoutes } from "react-router-dom";

// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import Products from "./pages/Products";
import Blog from "./pages/Blog";
import User from "./pages/User";
import State from "./pages/State";
import City from "./pages/City";
import NotFound from "./pages/Page404";
import Country from "./pages/Country";
import Landmark from "./pages/Landmark";
import Event from "./pages/Event";
import Chapter from "./pages/Chapter";
import Member from "./pages/Member";
import Meeting from "./pages/Meeting";
import Setting from "./pages/Setting";
import Poll from "./pages/Poll";
import Gallery from "./pages/gallery";
import V2v from "./pages/v2v";
import V2vReport from "./pages/v2vReport";
import Business from "./pages/Business";
import Reference from "./pages/Reference";
import Attendace from "./pages/Attendance";
import Memberattendace from "./pages/Memberattendance";
import Visitor from "./pages/Visitor";
import Greeting from "./pages/Greeting";
import Staff from "./pages/staff";
import Task from "./pages/task";
import Lead from "./pages/lead";
import InquiryForm from "./pages/InquiryForm";
import CityAmbassador from "./pages/CityAmbassador";
import WalletHistory from "./pages/WalletHistory";
import LvbDocuments from "./pages/LvbDocuments";
import Bankdetail from "./pages/BankDetail";
import ChangePassword from "./pages/ChangePassword";
import Testimonial from "./pages/Testimonial";
import BusinessReport from "./pages/BusinessReport";
import ReferenceReport from "./pages/ReferenceReport";
import VisitorReport from "./pages/VisitorReport";
import TestimonialReport from "./pages/TestimonialReport";
import AttendaceReport from "./pages/AttendanceReport";
import LvbReport from "./pages/lvbreport";
import CityReport from "./pages/cityreport";
import CityChapterReport from "./pages/Citychapterreport";
import ChapterAttendanceReport from "./pages/Memberattendance";
import ExportReport from "./pages/ExportReport";
import GeneralReport from "./pages/ExportReport";
import ChapterV2vReport from "./pages/Chapterv2vreport";
import ChapterReport from "./pages/ChapterReport";
import LvbPinBadge from "./pages/LvbPinBadge";
import ChapterMemberReportAllInOne from "./pages/ChapterMemberReportAllInOne";
import VisitorBlock from "./pages/VisitorBlackList";
import LeadForm from "./pages/LeadForm";
import { Suggestion } from "./pages/Suggestion";
import MemberInductionReport from "./pages/MemberInductionReport";
import ThankyouNoteApproval from "./pages/WaitingBusinessApproval";
import PinAssignReport from "./pages/PinAssignReport";
// ----------------------------------------------------------------------
export function AuthRouter() {
  return useRoutes([
    {
      path: "/*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "*", element: <Login /> },
        { path: "inquiry-form/:c_id", element: <InquiryForm /> },
        { path: "inquiry-form", element: <InquiryForm /> },
        { path: "lvb-membership-form", element: <LeadForm /> },
        { path: "lvb-membership-form/:c_id", element: <LeadForm /> },
      ],
    },
  ]);
}
export function Router() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();

  return useRoutes([
    {
      path: "/*",
      element: <DashboardLayout />,
      children:
        // Admin Routes
        loginUser?.u_type == 0 ||
        (loginUser?.u_type == 2 &&
          loginUser?.p_user_id == 1 &&
          loginUser?.management_p != 0)
          ? [
              { path: "*", element: <DashboardApp /> },
              {
                path: "dashboard",
                exact: true,
                strict: true,
                element: <DashboardApp />,
              },
              {
                path: "documents",
                exact: true,
                strict: true,
                element: <LvbDocuments />,
              },
              {
                path: "wallethistory/:c_a_id",
                exact: true,
                strict: true,
                element: <WalletHistory />,
              },
              {
                path: "country",
                exact: true,
                strict: true,
                element: <Country />,
              },
              { path: "state", exact: true, strict: true, element: <State /> },
              { path: "city", exact: true, strict: true, element: <City /> },
              {
                path: "cityambassador",
                exact: true,
                strict: true,
                element: <CityAmbassador />,
              },
              {
                path: "landmark",
                exact: true,
                strict: true,
                element: <Landmark />,
              },
              {
                path: "event-announcement",
                exact: true,
                strict: true,
                element: <Event />,
              },
              {
                path: "visitor",
                exact: true,
                strict: true,
                element: <Visitor />,
              },
              {
                path: "testimonial",
                exact: true,
                strict: true,
                element: <Testimonial />,
              },
              {
                path: "greeting",
                exact: true,
                strict: true,
                element: <Greeting />,
              },
              {
                path: "chapter",
                exact: true,
                strict: true,
                element: <Chapter />,
              },
              {
                path: "member",
                exact: true,
                strict: true,
                element: <Member />,
              },
              {
                path: "meeting",
                exact: true,
                strict: true,
                element: <Meeting />,
              },
              {
                path: "setting",
                exact: true,
                strict: true,
                element: <Setting />,
              },
              { path: "poll", exact: true, strict: true, element: <Poll /> },
              {
                path: "gallery",
                exact: true,
                strict: true,
                element: <Gallery />,
              },
              { path: "v2v", exact: true, strict: true, element: <V2v /> },
              { path: "*", exact: true, strict: true, element: <Chapter /> },
              {
                path: "business",
                exact: true,
                strict: true,
                element: <Business />,
              },
              {
                path: "reference",
                exact: true,
                strict: true,
                element: <Reference />,
              },
              {
                path: "attendace/:m_id",
                exact: true,
                strict: true,
                element: <Attendace />,
              },
              loginUser.u_type == 0 && {
                path: "staff",
                exact: true,
                strict: true,
                element: <Staff />,
              },
              { path: "task", exact: true, strict: true, element: <Task /> },
              { path: "lead", exact: true, strict: true, element: <Lead /> },
              {
                path: "v2v-report",
                exact: true,
                strict: true,
                element: <V2vReport />,
              },
              {
                path: "business-report",
                exact: true,
                strict: true,
                element: <BusinessReport />,
              },
              {
                path: "reference-report",
                exact: true,
                strict: true,
                element: <ReferenceReport />,
              },
              {
                path: "visitorreport",
                exact: true,
                strict: true,
                element: <VisitorReport />,
              },
              {
                path: "testimonial-report",
                exact: true,
                strict: true,
                element: <TestimonialReport />,
              },
              {
                path: "attendance-report",
                exact: true,
                strict: true,
                element: <AttendaceReport />,
              },
              // LVB Report
              {
                path: "lvb-report",
                exact: true,
                strict: true,
                element: <LvbReport />,
              },
              // City Report
              {
                path: "city-report",
                exact: true,
                strict: true,
                element: <CityReport />,
              },
              {
                path: "city-chapter-report",
                exact: true,
                strict: true,
                element: <CityChapterReport />,
              },
              // Chapter Report
              {
                path: "chapter-v2v-report",
                exact: true,
                strict: true,
                element: <ChapterV2vReport />,
              },
              {
                path: "chapter-business-report",
                exact: true,
                strict: true,
                element: <BusinessReport />,
              },
              {
                path: "chapter-reference-report",
                exact: true,
                strict: true,
                element: <ReferenceReport />,
              },
              {
                path: "chapter-visitor-report",
                exact: true,
                strict: true,
                element: <VisitorReport />,
              },
              {
                path: "chapter-testimonial-report",
                exact: true,
                strict: true,
                element: <TestimonialReport />,
              },
              {
                path: "chapter-attendance-report",
                exact: true,
                strict: true,
                element: <ChapterAttendanceReport />,
              },
              {
                path: "visitor-block-list",
                exact: true,
                strict: true,
                element: <VisitorBlock />,
              },
              {
                path: "lvb-suggestion",
                exact: true,
                strict: true,
                element: <Suggestion />,
              },
              {
                path: "chapters-member-report",
                exact: true,
                strict: true,
                element: <ChapterMemberReportAllInOne />,
              },
              {
                path: "chapterAttendance-report",
                exact: true,
                strict: true,
                element: <ChapterAttendanceReport />,
              },
              {
                path: "topper-report",
                exact: true,
                strict: true,
                element: <GeneralReport />,
              },
              {
                path: "lvb-pin-badge",
                exact: true,
                strict: true,
                element: <LvbPinBadge />,
              },
              {
                path: "member-induction-report",
                exact: true,
                strict: true,
                element: <MemberInductionReport />,
              },
              {
                path: "pin-assign-report",
                exact: true,
                strict: true,
                element: <PinAssignReport />,
              },
              {
                path: "business-approval",
                exact: true,
                strict: true,
                element: <ThankyouNoteApproval />,
              },
              // { path: "chapter-report", element: <ChapterReport /> }
            ]
          : // City Amb
          loginUser?.u_type == 1 ||
            (loginUser?.u_type == 2 && loginUser?.management_p != 0)
          ? [
              {
                path: "*",
                exact: true,
                strict: true,
                element: <DashboardApp />,
              },
              {
                path: "dashboard",
                exact: true,
                strict: true,
                element: <DashboardApp />,
              },
              {
                path: "documents",
                exact: true,
                strict: true,
                element: <LvbDocuments />,
              },
              {
                path: "memberattendance",
                exact: true,
                strict: true,
                element: <ChapterAttendanceReport />,
              },
              {
                path: "chapterAttendance-report",
                exact: true,
                strict: true,
                element: <ChapterAttendanceReport />,
              },
              {
                path: "attendace/:m_id",
                exact: true,
                strict: true,
                element: <Attendace />,
              },
              {
                path: "member",
                exact: true,
                strict: true,
                element: <Member />,
              },
              {
                path: "wallethistory/:c_a_id",
                exact: true,
                strict: true,
                element: <WalletHistory />,
              },
              {
                path: "meeting",
                exact: true,
                strict: true,
                element: <Meeting />,
              },
              {
                path: "chapter",
                exact: true,
                strict: true,
                element: <Chapter />,
              },
              {
                path: "event-announcement",
                exact: true,
                strict: true,
                element: <Event />,
              },
              { path: "v2v", exact: true, strict: true, element: <V2v /> },
              {
                path: "gallery",
                exact: true,
                strict: true,
                element: <Gallery />,
              },
              {
                path: "business",
                exact: true,
                strict: true,
                element: <Business />,
              },
              {
                path: "reference",
                exact: true,
                strict: true,
                element: <Reference />,
              },
              {
                path: "visitor",
                exact: true,
                strict: true,
                element: <Visitor />,
              },
              {
                path: "bankdetail",
                exact: true,
                strict: true,
                element: <Bankdetail />,
              },
              {
                path: "change-password",
                exact: true,
                strict: true,
                element: <ChangePassword />,
              },
              { path: "poll", exact: true, strict: true, element: <Poll /> },
              {
                path: "staff",
                exact: true,
                strict: true,
                element: loginUser?.u_type != 2 ? <Staff /> : <DashboardApp />,
              },
              // { path: "staff", element: <Staff /> },
              { path: "task", exact: true, strict: true, element: <Task /> },
              { path: "lead", exact: true, strict: true, element: <Lead /> },
              {
                path: "v2v-report",
                exact: true,
                strict: true,
                element: <V2vReport />,
              },
              {
                path: "business-report",
                exact: true,
                strict: true,
                element: <BusinessReport />,
              },
              {
                path: "reference-report",
                exact: true,
                strict: true,
                element: <ReferenceReport />,
              },
              {
                path: "visitorreport",
                exact: true,
                strict: true,
                element: <VisitorReport />,
              },
              {
                path: "testimonial",
                exact: true,
                strict: true,
                element: <Testimonial />,
              },
              {
                path: "testimonial-report",
                exact: true,
                strict: true,
                element: <TestimonialReport />,
              },
              {
                path: "attendance-report",
                exact: true,
                strict: true,
                element: <AttendaceReport />,
              },
              {
                path: "chapters-member-report",
                exact: true,
                strict: true,
                element: <ChapterMemberReportAllInOne />,
              },
              {
                path: "member-induction-report",
                exact: true,
                strict: true,
                element: <MemberInductionReport />,
              },
              {
                path: "pin-assign-report",
                exact: true,
                strict: true,
                element: <PinAssignReport />,
              },
              {
                path: "topper-report",
                exact: true,
                strict: true,
                element: <GeneralReport />,
              },
              {
                path: "city-chapter-report",
                exact: true,
                strict: true,
                element: <CityChapterReport />,
              },
              {
                path: "lvb-suggestion",
                exact: true,
                strict: true,
                element: <Suggestion />,
              },
              {
                path: "visitor-block-list",
                exact: true,
                strict: true,
                element: <VisitorBlock />,
              },
              {
                path: "business-approval",
                exact: true,
                strict: true,
                element: <ThankyouNoteApproval />,
              },
            ]
          : // Normal USer
            [
              {
                path: "*",
                exact: true,
                strict: true,
                element: <DashboardApp />,
              },
              {
                path: "dashboard",
                exact: true,
                strict: true,
                element: <DashboardApp />,
              },
              { path: "task", exact: true, strict: true, element: <Task /> },
              { path: "lead", exact: true, strict: true, element: <Lead /> },
            ],
    },
  ]);
}
