import { filter, get } from "lodash";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";

// material
import {
  Box,
  Card,
  CircularProgress,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import {
  fetchBusinessList,
  fetchV2vList,
  fetchChaptList,
  fetchchapterbyuser,
  fetchV2vListByMember,
  fetchLvbCounterWeb,
  fetchallcitylist,
  strToAmountNumberConvert,
  fetchchapterlistbycity,
} from "src/utils/common";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
// components
import Page from "../components/Page";
import {
  exportCityChapterReport,
  findChaptersMemberData,
  getPinBadge,
  getcitychaptersearchcounter,
  getcitysearchcounter,
} from "src/api/commonapi";
import {
  DeleteForever,
  DeleteOutline,
  DeleteSharp,
  EditSharp,
  Label,
  Start,
} from "@mui/icons-material";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import moment from "moment";
import { setDefaultOptions } from "date-fns";
import "../Assets/css/style.css";

let styleCursor = {
  cursor: "pointer",
  width: "100%",
  minHeight: "40px",
  display: "flex",
  alignItems: "center",
};

export default function CityChapterReport() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const [filterCounter, setFilterCounter] = useState([]);
  const [pending, setPending] = useState(false);
  const [dataModal, setDataModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalData, setModalData] = useState([]);
  const [FindData, setFindData] = useState();
  const [modalDataCounter, setModalDataCounter] = useState(0);
  const [exportFileName, setExportFileName] = useState("");
  const [filtereddata, setFilteredData] = useState({
    startDate: null,
    endDate: null,
    filterType: 0,
    city_id:
      loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 &&
        loginUser?.p_user_id != 1 &&
        loginUser?.management_p == 1)
        ? loginUser?.city_id
        : 0,
  });
  const [cityList, setClityList] = useState([]);
  const [
    exportChapterExcelBtnLoading,
    setExportChapterExcelBtnLoading,
  ] = useState(0);

  const [exportExcelFileName, setExportExcelFileName] = useState("");
  useEffect(async () => {
    let res = await fetchallcitylist();
    let newCityList = [{ value: 0, label: "Select City" }];
    res?.map((res) => {
      newCityList.push({ value: res.city_id, label: res.city_name });
    });
    setClityList(newCityList);
  }, []);

  const [PinList, setPinList] = useState([]);

  useEffect(async () => {
    let resp = await getPinBadge(1);
    if (resp.status == 1) {
      setPinList(resp.data);
    } else {
      setPinList([]);
    }
  }, []);

  // Modal Logic & Handle Modal Data
  // Modal Style Start
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    padding: "10px",
  };
  // Modal Style End

  const modalClose = () => {
    setDataModal(false);
    setModalData([]);
    setModalDataCounter(0);
  };

  const modalColumns = [
    (FindData == 0 ||
      FindData == 2 ||
      FindData == 3 ||
      FindData == 1 ||
      FindData == 4 ||
      FindData == 5 ||
      FindData == 6 ||
      FindData == 7 ||
      FindData == 18 ||
      FindData == 9 ||
      FindData == 10 ||
      FindData == 11 ||
      FindData == 12 ||
      FindData == 13 ||
      FindData == 14 ||
      FindData == 15) && {
      name:
        FindData == 0 || FindData == 1
          ? "V2V From"
          : FindData == 2 || FindData == 3 || FindData == 11 || FindData == 12
          ? FindData == 2 || FindData == 11
            ? "Business Receiver"
            : "Business Giver"
          : FindData == 4 || FindData == 5 || FindData == 6 || FindData == 13
          ? [4, 13].includes(FindData)
            ? "Reference Receiver"
            : "Reference Giver"
          : FindData == 14 || FindData == 15 || FindData == 9 || FindData == 10
          ? FindData == 14 || FindData == 9
            ? "Testimonial Receiver"
            : "Testimonial Giver"
          : [7, 18].includes(FindData)
          ? "Invited By"
          : "From",
      sortable: true,
      selector: (row, key) =>
        FindData == 3 ||
        FindData == 12 ||
        FindData == 4 ||
        FindData == 13 ||
        FindData == 14 ||
        FindData == 9
          ? row.do_to
          : row.do_from,

      // selector: (row, key) => row.do_from ,
      width: "250px",
    },

    (FindData == 0 ||
      FindData == 2 ||
      FindData == 3 ||
      FindData == 1 ||
      FindData == 4 ||
      FindData == 5 ||
      FindData == 6 ||
      FindData == 9 ||
      FindData == 10 ||
      FindData == 11 ||
      FindData == 12 ||
      FindData == 13 ||
      FindData == 14 ||
      FindData == 15) && {
      name:
        FindData == 0 || FindData == 1
          ? "V2V To"
          : FindData == 2 || FindData == 3 || FindData == 11 || FindData == 12
          ? FindData == 2 || FindData == 11
            ? "Business Giver"
            : "Business  Receiver"
          : FindData == 4 || FindData == 5 || FindData == 6 || FindData == 13
          ? FindData == 4 || FindData == 13
            ? "Reference Giver"
            : "Reference Receiver"
          : FindData == 14 || FindData == 15 || FindData == 9 || FindData == 10
          ? FindData == 14 || FindData == 9
            ? "Testimonial Giver"
            : "Testimonial Receiver"
          : FindData == 7
          ? "Invited By"
          : "To",
      sortable: true,
      selector: (row, key) =>
        FindData == 3 ||
        FindData == 12 ||
        FindData == 4 ||
        FindData == 13 ||
        FindData == 14 ||
        FindData == 9
          ? row.do_from
          : row.do_to,

      // selector: (row, key) => row.do_to,
      // width: "200px",
    },
    (FindData == 2 || FindData == 3 || FindData == 11 || FindData == 12) && {
      // Business & Business Received
      name: "Amount",
      sortable: true,
      selector: (row, key) => row.b_amount,
      width: "200px",
    },

    [7, 18].includes(FindData) && {
      // Visitor Detail
      name: "Visitor Name",
      sortable: true,
      selector: (row, key) => row.visitor_name,
      width: "200px",
    },
    [7, 18].includes(FindData) && {
      name: "Visitor Mobile Number",
      sortable: true,
      selector: (row, key) => row.n_m_mo_no,
      width: "200px",
    },
    FindData == 8 && {
      name: "Member Name",
      sortable: true,
      selector: (row, key) => row.memberName,
      width: "200px",
    },
    FindData == 8 && {
      name: "joining Date",
      sortable: true,
      selector: (row, key) => row.joining_date,
      width: "200px",
    },
    (FindData == 0 ||
      FindData == 1 ||
      FindData == 2 ||
      FindData == 3 ||
      FindData == 4 ||
      FindData == 5 ||
      FindData == 6 ||
      [7, 18].includes(FindData) ||
      FindData == 9 ||
      FindData == 10 ||
      FindData == 11 ||
      FindData == 12 ||
      FindData == 13 ||
      FindData == 14 ||
      FindData == 15) && {
      name: "Date",
      sortable: true,
      selector: (row, key) => row.date,
      width: "200px",
    },
    FindData == 8 && {
      name: "Reference By",
      sortable: true,
      selector: (row, key) => row.reference_By,
      width: "200px",
    },
    FindData == 17 && {
      name: "Pin Assign Date",
      sortable: true,
      selector: (row, key) => row.date,
      width: "200px",
    },
    FindData == 17 && {
      name: "Member Name",
      sortable: true,
      selector: (row, key) => row.member_name,
    },
    FindData == 18 && {
      name: "Invited At",
      sortable: true,
      selector: (row, key) => row.invited_at_chapter,
    },
  ].filter((f) => f != false);

  const getColumnLabel = (FindData) => {
    if (FindData === 0 || FindData === 1) {
      return "V2V To";
    } else if (
      FindData === 2 ||
      FindData === 3 ||
      FindData === 11 ||
      FindData === 12
    ) {
      return FindData === 2 || FindData === 11
        ? "Business Giver"
        : "Business Receiver";
    } else if (
      FindData === 4 ||
      FindData === 5 ||
      FindData === 6 ||
      FindData === 13
    ) {
      return "Reference Receiver";
    } else if (
      FindData === 14 ||
      FindData === 15 ||
      FindData === 9 ||
      FindData === 10
    ) {
      return "Testimonial Receiver";
    } else if (FindData === 7) {
      return "Invited By";
    } else {
      return "To";
    }
  };

  const getColumnKeyForExport = (FindData) => {
    return FindData == 3 ||
      FindData == 12 ||
      FindData == 4 ||
      FindData == 13 ||
      FindData == 14 ||
      FindData == 9
      ? "do_from"
      : "do_to";
  };

  const exportModalColumns = [
    (FindData == 0 ||
      FindData == 2 ||
      FindData == 3 ||
      FindData == 1 ||
      FindData == 4 ||
      FindData == 5 ||
      FindData == 6 ||
      [7, 18].includes(FindData) ||
      FindData == 9 ||
      FindData == 10 ||
      FindData == 11 ||
      FindData == 12 ||
      FindData == 13 ||
      FindData == 14 ||
      FindData == 15) && {
      label:
        FindData == 0 || FindData == 1
          ? "V2V From"
          : FindData == 2 || FindData == 3 || FindData == 11 || FindData == 12
          ? FindData == 2 || FindData == 11
            ? "Business Receiver"
            : "Business Giver"
          : FindData == 4 || FindData == 5 || FindData == 6 || FindData == 13
          ? FindData == 4 || FindData == 13
            ? "Reference Receiver"
            : "Reference Giver"
          : FindData == 14 || FindData == 15 || FindData == 9 || FindData == 10
          ? FindData == 14 || FindData == 9
            ? "Testimonial Receiver"
            : "Testimonial Giver"
          : [7, 18].includes(FindData)
          ? "Invited By"
          : "From",
      key:
        FindData == 3 ||
        FindData == 12 ||
        FindData == 4 ||
        FindData == 13 ||
        FindData == 14 ||
        FindData == 9
          ? "do_to"
          : "do_from",
      // key: getColumnKeyForExport(FindData),
    },
    (FindData == 0 ||
      FindData == 2 ||
      FindData == 3 ||
      FindData == 1 ||
      FindData == 4 ||
      FindData == 5 ||
      FindData == 6 ||
      FindData == 9 ||
      FindData == 10 ||
      FindData == 11 ||
      FindData == 12 ||
      FindData == 13 ||
      FindData == 14 ||
      FindData == 15) && {
      // label:
      //   FindData == 0
      //     ? "V2V To"
      //     : FindData == 1
      //     ? "V2V To"
      //     : FindData == 2
      //     ? "Business Giver"
      //     : FindData == 3
      //     ? "Business Receiver"
      //     : FindData == 11
      //     ? "Business Giver"
      //     : FindData == 12
      //     ? "Business Receiver"
      //     : FindData == 4
      //     ? "Reference Giver"
      //     : FindData == 5
      //     ? "Reference Receiver"
      //     : FindData == 6
      //     ? "Reference Receiver"
      //     : FindData == 13
      //     ? "Reference Giver"
      //     : FindData == 14
      //     ? "Testimonial Giver"
      //     : FindData == 15
      //     ? "Testimonial Receiver"
      //     : FindData == 9
      //     ? "Testimonial Giver"
      //     : FindData == 10
      //     ? "Testimonial Receiver"
      //     : FindData == 7
      //     ? "Invited By"
      //     : "To",
      // label: "1111",
      label: getColumnLabel(FindData),
      key:
        FindData == 3 ||
        FindData == 12 ||
        FindData == 4 ||
        FindData == 13 ||
        FindData == 14 ||
        FindData == 9
          ? "do_from"
          : "do_to",
      // key:
      //   FindData == 2 ||
      //   FindData == 11 ||
      //   FindData == 4 ||
      //   FindData == 13 ||
      //   FindData == 14 ||
      //   FindData == 9
      //     ? "do_from"
      //     : "do_to",
      // label:
      //   FindData == 0 || FindData == 1
      //     ? "V2V To"
      //     : FindData == 2 || FindData == 3 || FindData == 11 || FindData == 12
      //     ? "Business Receiver"
      //     : FindData == 4 || FindData == 5 || FindData == 6 || FindData == 13
      //     ? "Reference Receiver"
      //     : FindData == 14 || FindData == 15 || FindData == 9 || FindData == 10
      //     ? "Testimonial Receiver"
      //     : FindData == 7
      //     ? "Invited By"
      //     : "To",
      // key: "do_to",
    },
    (FindData == 2 || FindData == 3 || FindData == 11 || FindData == 12) && {
      label: "Amount",
      key: "b_amount",
    },
    [7, 18].includes(FindData) && {
      // Visitor Detail
      label: "Visitor Name",
      key: "visitor_name",
    },
    [7, 18].includes(FindData) && {
      // Visitor Detail
      label: "Visitor Mobile Number",
      key: "n_m_mo_no",
    },
    FindData == 8 && {
      label: "Member Name",
      key: "memberName",
    },
    FindData == 8 && {
      label: "joining Date",
      key: "joining_date",
    },
    (FindData == 0 ||
      FindData == 1 ||
      FindData == 2 ||
      FindData == 3 ||
      FindData == 4 ||
      FindData == 5 ||
      FindData == 6 ||
      [7, 18].includes(FindData) ||
      FindData == 9 ||
      FindData == 10 ||
      FindData == 11 ||
      FindData == 12 ||
      FindData == 13 ||
      FindData == 14 ||
      FindData == 5) && {
      label: "Date",
      key: "date",
    },
    FindData == 8 && {
      label: "Reference By",
      key: "reference_By",
    },

    FindData == 17 && {
      label: "Pin Assign Date",
      key: "date",
    },
    FindData == 17 && {
      label: "Member Name",
      key: "member_name",
    },
    FindData == 18 && {
      label: "Invited At",
      key: "invited_at_chapter",
    },
  ].filter((f) => f != false);

  // End Modal Logic & Handle Modal Data

  const filterData = async () => {
    let start = moment(filtereddata.startDate).format("DD-MM-YYYY");
    let end = moment(filtereddata.endDate).format("DD-MM-YYYY");
    if (filtereddata.city_id == 0) {
      let notify = notification({
        type: "error",
        message: "Please Select  City",
      });
      notify();
    } else {
      start = start != "Invalid date" ? start : "";
      end = end != "Invalid date" ? end : "";
      let fndata = new FormData();
      fndata.append("startDate", start);
      fndata.append("endDate", end);
      fndata.append("city_id", filtereddata.city_id);
      fndata.append("filterType", filtereddata.filterType);
      let resp = await getcitychaptersearchcounter(fndata);
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      if (resp.status == 1) {
        setFilterCounter(resp.data);
        setPending(false);
      } else {
        setFilterCounter([]);
      }
    }
  };

  const getCityCounter = async (id) => {
    let test = [];
    let start = moment(filtereddata.startDate).format("DD-MM-YYYY");
    let end = moment(filtereddata.endDate).format("DD-MM-YYYY");

    if (id == 0) {
      let notify = notification({
        type: "error",
        message: "Please Select  City",
      });
      notify();
    } else {
      start = start != "Invalid date" ? start : "";
      end = end != "Invalid date" ? end : "";
      let fndata = new FormData();
      fndata.append("startDate", start);
      fndata.append("endDate", end);
      fndata.append("city_id", id);
      fndata.append("filterType", filtereddata.filterType);
      let resp = await getcitychaptersearchcounter(fndata);
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      if (resp.status == 1) {
        setFilterCounter(resp.data);
        setPending(false);
      } else {
        setFilterCounter([]);
        setPending(false);
      }
    }
  };

  const exportChapterExcel = async (chpt_id, ChapterName) => {
    let start = moment(filtereddata.startDate).format("DD-MM-YYYY");
    let end = moment(filtereddata.endDate).format("DD-MM-YYYY");
    setExportChapterExcelBtnLoading(chpt_id);
    let resp = await exportCityChapterReport({
      startDate: start != "Invalid date" ? start : "",
      endDate: end != "Invalid date" ? end : "",
      chapter_id: chpt_id,
      filterType: filtereddata.filterType,
    });

    if (resp?.status == 1) {
      let data = resp?.data;
      const workbook = XLSX.utils.book_new();
      // Custom code for Export In Different Sheets Start
      Object.keys(data).map((key) => {
        //Define the columns you want to export On Sheet Wise
        const sheetColumn = data[key]?.column;
        const customColumn = data[key]?.mappingColumn;

        //  Filter and transform the data for the sheet
        const sheetFilteredData = data[key]?.data?.map((item) => {
          const filteredItem = {};
          sheetColumn?.forEach((column) => {
            filteredItem[column] = item[column];
          });
          return filteredItem;
        });
        // convert each sheet data in to row with Header and data Row
        const sheetArrayData = [customColumn].concat(
          sheetFilteredData?.map((item) =>
            sheetColumn?.map((column) => item[column])
          )
        );
        // Create Sheet and pass the data Array
        const sheet1WS = XLSX.utils.aoa_to_sheet(sheetArrayData);
        XLSX.utils.book_append_sheet(
          workbook,
          sheet1WS,
          key.replace(/_/g, " ")
        );
      });
      setExportChapterExcelBtnLoading(0);
      let newFilename = `${ChapterName} Chapter Report${
        start != "Invalid date" ? ` From ${start}` : ""
      }${end != "Invalid date" ? ` To ${end}` : ""}.xlsx`;
      XLSX.writeFile(workbook, newFilename);
    }
  };
  const expo_columns = [
    { label: "Chapter Name", key: "chpt_name" },
    { label: "V2V", key: "v2v_count" },
    { label: "Cross V2V", key: "Cross_V2V_Count" },
    { label: "Business Received", key: "business_recive_amt" },
    { label: "Business Given", key: "business_given_amt" },
    { label: "Cross Business Received", key: "Cross_Business_Received_Count" },
    { label: "Cross Business Given", key: "Cross_Business_Given_Count" },
    { label: "Reference Received", key: "reference_recive_count" },
    { label: "Reference Given", key: "reference_given_count" },
    { label: "Cross Reference Given", key: "Cross_Reference_Given_Count" },
    {
      label: "Cross Reference Received",
      key: "Cross_Reference_Received_Count",
    },
    { label: "Testimonial Received", key: "testimonial_receive_counter" },
    { label: "Testimonial Given", key: "testimonial_given_counter" },
    {
      label: "Cross Testimonial Received",
      key: "Cross_Testimonial_Received_Count",
    },
    { label: "Cross Testimonial Given", key: "Cross_Testimonial_Given_Count" },
    { label: "Visitor", key: "Visitor_Count" },
    { label: "Cross Visitor", key: "Cross_Visitor_Count" },

    { label: "New Inducted Member", key: "New_Inducted_Member_Count" },
    // { label: "Total Pin Assign", key: "Pin_Assign_Count" },
    ...PinList.map((e) => {
      return {
        label: `${e.name} Pin `,
        key: `${e.name}`,
      };
    }),
  ];

  const columns = [
    {
      name: "Chapter Name",
      sortable: true,
      width: "170px",
      selector: (row, key) => row.chpt_name,
    },
    {
      name: "Chapter Export",
      sortable: true,
      width: "170px",
      cell: (row, key) => (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            {filterCounter.length > 0 && (
              <CSVLink
                className="btn btn-primary test"
                data={[filterCounter[key]]}
                headers={expo_columns}
                filename={`City's Chapter ${row.chpt_name} ${
                  (moment(filtereddata.startDate).format("DD-MM-YYYY") &&
                    moment(filtereddata.endDate).format("DD-MM-YYYY")) !=
                  "Invalid date"
                    ? "Data From " +
                      moment(filtereddata.startDate).format("DD-MM-YYYY") +
                      " - " +
                      moment(filtereddata.endDate).format("DD-MM-YYYY")
                    : "All Data"
                } .csv`}
                target="_blank"
              >
                Export CSV
              </CSVLink>
            )}

            <Button
              color="warning"
              variant="contained"
              className="btn btn-export"
              onClick={() => {
                setExportExcelFileName(row?.chpt_name);
                exportChapterExcel(row?.chpt_id, row?.chpt_name);
              }}
              disabled={exportChapterExcelBtnLoading == row?.chpt_id}
            >
              Export Excel{" "}
              {exportChapterExcelBtnLoading == row?.chpt_id && (
                <CircularProgress size={14} />
              )}
            </Button>
          </div>
        </>
      ),
    },
    {
      name: "V2V",
      allowOverflow: true,
      cell: (row) => (
        <>
          <div
            style={styleCursor}
            onClick={() => searchChapterMemberRecord(row, 0)}
          >
            {row.v2v_count}
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: "Cross V2V",
      allowOverflow: true,
      cell: (row) => (
        <>
          <div
            style={styleCursor}
            onClick={() => searchChapterMemberRecord(row, 1)}
          >
            {row.Cross_V2V_Count}
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: "Business Received",
      allowOverflow: true,
      cell: (row) => (
        <>
          <div
            style={styleCursor}
            onClick={() => searchChapterMemberRecord(row, 2)}
          >
            {row.business_recive_amt}
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: "Business Given",
      allowOverflow: true,
      cell: (row) => (
        <>
          <div
            style={styleCursor}
            onClick={() => searchChapterMemberRecord(row, 3)}
          >
            {row.business_given_amt}
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: "Cross Business Received",
      allowOverflow: true,
      cell: (row) => (
        <>
          <div
            style={styleCursor}
            onClick={() => searchChapterMemberRecord(row, 11)}
          >
            {row.Cross_Business_Received_Count}
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: "Cross Business Given",
      allowOverflow: true,
      cell: (row) => (
        <>
          <div
            style={styleCursor}
            onClick={() => searchChapterMemberRecord(row, 12)}
          >
            {row.Cross_Business_Given_Count}
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: `Reference Received`,
      allowOverflow: true,
      cell: (row) => (
        <>
          <div
            style={styleCursor}
            onClick={() => searchChapterMemberRecord(row, 4)}
          >
            {row.reference_recive_count}
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: "Reference Given",
      allowOverflow: true,
      cell: (row) => (
        <>
          <div
            style={styleCursor}
            onClick={() => searchChapterMemberRecord(row, 5)}
          >
            {row.reference_given_count}
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: "Cross Reference Given",
      allowOverflow: true,
      sortable: true,
      cell: (row) => (
        <>
          <div
            style={styleCursor}
            onClick={() => searchChapterMemberRecord(row, 6)}
          >
            {row.Cross_Reference_Given_Count}
          </div>
        </>
      ),
    },
    {
      name: "Cross Reference Received",
      allowOverflow: true,
      sortable: true,
      cell: (row) => (
        <>
          <div
            style={styleCursor}
            onClick={() => searchChapterMemberRecord(row, 13)}
          >
            {row.Cross_Reference_Received_Count}
          </div>
        </>
      ),
    },
    {
      name: "Testimonial Received",
      allowOverflow: true,
      sortable: true,
      cell: (row) => (
        <>
          <div
            style={styleCursor}
            onClick={() => searchChapterMemberRecord(row, 14)}
          >
            {row.testimonial_receive_counter}
          </div>
        </>
      ),
    },
    {
      name: "Testimonial Given",
      allowOverflow: true,
      sortable: true,
      cell: (row) => (
        <>
          <div
            style={styleCursor}
            onClick={() => searchChapterMemberRecord(row, 15)}
          >
            {row.testimonial_given_counter}
          </div>
        </>
      ),
    },
    {
      name: "Cross Testimonial Received",
      allowOverflow: true,
      sortable: true,
      cell: (row) => (
        <>
          <div
            style={styleCursor}
            onClick={() => searchChapterMemberRecord(row, 9)}
          >
            {row.Cross_Testimonial_Received_Count}
          </div>
        </>
      ),
    },
    {
      name: "Cross Testimonial Given",
      allowOverflow: true,
      sortable: true,
      cell: (row) => (
        <>
          <div
            style={styleCursor}
            onClick={() => searchChapterMemberRecord(row, 10)}
          >
            {row.Cross_Testimonial_Given_Count}
          </div>
        </>
      ),
    },
    {
      name: "Visitor",
      allowOverflow: true,
      cell: (row) => (
        <>
          <div
            style={styleCursor}
            onClick={() => searchChapterMemberRecord(row, 7)}
          >
            {row.Visitor_Count}
          </div>
        </>
      ),
      sortable: true,
    },

    {
      name: "Cross Visitor",
      allowOverflow: true,
      cell: (row) => (
        <>
          <div
            style={styleCursor}
            onClick={() => searchChapterMemberRecord(row, 18)}
          >
            {row.Cross_Visitor_Count}
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: "New Inducted Member",
      allowOverflow: true,
      cell: (row) => (
        <>
          <div
            style={styleCursor}
            onClick={() => searchChapterMemberRecord(row, 8)}
          >
            {row.New_Inducted_Member_Count}
          </div>
        </>
      ),
      sortable: true,
    },
    // {
    //   name: "Pin Assign",
    //   allowOverflow: true,
    //   cell: (row) => (
    //     <>
    //       <div
    //         style={styleCursor}
    //         onClick={() => searchChapterMemberRecord(row, 17)}
    //       >
    //         {row.Pin_Assign_Count}
    //       </div>
    //     </>
    //   ),
    //   sortable: true,
    // },
    ...PinList.map((e) => {
      return {
        name: `${e.name} Pin `,
        allowOverflow: true,
        cell: (row) => (
          <>
            <div
              style={styleCursor}
              onClick={() => searchChapterMemberRecord(row, 17, e?.p_id)}
            >
              {/* {row.Pin_Assign_Count} */}

              {row[e.name]}
            </div>
          </>
        ),
        sortable: true,
      };
    }),
  ];

  const searchChapterMemberRecord = async (data, find, pin_id = 0) => {
    // find => 0 - V2V, 1=>Cross V2V, 2=> Business Rec,3 => Business Given,4 => Reference Rec, 5=> Ref.give,6 => Cross Ref. Given, 7=> Visitor, 8=> New Induction Member
    //9 - Cross Testimonial Received,10 - Cross Testimonial Give,11 - Cross business receive, 12 - Cross business Give,13 - Cross Ref Received,14 - Testimonial Received,15 - testimonial Given
    let start = moment(filtereddata.startDate).format("DD-MM-YYYY");
    let end = moment(filtereddata.endDate).format("DD-MM-YYYY");
    let chapter = data.chpt_id;
    setFindData(find);
    start = start != "Invalid date" ? start : "";
    end = end != "Invalid date" ? end : "";
    let fndata = new FormData();
    fndata.append("startDate", start);
    fndata.append("endDate", end);
    fndata.append("chapter_id", chapter);
    fndata.append("find", find);
    fndata.append("filterType", filtereddata.filterType);
    fndata.append("pin_id", pin_id);

    let resp = await findChaptersMemberData(fndata);

    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      setDataModal(true);
      setModalData(resp.data.list);
      setModalDataCounter(resp.data.counter);
      let title = "";
      if (find == 0) {
        // V2V
        title = "V2V Member Data";
      } else if (find == 1) {
        //Cross V2V
        title = "Cross V2V Member Data";
      } else if (find == 2) {
        //Business Rec
        title = "Business Received Member Data";
      } else if (find == 3) {
        //Business Given
        title = "Business Given Member Data";
      } else if (find == 4) {
        //Reference Rec
        title = "Reference Received Member Data";
      } else if (find == 5) {
        //Ref.give
        title = "Reference Given Member Data";
      } else if (find == 6) {
        //Cross Ref
        title = "Cross Reference Given Member Data";
      } else if (find == 7) {
        //Visitor
        title = "Visitor Member Data";
      } else if (find == 8) {
        //New Induction Member
        title = "New Induction Member Data";
      } else if (find == 9) {
        title = "Cross Testimonial Received Member Data";
      } else if (find == 10) {
        title = "Cross Testimonial Given Member Data";
      } else if (find == 11) {
        title = "Cross Business Received Member Data";
      } else if (find == 12) {
        title = "Cross Business Given Member Data";
      } else if (find == 13) {
        title = "Cross Reference Received Member Data";
      } else if (find == 14) {
        title = "Testimonial Received Member Data";
      } else if (find == 15) {
        title = "Testimonial Given Member Data";
      } else if (find == 17) {
        //Pin Assign
        title = PinList.map((e) => {
          return e.p_id == pin_id && `${e.name} Pin Assign Data`;
        }).filter((f) => f !== false);
      } else if (find == 18) {
        title = "Cross Visitor Member Data";
      }

      setModalTitle(title);
      setExportFileName(
        data.chpt_name +
          " Chapter Data " +
          (start != "" && end != "" ? `From ${start} To ${end}` : "") +
          "_For " +
          title
      );
    } else {
      setDataModal(false);
      setModalData([]);
      setModalDataCounter(0);
      setModalTitle("");
    }
  };

  return (
    <>
      <Modal
        open={dataModal}
        onClose={modalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h4"
            component="h2"
            style={{
              display: "flex",
              padding: "2px 10px",
              justifyContent: "center",
              borderBottom: "2px solid #000",
            }}
          >
            <div>{modalTitle}</div>
          </Typography>
          <div style={{ padding: "10px" }}>
            <div style={{ maxHeight: "480px" }}>
              <Stack spacing={10} style={{ marginTop: "10px" }}>
                <Card
                  style={{
                    // padding: "25px",
                    paddingBottom: "25px",
                    minHeight: "420px",
                    maxHeight: "420px",
                    overflow: "scroll",
                  }}
                >
                  {modalData && (
                    <>
                      <Button className="btn btn-export">
                        <CSVLink
                          data={modalData}
                          headers={exportModalColumns}
                          filename={`${exportFileName}.csv`}
                          className="btn btn-primary test"
                          target="_blank"
                        >
                          Export CSV
                        </CSVLink>
                      </Button>

                      <DataTable
                        style={{
                          overflowX: "scroll",
                          overflowY: "hidden",
                          width: "100%",
                        }}
                        filter
                        pagination
                        highlightOnHover
                        columns={modalColumns}
                        data={modalData}
                        responsive={true}
                      />
                    </>
                  )}
                </Card>
              </Stack>
            </div>
            <hr />
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setDataModal(false);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Page title="City Chapter Report | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="h4" gutterBottom>
              City Chapter Report
            </Typography>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <label style={{ marginBottom: "10px" }}> Select City </label>
                  <Select
                    isDisabled={
                      loginUser?.u_type == 1 ||
                      (loginUser?.u_type == 2 &&
                        loginUser?.p_user_id != 1 &&
                        loginUser?.management_p == 1)
                        ? true
                        : false
                    }
                    value={
                      cityList &&
                      cityList.map((option) => {
                        if (option.value == filtereddata.city_id) {
                          return { value: option.value, label: option.label };
                        }
                      })
                    }
                    onChange={async (e) => {
                      setFilteredData({
                        ...filtereddata,
                        city_id: e.value,
                        chpt_id: 0,
                      });
                      setPending(true);
                      getCityCounter(e.value);
                    }}
                    options={cityList}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Card
            style={{
              padding: "25px",
              paddingBottom: "25px",
              marginBottom: "25px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <RadioGroup
                    style={{ marginTop: "0px" }}
                    row
                    defaultValue={0}
                    onChange={(e) => {
                      setFilteredData({
                        ...filtereddata,
                        filterType: e.target.value,
                      });
                    }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="e_type"
                  >
                    <FormControlLabel
                      value="0"
                      control={
                        <Radio checked={filtereddata.filterType == 0 && true} />
                      }
                      label="Include Default Data"
                    />
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio checked={filtereddata.filterType == 1 && true} />
                      }
                      label="Don't Include Default Data"
                    />
                  </RadioGroup>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack
                  spacing={3}
                  style={{ marginBottom: "15px", marginTop: "5px" }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    style={{ marginTop: "15px" }}
                  >
                    <DatePicker
                      type="date"
                      name="startdate"
                      label="Start Date "
                      value={
                        filtereddata?.startDate != null
                          ? filtereddata?.startDate
                          : null
                      }
                      inputFormat="dd/MM/yyyy"
                      onChange={(newDate) => {
                        setFilteredData({
                          ...filtereddata,
                          startDate: newDate,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack
                  spacing={3}
                  style={{ marginBottom: "15px", marginTop: "5px" }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    style={{ marginTop: "15px" }}
                  >
                    <DatePicker
                      type="date"
                      name="enddate"
                      label="End Date "
                      value={
                        filtereddata?.endDate != null
                          ? filtereddata?.endDate
                          : null
                      }
                      inputFormat="dd/MM/yyyy"
                      onChange={(newDate) => {
                        setFilteredData({
                          ...filtereddata,
                          endDate: newDate,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setPending(true);
                      filterData();
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setFilteredData({
                        startDate: null,
                        endDate: null,
                        city_id: 0,
                        filterType: 0,
                      });
                    }}
                    style={{ marginLeft: "5px" }}
                  >
                    Clear
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>
          <Card
            style={{
              paddingTop: "25px",
              paddingBottom: "25px",
              marginBottom: "25px",
            }}
          >
            {filterCounter && (
              <>
                {pending == false && (
                  <Button className="btn btn-export">
                    <CSVLink
                      data={filterCounter}
                      headers={expo_columns}
                      filename={`City's Chapter ${
                        (moment(filtereddata.startDate).format("DD-MM-YYYY") &&
                          moment(filtereddata.endDate).format("DD-MM-YYYY")) !=
                        "Invalid date"
                          ? "Data From " +
                            moment(filtereddata.startDate).format(
                              "DD-MM-YYYY"
                            ) +
                            " - " +
                            moment(filtereddata.endDate).format("DD-MM-YYYY")
                          : "All Data"
                      } .csv`}
                      className="btn btn-primary test"
                      target="_blank"
                    >
                      Export CSV
                    </CSVLink>
                  </Button>
                )}

                <DataTable
                  title={
                    filterCounter?.[0]?.Type != undefined
                      ? filterCounter?.[0]?.Type + " City Record"
                      : ""
                  }
                  style={{ overflow: "hidden" }}
                  filter
                  progressPending={pending}
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={filterCounter}
                />
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
