import { filter } from "lodash";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import { Card, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import {
  fetchBusinessList,
  fetchVisitorList,
  fetchChaptList,
  fetchchapterbyuser,
  fetchTestimonialList,
  fetchallcitylist,
} from "src/utils/common";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
// components
import Page from "../components/Page";
import {
  deleteTestimonialData,
  deleteVisitorData,
  GetMemberByChptId,
} from "src/api/commonapi";
import {
  DeleteForever,
  DeleteOutline,
  DeleteSharp,
  EditSharp,
  WomanRounded,
} from "@mui/icons-material";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import moment from "moment";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";
import Label from "src/components/Label";

export default function TestimonialReport() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });

  const [joindate, setjoinDate] = useState(null);
  const [chptdata, setchptdata] = useState([]);
  const [amount, setamount] = useState({});
  const [testimonialData, settestimonialData] = useState([]);
  const [ntestimonialData, setntestimonialData] = useState([]);
  const [chapoption, setchptoption] = useState([]);
  const [memberdata, setmemberdata] = useState([]);
  const [getdata, setgetdata] = useState({});
  const [step, setstep] = useState(0);
  const [filter, setFilter] = useState({
    city_id:
      loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 &&
        loginUser?.p_user_id != 1 &&
        loginUser?.management_p == 1)
        ? loginUser?.city_id
        : 0,
    chpt_id: 0,
    m_id: 0,
    selectType: 0,
    startDate: "",
    endDate: "",
    crossType: 0,
  });

  const getchptlist = async () => {
    setchptdata(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  };
  useEffect(
    (async) => {
      chptdata?.map((option) => {
        chapoption.push({ value: option.chpt_id, label: option.chpt_name });
      });
    },
    [chptdata]
  );

  const filterData = (start, end) => {
    let newTestimonial = [];
    newTestimonial =
      testimonialData &&
      testimonialData.filter((testi) => {
        if (
          (filter.selectType == 1 && testi.t_given_to == filter.m_id) ||
          (filter.selectType == 2 && testi.t_given_by == filter.m_id) ||
          filter.selectType == 0
        ) {
          if (filter.startDate != "" && filter.endDate != "") {
            if (
              moment(testi.date, "DD-MM-YYYY").isBetween(
                filter.startDate,
                filter.endDate,
                "days",
                "[]"
              )
            ) {
              let res = handleCrossFilter(testi);
              return res;
            }
          } else {
            let res = handleCrossFilter(testi);
            return res;
          }
        }
      });

    setntestimonialData(newTestimonial);
  };

  const handleCrossFilter = (testi) => {
    if (filter.crossType == 1 || filter.crossType == 2) {
      if (testi.inOutChapter == filter.crossType) {
        return testi;
      }
    } else {
      return testi;
    }
  };

  useEffect(async () => {
    await filterData();
  }, [testimonialData, filter]);

  const getmemberbychp = async (ch_id) => {
    setmemberdata(await GetMemberByChptId(ch_id));
  };
  const getTestimonialList = async (ch_id, m_id) => {
    let res = await fetchTestimonialList(m_id);
    let newData = res.map((testimonial) => {
      if (testimonial.t_given_to == m_id) {
        return memberdata
          .filter((member) => member.chpt_id == filter.chpt_id)
          .map((member) => member.m_id)
          .includes(testimonial.t_given_by)
          ? { ...testimonial, inOutChapter: 1, outChapterLabel: "In-Chapter" }
          : {
              ...testimonial,
              inOutChapter: 2,
              outChapterLabel: "Cross-Chapter",
            };
        // return ({...testimonial , crossData :''})
      } else if (testimonial.t_given_by == m_id) {
        return memberdata
          .filter((member) => member.chpt_id == filter.chpt_id)
          .map((member) => member.m_id)
          .includes(testimonial.t_given_to)
          ? { ...testimonial, inOutChapter: 1, outChapterLabel: "In-Chapter" }
          : {
              ...testimonial,
              inOutChapter: 2,
              outChapterLabel: "Cross-Chapter",
            };
      }
    });
    settestimonialData(newData);
  };
  useEffect(async () => {
    getchptlist();
  }, []);

  const [filterTypeOptions, setFilterTypeOptions] = useState([
    { value: 0, label: "All" },
    { value: 1, label: "Testimonial Receiver" },
    { value: 2, label: "Testimonial Giver" },
  ]);
  const [inOutChapterOptions, setInOutChapterOptions] = useState([
    { value: 0, label: "All" },
    { value: 1, label: "In-Chapter" },
    { value: 2, label: "Cross-Chapter" },
  ]);

  const expo_columns = [
    { label: "No", key: "row_no" },
    { label: "Date", key: "date" },
    { label: "Testimonial Giver", key: "t_given_by_member" },
    { label: "Testimonial Receiver", key: "t_given_to_member" },
    { label: "Comment", key: "comment" },
    { label: "In/Out Chapter", key: "outChapterLabel" },
  ];

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row.row_no,
      sortable: true,
      width: "80px",
    },
    {
      name: "Date",
      wrap: true,
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Testimonial Giver",
      wrap: true,
      selector: (row) => row.t_given_by_member,
      sortable: true,
    },
    {
      name: "Testimonial Receiver",
      wrap: true,
      selector: (row) => row.t_given_to_member,
      sortable: true,
    },

    {
      name: "Comment",
      wrap: true,
      width: "500px",
      selector: (row) => (
        <div style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
          {row.comment}
        </div>
      ),
    },

    {
      name: "In/Out Chapter",
      wrap: true,
      selector: (row) => (
        <div>
          <Label
            variant={"ghost"}
            color={row.inOutChapter == 1 ? "success" : "info"}
          >
            {row.outChapterLabel}
          </Label>
        </div>
      ),
    },
  ];
  const changeChapterHandler = async (e) => {
    setFilter({ ...filter, chpt_id: e.value });
    await getmemberbychp(e.value);
  };

  const [citylist, setcitylist] = useState([]);

  const [chaptlist, setchaplist] = useState([]);

  const getallcitylist = async () => {
    setcitylist(await fetchallcitylist());
    setchaplist(
      await fetchchapterbyuser(loginUser?.city_id, loginUser?.u_type)
    );
  };
  useEffect(async () => {
    getallcitylist();
  }, []);
  return (
    <>
      <Page title="Testimonial Report | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="h4" gutterBottom>
              Testimonial Report
            </Typography>
          </Stack>
          <Card className="p-20">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>Select City</label>
                  <Select
                    menuPortalTarget={document.body}
                    isDisabled={
                      loginUser?.u_type == 1 ||
                      (loginUser?.u_type == 2 &&
                        loginUser?.p_user_id != 1 &&
                        loginUser?.management_p == 1)
                        ? true
                        : false
                    }
                    options={[
                      {
                        value: 0,
                        label: "All City",
                      },
                      ...citylist.map((city) => {
                        return {
                          label: city?.city_name,
                          value: city?.city_id,
                        };
                      }),
                    ]}
                    onChange={(e) => {
                      setFilter({ ...filter, city_id: e.value, chpt_id: 0 });
                    }}
                    value={
                      filter.city_id == 0
                        ? {
                            value: 0,
                            label: "All City",
                          }
                        : citylist.map((city) => {
                            return (
                              filter.city_id == city.city_id && {
                                value: city.city_id,
                                label: city.city_name,
                              }
                            );
                          })
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>Select Chapter</label>
                  <Select
                    name="chpt_id"
                    menuPortalTarget={document.body}
                    onChange={changeChapterHandler}
                    options={[
                      ...(chaptlist &&
                        chaptlist
                          ?.map((chpt) => {
                            if (filter?.city_id != 0) {
                              if (filter?.city_id == chpt?.city_id) {
                                return {
                                  value: chpt.chpt_id,
                                  label: chpt.chpt_name,
                                };
                              }
                            } else {
                              return {
                                value: chpt.chpt_id,
                                label: chpt.chpt_name,
                              };
                            }
                          })
                          .filter((f) => f != undefined)),
                    ]}
                    value={
                      chaptlist &&
                      chaptlist.map((c) => {
                        if (c.chpt_id == filter.chpt_id) {
                          return {
                            value: c.chpt_id,
                            label: c.chpt_name,
                          };
                        }
                      })
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>Select Member</label>
                  <Select
                    menuPortalTarget={document.body}
                    value={memberdata.map((member) => {
                      if (member.m_id == filter.m_id) {
                        return { value: member.m_id, label: member.m_name };
                      }
                    })}
                    onChange={(e) => {
                      setFilter({ ...filter, m_id: e.value });
                      getTestimonialList(filter.chpt_id, e.value);
                    }}
                    options={
                      memberdata &&
                      memberdata.map((option) => {
                        return { value: option.m_id, label: option.m_name };
                      })
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>Select Type</label>
                  <Select
                    menuPortalTarget={document.body}
                    value={filterTypeOptions.map((c) => {
                      if (c.value == filter.selectType) {
                        return { value: c.value, label: c.label };
                      }
                    })}
                    onChange={async (e) => {
                      setFilter({ ...filter, selectType: e.value });
                    }}
                    options={filterTypeOptions}
                  />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>In / Cross Chapter</label>
                  <Select
                    menuPortalTarget={document.body}
                    value={inOutChapterOptions.map((c) => {
                      if (c.value == filter.crossType) {
                        return { value: c.value, label: c.label };
                      }
                    })}
                    onChange={async (e) => {
                      setFilter({ ...filter, crossType: e.value });
                    }}
                    options={inOutChapterOptions}
                  />
                </Stack>
              </Grid>

              <Grid item xs={3}>
                <Stack spacing={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      type="date"
                      name="startdate"
                      label="Start Date "
                      value={filter?.startDate != "" ? filter?.startDate : null}
                      inputFormat="dd/MM/yyyy"
                      onChange={(newDate) => {
                        setFilter({
                          ...filter,
                          startDate: newDate,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      type="date"
                      name="enddate"
                      label="End Date "
                      value={filter?.endDate != "" ? filter?.endDate : null}
                      inputFormat="dd/MM/yyyy"
                      onChange={(newDate) => {
                        setFilter({
                          ...filter,
                          endDate: newDate,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      filterData();
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setFilter({
                        chpt_id: 0,
                        m_id: 0,
                        selectType: 0,
                        startDate: "",
                        endDate: "",
                        crossType: 0,
                      });
                      settestimonialData([]);
                    }}
                    style={{ marginLeft: "5px" }}
                  >
                    Clear
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>

          <Card className="p-20" sx={{ mt: 2 }}>
            {ntestimonialData && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={ntestimonialData}
                    headers={expo_columns}
                    filename={
                      "Testimonial Data " +
                      moment().format("DD-MM-YYYY") +
                      ".csv"
                    }
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  style={{ overflow: "hidden" }}
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={ntestimonialData}
                />
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
